import { createSelector } from "reselect";

import {
  getMonitoringData as getMonitoringDataApi,
  getMonitoringCropIntervals,
} from "../../../shared/api/sentinel/monitoring/monitoring.selectors";
import { getSatelliteProducts } from "../../../shared/api/sentinel/satelliteProducts/satelliteProducts.selectors";

import * as satProduct from "../../../shared/constants/satelliteProductsTypes.constants";

import { ChartDataOrMonitoringData } from "../containers/BioMonitoring";

import { PrecisionState } from "../../../reducers/precision.reducer.types";
import { MonitoringData } from "../../../shared/api/sentinel/monitoring/monitoring.types";

export const getIndex = (state: PrecisionState) => state.ui.monitoring.index;
export const getDateFrom = (state: PrecisionState) =>
  state.ui.monitoring.dateFrom;
export const getDateTo = (state: PrecisionState) =>
  state.ui.monitoring.dateTo ?? "";

export const getParcelGeometry = (state: PrecisionState) =>
  state.api.geometry.geometry;

export const getMonitoringStatus = createSelector(
  getSatelliteProducts,
  (products) => {
    if (
      products.biomonitoringStatus === satProduct.INACTIVE &&
      products.internalBiomonitoringStatus === satProduct.INACTIVE
    ) {
      return satProduct.INACTIVE;
    } else if (
      products.biomonitoringStatus === satProduct.ACTIVE ||
      products.internalBiomonitoringStatus === satProduct.ACTIVE
    ) {
      return satProduct.ACTIVE;
    } else if (
      products.biomonitoringStatus === satProduct.HISTORICAL ||
      products.internalBiomonitoringStatus === satProduct.HISTORICAL
    ) {
      return satProduct.HISTORICAL;
    } else if (
      products.biomonitoringStatus === satProduct.AWAITING_DATA ||
      products.internalBiomonitoringStatus === satProduct.AWAITING_DATA
    ) {
      return satProduct.AWAITING_DATA;
    } else {
      return satProduct.AWAITING_USER;
    }
  },
);

export const getIsMonitoring = (state: PrecisionState) =>
  Boolean(getMonitoringData(state));

export const getCropIntervals = createSelector(
  getMonitoringCropIntervals,
  (cropIntervals) =>
    cropIntervals.map((cropInterval, i) => ({
      id: `${cropInterval.crop?.legislativeCode ?? i}_${cropInterval.from}`,
      ...cropInterval,
    })),
);

export const getMonitoringData = createSelector(
  getMonitoringDataApi,
  (data) =>
    data
      .filter((item: MonitoringData) => item?.snapshots?.length)
      .map((item) => ({
        crop: item.crop,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        status: item.status,
        value: item.snapshots[0].value,
        imgPath: item.snapshots[0].imgPath,
        imgExtent: item.snapshots[0].imgExtent,
        zones: item.snapshots[0].zones,
        indexType: item.snapshots[0].indexType,
      })) as ChartDataOrMonitoringData[],
);

export const getChartData = createSelector(
  getMonitoringData,
  (data) => data.slice().reverse() as ChartDataOrMonitoringData[],
);
