import React, { FC, useState, useMemo, useContext, useEffect } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field, FormikProps } from "formik";
import { toNumber } from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getMachineByGpsUnit } from "../../../shared/api/telematics/drives/drives.selectors";

import { calculateArea } from "../../actions/telematicsDetail.actions";

import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikNumericTextField from "../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import * as validators from "../../../shared/misc/validators";
import { CfFormikErrors, CfFormikProps, Thunk } from "../../../types";
import EmptyParcelBox from "../EmptyParcelBox/EmptyParcelBox";
import LpisBlock from "../LpisBlock/LpisBlock";
import TelematicsDetailDriverSelector from "../TelematicsDetailSelectors/TelematicsDetailDriverSelector";
import TelematicsDetailEquipmentSelector from "../TelematicsDetailSelectors/TelematicsDetailEquipmentSelector";
import TelematicsDetailMachineSelector from "../TelematicsDetailSelectors/TelematicsDetailMachineSelector";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../TelematicsForeignWarning/TelematicsForeignWarning";
import TelematicsOperation from "../TelematicsOperation/TelematicsOperation";
import TelematicsOperationDialog from "../TelematicsOperationDialog/TelematicsOperationDialog";
import TelematicsParcel, {
  TelematicsParcelType,
} from "../TelematicsParcel/TelematicsParcel";

import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { ParcelDetailTo } from "../../../shared/api/agroevidence/agroevidence.types";
import {
  Affiliation,
  DrivePartDetailTo,
  DriveRecalculateTo,
  DriverTo,
  EquipmentTo,
  MachineTo,
  Source,
  TelematicsOperation as TelematicsOperationCode,
} from "../../../shared/api/telematics/telematics.types";
import {
  TelematicsItemFormValues,
  TelematicsOperationFormValues,
} from "../../telematics.types";

const useStyles = makeStyles((theme: Theme) => ({
  center: {
    margin: "0 auto",
  },
  button: {
    margin: "10px",
  },
  error: {
    color: theme.palette.error.main,
  },
  foreignWarning: {
    margin: "3px",
    fontSize: 13,
  },
}));

interface Props {
  driveDetail?: DrivePartDetailTo;
  formikRef: React.RefObject<FormikProps<TelematicsItemFormValues>>;
  goBack(): void;
  isEditing: boolean;
  isFetchingParcel: boolean;
  isNew: boolean;
  isSubmitting: boolean;
  machine?: MachineTo;
  onReset(): void;
  onSubmit(values: TelematicsItemFormValues): void;
  parcel?: ParcelDetailTo | null;
  submitError: boolean;
}

interface ConnectedProps extends Props {
  calculateArea: (
    driveId: number,
    equipment: DriveRecalculateTo,
    changeHandler: (area: number) => void,
  ) => void;
  machine?: MachineTo;
}

const validateWorkingWidth = (value: number) => {
  if (value > 50 || value < 0) {
    return "TelematicsList.validation.workingtWidth";
  }
  return "";
};

const TelematicsDetailContent: FC<ConnectedProps> = ({
  calculateArea,
  driveDetail,
  formikRef,
  goBack,
  isEditing,
  isFetchingParcel,
  isNew,
  isSubmitting,
  machine,
  onReset,
  onSubmit,
  parcel,
  submitError,
}) => {
  const isManualRecord =
    driveDetail?.source === Source.MANUAL || !driveDetail?.source;
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const [selectedOperation, setSelectedOperation] = useState<
    TelematicsOperationCode | undefined
  >();
  const [selectedMachine, setSelectedMachine] = useState<
    MachineTo | null | undefined
  >();

  useEffect(() => {
    setSelectedMachine(machine);
  }, [machine]);

  const handleOperationDialogClose = () => {
    setSelectedOperation(undefined);
  };

  const handleOperationDialogSubmit = (
    newValues: TelematicsOperationFormValues,
  ) => {
    const values = formikRef?.current?.values;
    formikRef?.current?.setValues({
      ...values,
      ...(newValues as TelematicsItemFormValues),
    });
    setSelectedOperation(undefined);
  };

  const handleCreate = () => {
    formikRef?.current?.setFieldValue("createMore", false);
    formikRef?.current?.submitForm();
  };

  const handleCreateAndCreateMore = () => {
    formikRef?.current?.setFieldValue("createMore", true);
    formikRef?.current?.submitForm();
  };

  const handleCancel = () => {
    goBack();
  };

  const formikInitialValues = useMemo(
    () => ({
      createMore: false,
      date: driveDetail ? moment(driveDetail.timeFrom) : moment(),
      driverCode: driveDetail?.driver?.code ?? "",
      bonus: driveDetail?.driver?.bonus || 0,
      gpsUnit: driveDetail?.gpsUnit ?? "",
      equipmentCode: driveDetail?.equipmentCode ?? "",
      workingWidth: driveDetail?.workingWidth ?? 0,
      operation: driveDetail?.operation,
      timeFrom: driveDetail ? moment(driveDetail.timeFrom) : null,
      timeTo: driveDetail ? moment(driveDetail.timeTo) : null,
      productionOperation: driveDetail?.productionOperation ?? {},
      season: driveDetail?.season ?? 0,
      cultivated: driveDetail?.action?.area ?? 0,
      distance: driveDetail?.action?.distance ?? 0,
    }),
    [driveDetail],
  );

  const validate = (values: TelematicsItemFormValues) => {
    const errors: CfFormikErrors<TelematicsItemFormValues> = {};
    if (!values.date) {
      errors.date = "validation.required";
    }

    if (!values.gpsUnit) {
      errors.gpsUnit = "validation.required";
    }
    if (!values.driverCode) {
      errors.driverCode = "validation.required";
    }
    if (!values.operation) {
      errors.operation = "validation.required";
    }

    if (values.bonus) {
      if (values.bonus < -999 || values.bonus > 999) {
        errors.bonus = "TelematicsList.validation.bonus";
      }
    }

    return errors;
  };

  return (
    <>
      <Formik<TelematicsItemFormValues>
        enableReinitialize={true}
        initialValues={formikInitialValues}
        innerRef={formikRef}
        onReset={onReset}
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          errors,
          setFieldValue,
          validateField,
          values,
        }: CfFormikProps<TelematicsItemFormValues>) => {
          const handleDriverChange = (driver?: DriverTo | null) => {
            setFieldValue("driverCode", driver?.code ?? "");
          };
          const handleMachineChange = (machine?: MachineTo | null) => {
            setFieldValue("gpsUnit", machine?.gpsUnit ?? "");
            setSelectedMachine(machine);
            if (!values.equipmentCode) {
              setFieldValue("workingWidth", machine?.workingWidth ?? 0);
            }
          };
          const setNewValueAfterRecalculation = (area: number) => {
            showSnackbar({
              message: (
                <FormattedMessage id="TelematicsList.areaRecalculation.success" />
              ),
              autoHideDuration: 3000,
            });
            setFieldValue("cultivated", area);
          };
          const handleEquipmentChange = (equipment?: EquipmentTo | null) => {
            let newWorkingWidth = 0;
            if (equipment) {
              newWorkingWidth = equipment?.workingWidth || 0;
            } else if (selectedMachine) {
              newWorkingWidth = selectedMachine?.workingWidth || 0;
            }
            setFieldValue("equipmentCode", equipment?.code ?? "");
            setFieldValue("workingWidth", newWorkingWidth);
            if (isNew || isManualRecord || !driveDetail.id) return;
            const equipmentData = {
              gpsUnit: values.gpsUnit,
              equipment: equipment ? equipment?.code : undefined,
              workingWidth: newWorkingWidth,
            };

            calculateArea(
              driveDetail.id,
              equipmentData,
              setNewValueAfterRecalculation,
            );
          };

          const handleAreaRecalculationAfterValidation = (err: string) => {
            if (err) return;
            if (isNew || isManualRecord) return;
            if (values.gpsUnit && driveDetail.id) {
              calculateArea(
                driveDetail.id,
                {
                  gpsUnit: values.gpsUnit,
                  workingWidth: values.workingWidth,
                },
                setNewValueAfterRecalculation,
              );
            }
          };

          const handleWorkingWidthBlur = () => {
            const newValue =
              Math.round(toNumber(values.workingWidth) * 10000) / 10000;
            setFieldValue("workingWidth", newValue);
            validateField("workingWidth").then(
              // @ts-ignore
              handleAreaRecalculationAfterValidation,
            );
          };

          const handleBonusBlur = () => {
            setFieldValue(
              "bonus",
              Math.round(toNumber(values.bonus) * 100) / 100,
            );
          };

          const isExternal =
            driveDetail?.parcel?.affiliation === Affiliation.EXTERNAL;
          const isInternal =
            driveDetail?.parcel?.affiliation === Affiliation.COMPANY;

          // TODO: update this logic when editing is re-enabled OR delete editing mode completely
          let parcelData: Partial<TelematicsParcelType> | undefined;

          if (isEditing && isInternal && parcel) {
            parcelData = {
              ...parcel,
            };
          }
          if (!isEditing && isInternal) {
            parcelData = {
              ...driveDetail?.parcel,
              seedApplication: driveDetail?.cropName
                ? { seed: { crop: { name: driveDetail?.cropName } } }
                : undefined,
            };
          }

          const hasExternalDriver =
            driveDetail?.driver.affiliation === Affiliation.EXTERNAL;
          const hasExternalEquipment =
            driveDetail?.equipmentAffiliation === Affiliation.EXTERNAL;
          const hasExternalMachine =
            driveDetail?.machine?.affiliation === Affiliation.EXTERNAL;

          return (
            <>
              <TelematicsOperationDialog
                initialValues={values}
                onClose={handleOperationDialogClose}
                onSubmit={handleOperationDialogSubmit}
                selectedOperation={selectedOperation}
              />
              <Form>
                {parcelData && !isExternal && (
                  <TelematicsParcel
                    // eslint-disable-next-line arrow-spacing, @typescript-eslint/no-empty-function
                    handleRemoveParcel={() => {}}
                    isEditing={isEditing}
                    isFetchingParcel={isFetchingParcel}
                    parcel={parcelData}
                    parcelId={driveDetail?.parcelId}
                  />
                )}
                {!parcelData && !isExternal && <EmptyParcelBox />}
                {!parcelData && isExternal && (
                  <LpisBlock
                    area={driveDetail?.parcel?.area}
                    blockNr={driveDetail?.parcel?.blockNumber}
                    // eslint-disable-next-line arrow-spacing, @typescript-eslint/no-empty-function
                    handleRemove={() => {}}
                    isEditing={isEditing}
                    square={driveDetail?.parcel?.square}
                    subjectId={driveDetail?.parcel?.subjectId}
                    subjectName={driveDetail?.parcel?.subjectName}
                  />
                )}
                <Grid
                  className={classes.center}
                  item
                  md={5}
                  sm={6}
                  xl={4}
                  xs={10}
                >
                  <CfFormControl>
                    <Field
                      component={CfFormikDatePicker}
                      disabled={!isEditing || !isManualRecord}
                      disableFuture
                      label={<FormattedMessage id="TelematicsList.date" />}
                      name="date"
                      validate={validators.formikDateValidRequired}
                    />
                  </CfFormControl>

                  <CfFormControl>
                    <TelematicsDetailDriverSelector
                      dateFrom={values.date.toISOString()}
                      dateTo={values.date.toISOString()}
                      disabled={!isEditing}
                      error={!!errors.driverCode}
                      label={<FormattedMessage id="TelematicsList.driver" />}
                      onChange={handleDriverChange}
                      selectedDriverCode={values.driverCode}
                      helperText={
                        errors.driverCode && (
                          <FormattedMessage id={errors.driverCode} />
                        )
                      }
                    />
                    {hasExternalDriver && (
                      <div className={classes.foreignWarning}>
                        <TelematicsForeignWarning
                          text={driveDetail.driver.companyName}
                          type={TelematicsForeignWarningType.Driver}
                        />
                      </div>
                    )}
                  </CfFormControl>

                  <CfFormControl>
                    <Field
                      component={CfFormikNumericTextField}
                      disabled={!isEditing}
                      error={!!errors.bonus}
                      label={<FormattedMessage id="TelematicsList.bonus" />}
                      name="bonus"
                      onBlur={handleBonusBlur}
                      helperText={
                        errors.bonus && <FormattedMessage id={errors.bonus} />
                      }
                    />
                  </CfFormControl>

                  <CfFormControl>
                    <TelematicsDetailMachineSelector
                      dateFrom={values.date.toISOString()}
                      dateTo={values.date.toISOString()}
                      disabled={!isEditing || !isManualRecord}
                      error={!!errors.gpsUnit}
                      label={<FormattedMessage id="TelematicsList.machine" />}
                      onChange={handleMachineChange}
                      selectedGpsUnit={values.gpsUnit}
                      helperText={
                        errors.gpsUnit && (
                          <FormattedMessage id={errors.gpsUnit} />
                        )
                      }
                    />
                    {hasExternalMachine && (
                      <div className={classes.foreignWarning}>
                        <TelematicsForeignWarning
                          text={driveDetail.machine?.companyName}
                          type={TelematicsForeignWarningType.Machine}
                        />
                      </div>
                    )}
                  </CfFormControl>

                  <CfFormControl>
                    <TelematicsDetailEquipmentSelector
                      dateFrom={values.date.toISOString()}
                      dateTo={values.date.toISOString()}
                      disabled={!isEditing}
                      error={!!errors.equipmentCode}
                      onChange={handleEquipmentChange}
                      selectedCode={values.equipmentCode}
                      helperText={
                        errors.equipmentCode && (
                          <FormattedMessage id={errors.equipmentCode} />
                        )
                      }
                      label={
                        <FormattedMessage id="TelematicsList.additionalEquipment" />
                      }
                    />
                    {hasExternalEquipment && (
                      <div className={classes.foreignWarning}>
                        <TelematicsForeignWarning
                          text={driveDetail.equipmentCompanyName}
                          type={
                            TelematicsForeignWarningType.AdditionalEquipment
                          }
                        />
                      </div>
                    )}
                  </CfFormControl>

                  <CfFormControl>
                    <Field
                      component={CfFormikNumericTextField}
                      disabled={!isEditing}
                      error={!!errors.workingWidth}
                      name="workingWidth"
                      onBlur={handleWorkingWidthBlur}
                      validate={validateWorkingWidth}
                      helperText={
                        errors.workingWidth && (
                          <FormattedMessage id={errors.workingWidth} />
                        )
                      }
                      label={
                        <FormattedMessage
                          id={
                            values.equipmentCode
                              ? "TelematicsList.additionalEquipmentWidth"
                              : "TelematicsList.additionalMachineWidth"
                          }
                        />
                      }
                    />
                  </CfFormControl>
                </Grid>
                <TelematicsOperation
                  disableRemoval={!isManualRecord}
                  error={errors.operation}
                  isEditing={isEditing}
                  setSelectedOperation={setSelectedOperation}
                />

                {isEditing && (
                  <Grid item xs={12}>
                    {submitError && (
                      <div className={classes.error}>
                        {isNew ? (
                          <FormattedMessage id="TelematicsDetail.createRideError" />
                        ) : (
                          <FormattedMessage id="TelematicsDetail.editRideError" />
                        )}
                      </div>
                    )}
                    <Grid
                      alignItems="center"
                      container
                      justifyContent="center"
                      spacing={0}
                    >
                      {isNew ? (
                        <Button
                          className={classes.button}
                          disabled={isSubmitting}
                          id="reset"
                          onClick={handleCancel}
                          type="button"
                          variant="contained"
                        >
                          <FormattedMessage id="common.cancel" />
                        </Button>
                      ) : (
                        <Button
                          className={classes.button}
                          disabled={isSubmitting}
                          id="reset"
                          type="reset"
                          variant="contained"
                        >
                          <FormattedMessage id="common.cancel" />
                        </Button>
                      )}

                      <LoadingButton
                        className={classes.button}
                        color="primary"
                        // TODO: CFD-1265 re-enable after aggregation is done
                        disabled={true}
                        id="create"
                        loading={isSubmitting && !values.createMore}
                        onClick={handleCreate}
                        type="button"
                        variant="contained"
                      >
                        <FormattedMessage
                          id={isNew ? "common.create" : "common.save"}
                        />
                      </LoadingButton>

                      {isNew && (
                        <LoadingButton
                          className={classes.button}
                          color="primary"
                          // TODO: CFD-1265 re-enable after aggregation is done
                          disabled={true}
                          id="createAndCreateMore"
                          loading={isSubmitting && values.createMore}
                          onClick={handleCreateAndCreateMore}
                          type="button"
                          variant="contained"
                        >
                          <FormattedMessage id="common.createAndCreateMore" />
                        </LoadingButton>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      calculateArea,
    },
    dispatch,
  );

const mapStateToProps = (state: TelematicsState, props: Props) => ({
  machine: getMachineByGpsUnit(state, props),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TelematicsDetailContent);
