import React, { FC } from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import { COLOR_FONT } from "../../../../../theme";

interface Props {
  handleClose: () => void;
  handleAccept: () => void;
  open: boolean;
}

const ApplicationDeleteDialog: FC<Props> = ({
  handleAccept,
  handleClose,
  open,
}) => {
  const classes = useStyles();

  return (
    <CfDialog
      acceptButtonVariant="text"
      acceptText={<FormattedMessage id="common.delete" />}
      cancelText={<FormattedMessage id="common.cancel" />}
      onAccept={handleAccept}
      onCancel={handleClose}
      opened={open}
      processing={false}
      title={
        <FormattedMessage id="Catalogues.plantProtection.applications.delete.title" />
      }
    >
      <div className={classes.body}>
        <FormattedMessage id="Catalogues.plantProtection.applications.delete.desc" />
      </div>
    </CfDialog>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    color: COLOR_FONT.main,
    fontSize: 14,
  },
}));

export default ApplicationDeleteDialog;
