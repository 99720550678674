import { AnyAction } from "redux";
import { RSAAAction } from "redux-api-middleware";

import {
  getSectionListSearch,
  getSectionListPage,
  getSectionListRowsPerPage,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListAdvancedFilter,
} from "../selectors/sectionList.selectors";

import { getSelectedNamespace } from "../reducers/namespace.reducer";

import { getCropsApi } from "../../shared/api/agroevidence/catalogues/crops/crops.api";
import {
  getFertilizerApi,
  getFertilizersApi,
  resetFertilizerApi,
  resetFertilizersApi,
  updateFertilizerApi,
  createFertilizerApi,
  patchFertilizerApi,
} from "../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api";
import {
  getActiveSubstancesApi,
  getBioFunctionsApi,
  getOrganismsApi,
  getPlantProtectionsApi,
  patchPlantProtectionApi,
  resetPlantProtectionsApi,
  getPlantProtectionApi,
  resetPlantProtectionApi,
  copyPlantProtectionApi,
  createPlantProtectionApi,
  updatePlantProtectionApi,
  updatePlantProtectionApplicationApi,
  createPlantProtectionApplicationApi,
  deletePlantProtectionApplicationApi,
  getPlantProtectionsApiV2,
  getPlantProtectionDetailApiV2,
  getPlantProtectionDetailApplicationsApiV2,
  deletePlantProtectionApplicationApiV2,
  createPlantProtectionApplicationApiV2,
  updatePlantProtectionApplicationApiV2,
} from "../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import {
  createSeedApi,
  getSeedsApi,
  resetSeedsApi,
  updateSeedApi,
} from "../../shared/api/agroevidence/catalogues/seeds/seeds.api";
import { getShortDateString } from "../../shared/misc/timeHelpers";

import { CataloguesState } from "../../reducers/catalogues.reducer.types";
import {
  ActiveSubstanceTo,
  CropSeedCreateTo,
  CropSeedPatchTo,
  CropTo,
  EagriFertilizerNitrogenCategoryTo,
  FertilizerCreateTo,
  FertilizerPatchTo,
  OrganismTo,
  PlantProtectionRegistrationApplicationCreateUpdateTo,
  PlantProtectionCreateUpdateTo,
  PlantProtectionPatchTo,
  PlantProtectionApplicationCreateToV2,
} from "../../shared/api/agroevidence/agroevidence.types";
import { getFertilizersParams } from "../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.types";
import { getPlantProtectionParams } from "../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.types";
import { getSeedsParams } from "../../shared/api/agroevidence/catalogues/seeds/seeds.types";

// --------------------------------------------------------//
// ---------- FERTILIZERS CATALOGUES SECTION --------------//
// --------------------------------------------------------//

export const fetchFertilizers =
  (validOn?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getFertilizersParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params.organic = advancedFilter.isOrganic?.state;
      params["eagri-nitrogen-category"] = advancedFilter.nitrogenCategory
        ?.map((nc: EagriFertilizerNitrogenCategoryTo) => nc.code)
        ?.join(",");
      params.catalogue = advancedFilter.source?.type;
    }

    if (validOn) {
      params["valid-on"] = validOn;
    }

    dispatch(getFertilizersApi(params));
  };

export const resetFertilizers = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetFertilizersApi());

export const fetchFertilizer =
  (fertilizerId: string) => (dispatch: (action: RSAAAction) => void) =>
    dispatch(getFertilizerApi(fertilizerId));

export const resetFertilizer = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetFertilizerApi());

export const patchFertilizer =
  (fertilizerId: string, params: FertilizerPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(patchFertilizerApi(fertilizerId, params));

export const updateFertilizer =
  (fertilizerId: string, params: FertilizerCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updateFertilizerApi(fertilizerId, params));

export const createFertilizer =
  (params: FertilizerCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createFertilizerApi(params));

// -------------------------------------------------------//
// ------------- SEEDS CATALOGUES SECTION ----------------//
// -------------------------------------------------------//

export const fetchSeeds =
  (validOn?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getSeedsParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params["external-ids"] = advancedFilter.crops
        ?.map((nc: CropTo) => nc.externalId)
        ?.join(",");
      params.catalogue = advancedFilter.source?.type;
    }

    if (validOn) {
      params["valid-on"] = validOn;
    }

    dispatch(getSeedsApi(params));
  };

export const resetSeeds = () => (dispatch: (action: AnyAction) => void) =>
  dispatch(resetSeedsApi());

export const updateSeed =
  (seedId: string, params: CropSeedPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updateSeedApi(seedId, params));

export const createSeed =
  (params: CropSeedCreateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createSeedApi(params));

export const fetchCrops =
  (search?: string, farmId?: string) =>
  (dispatch: (action: RSAAAction) => void) => {
    const validOn = getShortDateString();
    const params = {
      search,
      "valid-on": validOn,
      farmIds: farmId,
    };
    if (farmId === undefined) {
      delete params.farmIds;
    }
    dispatch(getCropsApi(params));
  };

// --------------------------------------------------------//
// -------- PLAN PROTECTION CATALOGUES SECTION ------------//
// --------------------------------------------------------//

export const fetchPlantProtectionsV2 =
  (usableUntil?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getPlantProtectionParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params["crop-id"] = advancedFilter.crops
        ?.map((o: CropTo) => o.id)
        ?.join(",");
      params["organism-id"] = advancedFilter.organisms
        ?.map((o: OrganismTo) => o.id)
        ?.join(",");
      params["active-substance-id"] = advancedFilter.activeSubstances
        ?.map((as: ActiveSubstanceTo) => as.id)
        ?.join(",");
      params.catalogue = advancedFilter.source?.id;
    }

    if (usableUntil) {
      params["usable-until"] = usableUntil;
    }

    dispatch(getPlantProtectionsApiV2(params));
  };

export const fetchPlantProtections =
  (usableUntil?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getPlantProtectionParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      "per-page": getSectionListRowsPerPage(state),
      "sort-col": getSectionListOrderBy(state),
      "sort-dir": getSectionListOrder(state),
    };

    if (advancedFilter) {
      params["crop-id"] = advancedFilter.crops
        ?.map((o: CropTo) => o.id)
        ?.join(",");
      params["organism-id"] = advancedFilter.organisms
        ?.map((o: OrganismTo) => o.id)
        ?.join(",");
      params["active-substance-id"] = advancedFilter.activeSubstances
        ?.map((as: ActiveSubstanceTo) => as.id)
        ?.join(",");
      params.catalogue = advancedFilter.source?.type;
    }

    if (usableUntil) {
      params["usable-until"] = usableUntil;
    }

    dispatch(getPlantProtectionsApi(params));
  };

export const resetPlantProtections =
  () => (dispatch: (action: AnyAction) => void) =>
    dispatch(resetPlantProtectionsApi());

export const fetchPlantProtectionV2 =
  (plantProtectionId: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getPlantProtectionDetailApiV2(plantProtectionId));
  };

export const fetchPlantProtectionApplicationsV2 =
  (plantProtectionId: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getPlantProtectionDetailApplicationsApiV2(plantProtectionId));
  };

export const fetchPlantProtection =
  (plantProtectionId: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getPlantProtectionApi(plantProtectionId));
  };

export const copyPlantProtection =
  (plantProtectionId: string) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(copyPlantProtectionApi(plantProtectionId));

export const resetPlantProtection =
  () => (dispatch: (action: AnyAction) => void) =>
    dispatch(resetPlantProtectionApi());

export const patchPlantProtection =
  (plantProtectionId: string, params: PlantProtectionPatchTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(patchPlantProtectionApi(plantProtectionId, params));

export const updatePlantProtection =
  (plantProtectionId: string, params: PlantProtectionCreateUpdateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(updatePlantProtectionApi(plantProtectionId, params));

export const createPlantProtection =
  (params: PlantProtectionCreateUpdateTo) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createPlantProtectionApi(params));

export const fetchActiveSubstances =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getActiveSubstancesApi(search));
  };

export const fetchOrganisms =
  (search: string, isUsed: boolean, validOnly: boolean) =>
  (dispatch: (action: RSAAAction) => void) => {
    if (isUsed) {
      return dispatch(getOrganismsApi({ search, "is-used": isUsed }));
    }

    if (validOnly) {
      return dispatch(getOrganismsApi({ search, "valid-only": validOnly }));
    }

    dispatch(getOrganismsApi({ search }));
  };

export const fetchBioFunctions =
  (search?: string) => (dispatch: (action: RSAAAction) => void) => {
    dispatch(getBioFunctionsApi(search));
  };

export const updatePlantProtectionApplication =
  (
    plantProtectionId: string,
    applicationId: string,
    params: PlantProtectionRegistrationApplicationCreateUpdateTo,
  ) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(
      updatePlantProtectionApplicationApi(
        plantProtectionId,
        applicationId,
        params,
      ),
    );

export const createPlantProtectionApplication =
  (
    plantProtectionId: string,
    params: PlantProtectionRegistrationApplicationCreateUpdateTo,
  ) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createPlantProtectionApplicationApi(plantProtectionId, params));

export const deletePlantProtectionApplication =
  (plantProtectionId: string, applicationId: string) =>
  (dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>) =>
    dispatch(
      deletePlantProtectionApplicationApi(plantProtectionId, applicationId),
    );

export const createPlantProtectionApplicationV2 =
  (plantProtectionId: string, params: PlantProtectionApplicationCreateToV2) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(createPlantProtectionApplicationApiV2(plantProtectionId, params));

export const updatePlantProtectionApplicationV2 =
  (
    plantProtectionId: string,
    applicationId: string,
    params: PlantProtectionApplicationCreateToV2,
  ) =>
  (dispatch: (action: RSAAAction) => Promise<void>) =>
    dispatch(
      updatePlantProtectionApplicationApiV2(
        plantProtectionId,
        applicationId,
        params,
      ),
    );

export const deletePlantProtectionApplicationV2 =
  (plantProtectionId: string, applicationId: string) =>
  (dispatch: (action: RSAAAction) => Promise<Record<string, unknown>>) =>
    dispatch(
      deletePlantProtectionApplicationApiV2(plantProtectionId, applicationId),
    );
