import React from "react";

import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import get from "lodash/get";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";

import TableCell from "./TableCell";

const StatsFooterPure = (props) => (
  <TableRow>
    <TableCell
      key="name"
      classes={{
        root: `${props.classes.statCellBorderTop} ${props.classes.firstCell}`,
      }}
    >
      <FormattedMessage id="ParcelZoneStatistics.total" />
    </TableCell>
    <TableCell
      key="count"
      classes={{
        root: `${props.classes.statCellBorderTop} ${props.classes.cell}`,
      }}
    >
      <CfTableCellNumber>{props.stats.count}</CfTableCellNumber>
    </TableCell>
    <TableCell
      key="area"
      classes={{
        root: `${props.classes.statCellBorderTop} ${props.classes.cell}`,
      }}
    >
      <CfTableCellNumber>
        <CfFormattedNumber value={get(props.stats, "totalArea", null) || 0} />
      </CfTableCellNumber>
    </TableCell>
  </TableRow>
);

StatsFooterPure.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  statCellBorderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  statCellBorderBottom: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    color: theme.palette.grey[500],
    backgroundColor: "white",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
});

export const StatsFooter = withStyles(styles)(StatsFooterPure);
