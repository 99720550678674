const TOGGLES = {
  LPIS_UPDATE_TOGGLE: {
    name: "LPIS_UPDATE_TOGGLE",
    jira: "CFD-2361",
    description: "LPIS update farm",
    active: false,
    permanent: false,
  },
  NEW_POR_TOGGLE: {
    name: "NEW_POR_TOGGLE",
    jira: "CFD-5838",
    description: "Catalogues: redisign POR tab",
    active: false,
    permanent: false,
  },
  // example:
  // MY_FIRST_TOGGLE: {
  //   name: 'MY_FIRST_TOGGLE', //should be same as key (case sensitive)
  //   jira: 'JIRA ticket ID',
  //   active: false, //active by default
  //   permanent: true //overrides user preferences
  // }
};

export default TOGGLES;
