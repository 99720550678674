import React from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getIsFetchingReport } from "../../../../shared/api/agroevidence/reports/reports.selectors";

import { exportFarmNitrogen } from "../../actions/reports.actions";

import { useToggle } from "../../../../shared/hooks/useToggle";
import { FarmerPortalHeading } from "../shared";

import ReportOrganicNitrogenDialog from "./ReportOrganicNitrogenDialog";

const XLSX = "xlsx";

const ReportOrganicNitrogen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isFetchingReports = useSelector(getIsFetchingReport);
  const { formatMessage } = useIntl();
  const exportLabel = formatMessage({ id: "common.export" });

  const {
    on: showDialog,
    setOff: handleDialogClose,
    setOn: handleDialogOpen,
  } = useToggle();

  const onExportFarmNitrogen = (date: string) => {
    dispatch(exportFarmNitrogen(date, XLSX));
  };

  return (
    <>
      {showDialog && (
        <ReportOrganicNitrogenDialog
          onAccept={onExportFarmNitrogen}
          onClose={handleDialogClose}
          showDialog={showDialog}
        />
      )}
      <FarmerPortalHeading titleTranslateString="Reports.organicNitrogen.Heading" />
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage id="Reports.organicNitrogen.Info" />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button
          color="primary"
          disabled={isFetchingReports}
          onClick={handleDialogOpen}
          variant="contained"
        >
          {`${exportLabel} ${XLSX}`}
          {isFetchingReports && (
            <CircularProgress className={classes.progress} size={21} />
          )}
        </Button>
      </div>
    </>
  );
};

export default ReportOrganicNitrogen;

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  progress: {
    marginLeft: 10,
  },
}));
