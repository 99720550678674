import React from "react";

import { setFocusedRow } from "../../../../shared/actions/parcels.actions";

import CfBackButton from "../../../../../../shared/components/common/CfBackButton/CfBackButton";
import useFocusedTableRowSource from "../../../../../../shared/hooks/useFocusedTableRowSource";
import { AnyTodo } from "../../../../../../types";

type Props = {
  farmId: string;
  history: AnyTodo;
  parcelId: string;
};

const ParcelDetailButtonBack = ({ farmId, history, parcelId }: Props) => {
  const { doSetFocusedRow } = useFocusedTableRowSource({
    sourceId: parcelId,
    setFocusedRow,
  });

  return (
    <CfBackButton
      translId="ParcelDetail.backToParcels"
      onClick={() => {
        doSetFocusedRow();
        history.push(`/farm/${farmId}/parcels`);
      }}
    />
  );
};

export default ParcelDetailButtonBack;
