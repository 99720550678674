import React, { FC, useContext } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import LaunchIcon from "@mui/icons-material/Launch";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { IconButton, Checkbox, TextField, Tooltip } from "@mui/material";
import classNames from "classnames";
import { Field } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import { copyPlantProtection } from "../../../../actions/catalogues.actions";

import CfBackButton from "../../../../../shared/components/common/CfBackButton/CfBackButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import * as validators from "../../../../../shared/misc/validators";
import { CataloguesContext } from "../../../../containers/CataloguesWrapper/CataloguesWrapper";
import { usePlantProtectionDetailStyles } from "../PlantProtectionDetail.styles";

import { PlantProtectionDetailTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  backButtonLabel: string;
  eagriRegistrationId?: string;
  canUpdate?: boolean;
  defaultIsFavorite: boolean;
  defaultName: string;
  error: boolean;
  handleFavoriteClick: () => void;
  handleGoBack: () => void;
  handleStartEdit: () => void;
  isEditing: boolean;
  itemNamePlaceholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  plantProtectionId: string;
}

const PlantProtectionDetailHeader: FC<Props> = ({
  backButtonLabel,
  canUpdate = false,
  defaultIsFavorite,
  defaultName,
  eagriRegistrationId,
  error = false,
  handleFavoriteClick,
  handleGoBack,
  handleStartEdit,
  isEditing,
  itemNamePlaceholder,
  onChange,
  plantProtectionId,
}) => {
  const classes = usePlantProtectionDetailStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const showSnackbar = useContext(SnackbarContext);

  const { farmId } = useContext(CataloguesContext);

  const handleLinkToEagri = () =>
    window.open(
      `https://eagri.cz/public/app/eagriapp/POR/Detail.aspx?id=${eagriRegistrationId}`,
      "_blank",
    );

  const handleCopyPlantProtection = () =>
    (
      dispatch(
        copyPlantProtection(plantProtectionId),
      ) as unknown as Promise<unknown>
    ).then((res: RSAAResultAction<PlantProtectionDetailTo>) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.copy.error" />
          ),
          isError: true,
        });
      } else {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.copy.success" />
          ),
          isSuccess: true,
        });
        history.push(
          `/farm/${farmId}/catalogues/plantProtectionReact/${res.payload.id}`,
        );
      }
    });

  return (
    <PageHeader
      actionButtons={
        <div className={classes.headerActionButtons}>
          <Tooltip
            followCursor
            title={
              <span>
                <FormattedMessage id="Catalogues.plantProtection.detail.copy.tooltip" />
              </span>
            }
          >
            <IconButton
              data-test="ppp-copy"
              disabled={isEditing}
              onClick={handleCopyPlantProtection}
              size="large"
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          {!canUpdate && eagriRegistrationId && (
            <Tooltip
              followCursor
              title={
                <span>
                  <FormattedMessage id="Catalogues.plantProtection.detail.eAgriLink.tooltip" />
                </span>
              }
            >
              <IconButton
                data-test="eAgri-link"
                onClick={handleLinkToEagri}
                size="large"
              >
                <LaunchIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            followCursor
            title={
              canUpdate ? (
                ""
              ) : (
                <span>
                  <FormattedMessage
                    id="Catalogues.plantProtection.detail.editOff.tooltip"
                    values={{
                      br: <br />,
                      b: (chunks: string) => <b>{chunks}</b>,
                    }}
                  />
                </span>
              )
            }
          >
            {/* https://github.com/mui/material-ui/issues/8416 */}
            <div>
              <IconButton
                data-test="ppp-edit"
                disabled={!canUpdate}
                onClick={handleStartEdit}
                size="large"
              >
                {canUpdate ? <EditIcon /> : <EditOffIcon />}
              </IconButton>
            </div>
          </Tooltip>
        </div>
      }
      backButton={
        <CfBackButton onClick={handleGoBack} translId={backButtonLabel} />
      }
      heading={
        <PageHeading
          customClass={classes.heading}
          value={
            <>
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage
                    id={
                      defaultIsFavorite
                        ? "Catalogues.detail.shared.removeFromFavorites.tooltip"
                        : "Catalogues.detail.shared.addToFavorites.tooltip"
                    }
                  />
                }
              >
                <Checkbox
                  checked={defaultIsFavorite}
                  checkedIcon={<StarRoundedIcon />}
                  color="secondary"
                  data-test="favourite"
                  icon={<StarBorderRoundedIcon />}
                  onClick={handleFavoriteClick}
                />
              </Tooltip>
              <Field
                component={TextField}
                data-test="ppp-name"
                disabled={!isEditing}
                error={error}
                name="name"
                onChange={onChange}
                placeholder={intl.formatMessage({ id: itemNamePlaceholder })}
                validate={validators.required}
                value={defaultName}
                helperText={
                  error ? <FormattedMessage id="validation.required" /> : ""
                }
                InputProps={{
                  classes: {
                    root: classNames(classes.textField, {
                      [classes.headingContentConfirming]: !isEditing,
                      [classes.headingValidationError]: error,
                    }),
                    input: classes.headingFontSize,
                  },
                }}
              />
            </>
          }
        />
      }
    />
  );
};

export default PlantProtectionDetailHeader;
