import { AnyAction } from "redux";

import * as types from "./plantProtection.constants";

import { FormPlantProtectionApplications } from "./plantProtection.types";

const initialState = {
  items: [],
  itemsV2: [],
  totalCount: 0,
  isFetching: false,
  isFetchingBioFunctions: false,
  isFetchingOrganisms: false,
  isFetchingActiveSubstances: false,
  activeSubstances: [],
  organisms: [],
  bioFunctions: [],
  item: null,
  itemV2: null,
  itemV2Applications: [],
  itemOrganisms: [],
  applications: [],
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_PLANT_PROTECTIONS_V2_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_PLANT_PROTECTIONS_V2_SUCCESS:
        return {
          ...state,
          isFetching: false,
          itemsV2: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_PLANT_PROTECTIONS_V2_ERROR:
        return {
          ...state,
          isFetching: false,
          totalCount: 0,
        };
      case types.GET_PLANT_PROTECTIONS_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.GET_PLANT_PROTECTIONS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          items: action.payload,
          totalCount: Number(action.meta.headers.get("X-Total-Count")),
        };
      case types.GET_PLANT_PROTECTIONS_ERROR:
        return {
          ...state,
          isFetching: false,
          totalCount: 0,
        };
      case types.RESET_PLANT_PROTECTIONS:
        return {
          ...state,
          items: [],
          itemsV2: [],
          totalCount: 0,
        };

      case types.GET_PLANT_PROTECTION_V2_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case types.GET_PLANT_PROTECTION_V2_SUCCESS:
      case types.COPY_PLANT_PROTECTION_V2_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          itemV2: action.payload,
        };
      }
      case types.GET_PLANT_PROTECTION_V2_ERROR: {
        return {
          ...state,
          isFetching: false,
        };
      }

      case types.GET_PLANT_PROTECTION_APPLICATION_V2_REQUEST:
        return {
          ...state,
          isFetchingApplications: true,
        };
      case types.GET_PLANT_PROTECTION_APPLICATION_V2_SUCCESS:
        return {
          ...state,
          itemV2Applications: action.payload,
          isFetchingApplications: false,
        };
      case types.GET_PLANT_PROTECTION_APPLICATION_V2_ERROR:
        return {
          ...state,
          isFetchingApplications: false,
        };

      case types.GET_PLANT_PROTECTION_REQUEST: {
        return {
          ...state,
          isFetching: true,
        };
      }
      case types.GET_PLANT_PROTECTION_SUCCESS:
      case types.COPY_PLANT_PROTECTION_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          item: action.payload,
        };
      }
      case types.GET_PLANT_PROTECTION_ERROR: {
        return {
          ...state,
          isFetching: false,
        };
      }
      case types.RESET_PLANT_PROTECTION: {
        return {
          ...state,
          item: null,
          itemV2: null,
          itemV2Applications: [],
        };
      }

      case types.GET_ACTIVE_SUBSTANCES_REQUEST:
        return {
          ...state,
          isFetchingActiveSubstances: true,
        };
      case types.GET_ACTIVE_SUBSTANCES_SUCCESS:
        return {
          ...state,
          isFetchingActiveSubstances: false,
          activeSubstances: action.payload,
        };
      case types.GET_ACTIVE_SUBSTANCES_ERROR:
        return {
          ...state,
          isFetchingActiveSubstances: false,
        };
      case types.RESET_ACTIVE_SUBSTANCES:
        return {
          ...state,
          activeSubstances: [],
        };

      case types.GET_ORGANISMS_REQUEST:
        return {
          ...state,
          isFetchingOrganisms: true,
        };
      case types.GET_ORGANISMS_SUCCESS:
        return {
          ...state,
          isFetchingOrganisms: false,
          organisms: action.payload,
        };
      case types.GET_ORGANISMS_ERROR:
        return {
          ...state,
          isFetchingOrganisms: false,
        };
      case types.RESET_ORGANISMS:
        return {
          ...state,
          organisms: [],
        };

      case types.GET_BIO_FUNCTION_REQUEST:
        return {
          ...state,
          isFetchingBioFunctions: true,
        };
      case types.GET_BIO_FUNCTION_SUCCESS:
        return {
          ...state,
          isFetchingBioFunctions: false,
          bioFunctions: action.payload,
        };
      case types.GET_BIO_FUNCTION_ERROR:
        return {
          ...state,
          isFetchingBioFunctions: false,
        };
      case types.RESET_BIO_FUNCTION:
        return {
          ...state,
          bioFunctions: [],
        };

      case types.GET_PLANT_PROTECTION_ORGANISMS_SUCCESS:
        return {
          ...state,
          itemOrganisms: action.payload,
        };
      case types.RESET_PLANT_PROTECTION_ORGANISMS:
        return {
          ...state,
          itemOrganisms: [],
        };
      case types.CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS:
        return {
          ...state,
          applications: action.payload,
          isFetchingApplications: false,
        };
      case types.CREATE_PLANT_PROTECTION_APPLICATION_REQUEST:
        return {
          ...state,
          isFetchingApplications: true,
        };
      case types.CREATE_PLANT_PROTECTION_APPLICATION_ERROR:
        return {
          ...state,
          isFetchingApplications: false,
        };
      case types.GET_PLANT_PROTECTION_APPLICATION_REQUEST:
        return {
          ...state,
          isFetchingApplications: true,
        };
      case types.GET_PLANT_PROTECTION_APPLICATION_SUCCESS:
        return {
          ...state,
          applications: [
            ...state.applications,
            {
              plantProtectionId: action.meta.plantProtectionId,
              applications: action.payload,
            },
          ],
          isFetchingApplications: false,
        };
      case types.GET_PLANT_PROTECTION_APPLICATION_ERROR:
        return {
          ...state,
          isFetchingApplications: false,
        };
      case types.REMOVE_PLANT_PROTECTION_APPLICATION:
        return {
          ...state,
          applications: state.applications.filter(
            (item: FormPlantProtectionApplications) =>
              item.plantProtectionId !== action.meta.id,
          ),
        };

      default:
        return state;
    }
  };
