import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./plantProtection.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../../api.constants";

import { getPlantProtectionParams } from "./plantProtection.types";
import { RsaaMethods, RsaaTypes } from "../../../api.types";
import {
  PlantProtectionRegistrationApplicationCreateUpdateTo,
  PlantProtectionCreateUpdateTo,
  PlantProtectionPatchTo,
  PlantProtectionPatchV2To,
  PlantProtectionCreateUpdateV2To,
  PlantProtectionApplicationCreateToV2,
} from "../../agroevidence.types";

export const getPlantProtectionsApi = (params: getPlantProtectionParams) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_REQUEST,
      types.GET_PLANT_PROTECTIONS_SUCCESS,
      types.GET_PLANT_PROTECTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionsSuggestionsApi = (
  params: getPlantProtectionParams,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/suggestions?${queryString.stringify(
      params,
    )}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_REQUEST,
      types.GET_PLANT_PROTECTIONS_SUCCESS,
      types.GET_PLANT_PROTECTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionsApi = () => ({
  type: types.RESET_PLANT_PROTECTIONS,
});

export const getPlantProtectionApi = (plantProtectionId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_REQUEST,
      types.GET_PLANT_PROTECTION_SUCCESS,
      types.GET_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionApi = () => ({
  type: types.RESET_PLANT_PROTECTION,
});

export const copyPlantProtectionApi = (plantProtectionId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/copy?`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.COPY_PLANT_PROTECTION_REQUEST,
      types.COPY_PLANT_PROTECTION_SUCCESS,
      types.COPY_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const patchPlantProtectionApi = (
  plantProtectionId: string,
  params: PlantProtectionPatchTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const updatePlantProtectionApi = (
  plantProtectionId: string,
  params: PlantProtectionCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionApi = (
  params: PlantProtectionCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: "catalogues/plant-protection?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_REQUEST,
      types.CREATE_PLANT_PROTECTION_SUCCESS,
      types.CREATE_PLANT_PROTECTION_ERROR,
    ] as RsaaTypes,
  },
});

export const getActiveSubstancesApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/active-substances?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ACTIVE_SUBSTANCES_REQUEST,
      types.GET_ACTIVE_SUBSTANCES_SUCCESS,
      types.GET_ACTIVE_SUBSTANCES_ERROR,
    ] as RsaaTypes,
  },
});

export const resetActiveSubstancesApi = () => ({
  type: types.RESET_ACTIVE_SUBSTANCES,
});

export interface ParcelsApiParams {
  search: string;
  "is-used"?: boolean;
  "valid-only"?: boolean;
}

export const getOrganismsApi = (params: ParcelsApiParams) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/organisms?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_ORGANISMS_REQUEST,
      types.GET_ORGANISMS_SUCCESS,
      types.GET_ORGANISMS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetOrganismsApi = () => ({
  type: types.RESET_ORGANISMS,
});

export const getBioFunctionsApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/bio-function?${
      search ? `search=${search}&` : ""
    }&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_BIO_FUNCTION_REQUEST,
      types.GET_BIO_FUNCTION_SUCCESS,
      types.GET_BIO_FUNCTION_ERROR,
    ] as RsaaTypes,
  },
});

export const resetBioFunctionApi = () => ({
  type: types.RESET_BIO_FUNCTION,
});

export const getPlantProtectionApplicationApi = (
  plantProtectionId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/applications?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_APPLICATION_REQUEST,
      {
        type: types.GET_PLANT_PROTECTION_APPLICATION_SUCCESS,
        meta: { plantProtectionId },
      },
      types.GET_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const removeApplication = (id?: string) => ({
  type: types.REMOVE_PLANT_PROTECTION_APPLICATION,
  meta: {
    id,
  },
});

export const updatePlantProtectionApplicationApi = (
  plantProtectionId: string,
  applicationId: string,
  params: PlantProtectionRegistrationApplicationCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionApplicationApi = (
  plantProtectionId: string,
  params: PlantProtectionRegistrationApplicationCreateUpdateTo,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/applications?`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.CREATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const deletePlantProtectionApplicationApi = (
  plantProtectionId: string,
  applicationId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.DELETE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.DELETE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionOrganismsApi = (plantProtectionId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/plant-protection/${plantProtectionId}/organisms?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_ORGANISMS_REQUEST,
      types.GET_PLANT_PROTECTION_ORGANISMS_SUCCESS,
      types.GET_PLANT_PROTECTION_ORGANISMS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetPlantProtectionOrganismsApi = () => ({
  type: types.RESET_PLANT_PROTECTION_ORGANISMS,
});

// ===========================  V2  ============================

export const getPlantProtectionsApiV2 = (params: getPlantProtectionParams) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/overview?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_V2_REQUEST,
      types.GET_PLANT_PROTECTIONS_V2_SUCCESS,
      types.GET_PLANT_PROTECTIONS_V2_ERROR,
    ] as RsaaTypes,
  },
});

// TODO replace getPlantProtectionsSuggestionsApi when new POR TAB will be finished
export const getPlantProtectionsSuggestionsApiV2 = (
  params: getPlantProtectionParams,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/suggestions?${queryString.stringify(
      params,
    )}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTIONS_V2_REQUEST,
      types.GET_PLANT_PROTECTIONS_V2_SUCCESS,
      types.GET_PLANT_PROTECTIONS_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionDetailApiV2 = (plantProtectionId: string) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/detail?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_V2_REQUEST,
      types.GET_PLANT_PROTECTION_V2_SUCCESS,
      types.GET_PLANT_PROTECTION_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const getPlantProtectionDetailApplicationsApiV2 = (
  plantProtectionId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/detail/applications?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_APPLICATION_V2_REQUEST,
      types.GET_PLANT_PROTECTION_APPLICATION_V2_SUCCESS,
      types.GET_PLANT_PROTECTION_APPLICATION_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const copyPlantProtectionApiV2 = (
  plantProtectionId: string,
  registrationsId?: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/copy?${
      registrationsId ? `registration-ids=${registrationsId}&` : ""
    }`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.COPY_PLANT_PROTECTION_V2_REQUEST,
      types.COPY_PLANT_PROTECTION_V2_SUCCESS,
      types.COPY_PLANT_PROTECTION_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const patchPlantProtectionApiV2 = (
  plantProtectionId: string,
  params: PlantProtectionPatchV2To,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_V2_REQUEST,
      types.UPDATE_PLANT_PROTECTION_V2_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const updatePlantProtectionApiV2 = (
  plantProtectionId: string,
  params: PlantProtectionCreateUpdateV2To,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_V2_REQUEST,
      types.UPDATE_PLANT_PROTECTION_V2_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_V2_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionApiV2 = (
  params: PlantProtectionCreateUpdateV2To,
) => ({
  [RSAA]: {
    endpoint: "catalogues/v2/plant-protections?",
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_V2_REQUEST,
      types.CREATE_PLANT_PROTECTION_V2_SUCCESS,
      types.CREATE_PLANT_PROTECTION_V2_ERROR,
    ] as RsaaTypes,
  },
});

// TODO replace getPlantProtectionApplicationApi when new POR TAB will be finished
export const getPlantProtectionApplicationApiV2 = (
  plantProtectionId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/applications?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PLANT_PROTECTION_APPLICATION_REQUEST,
      {
        type: types.GET_PLANT_PROTECTION_APPLICATION_SUCCESS,
        meta: { plantProtectionId },
      },
      types.GET_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const createPlantProtectionApplicationApiV2 = (
  plantProtectionId: string,
  params: PlantProtectionApplicationCreateToV2,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/applications?`,
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.CREATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.CREATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.CREATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const updatePlantProtectionApplicationApiV2 = (
  plantProtectionId: string,
  applicationId: string,
  params: PlantProtectionApplicationCreateToV2,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.PUT as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.UPDATE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.UPDATE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const deletePlantProtectionApplicationApiV2 = (
  plantProtectionId: string,
  applicationId: string,
) => ({
  [RSAA]: {
    endpoint: `catalogues/v2/plant-protections/${plantProtectionId}/applications/${applicationId}?`,
    method: methods.DELETE as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.DELETE_PLANT_PROTECTION_APPLICATION_REQUEST,
      types.DELETE_PLANT_PROTECTION_APPLICATION_SUCCESS,
      types.DELETE_PLANT_PROTECTION_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});
