import React, { FC, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsExportingData,
  getParcels,
  getParcelsError,
} from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getParcelListTextFilter,
  getParcelListSelectedOnPage,
  getParcelListAdvancedFilter,
} from "../../selectors/parcelList.selectors";

import { setAdvancedFilter } from "../../../../../shared/actions/filter.actions";
import { setTableSelected } from "../../../../../shared/actions/table.actions";
import {
  assignCenter,
  assignZones,
} from "../../../shared/actions/parcels.actions";
import { exportParcels } from "../../actions/parcelList.actions";

import { NAMESPACE as namespace } from "../../reducer/parcelList.reducer";

import {
  updateZoneApi,
  createZoneApi,
  deleteZoneApi,
} from "../../../../../shared/api/agroevidence/zones/zones.api";
import CfErrorPage from "../../../../../shared/components/common/CfErrorPage/CfErrorPage";
import { ExportButton } from "../../../../../shared/components/common/ExportButton/ExportButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import withWidth from "../../../../../shared/hocs/withWidth";
import { AsyncFn, RsaaApiError, Thunk } from "../../../../../types";
import ParcelListSeason from "../../components/PacelListSeason/ParcelListSeason";
import ParcelListActions from "../../components/ParcelListActions/ParcelListActions";
import ParcelListTextFilter from "../../components/ParcelListTextFilter/ParcelListTextFilter";
import ParcelAdvancedFilter from "../ParcelAdvancedFilter/ParcelAdvancedFilter";
import ParcelTable from "../ParcelTable/ParcelTable";
import ParcelZoneStatistics from "../ParcelZoneStatistics/ParcelZoneStatistics";

import type { CreateUpdateZoneParams } from "../../../../../shared/api/agroevidence/zones/zones.api";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  CropTo,
  ParcelTo,
  ZoneDetailTo,
  ZoneTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ParcelListAdvancedFilter } from "../../../../../shared/api/agroevidence/parcels/parcels.types";

type ReduxProps = ConnectedProps<typeof connector>;
export interface ParcelListProps {
  advancedFilter: ParcelListAdvancedFilter;
  assignCenter: (centerId: string, parcelId: string) => Promise<void>;
  deleteZoneApi: (zoneId: string) => void;
  error?: RsaaApiError;
  exportParcels: () => void;
  farmId: string;
  isExporting: boolean;
  langId: string;
  ngRedirectToEph: (selectedOnPage: string[]) => void;
  ngRedirectToHarvest: (selectedOnPage: string[]) => void;
  ngRedirectToMowing: (selectedOnPage: string[]) => void;
  ngRedirectToOtherActionsNew: (selectedOnPage: string[]) => void;
  ngRedirectToSowing: (selectedOnPage: string[]) => void;
  selectedOnPage: string[];
  setAdvancedFilter: (
    advancedFilter: ParcelListAdvancedFilter,
    namespace: string,
  ) => void;
  setTableSelected: (selected: string[], namespace: string) => void;
  textFilter: string;
  updateZoneApi: (zoneId: string, params: CreateUpdateZoneParams) => void;
}

export const ParcelList: FC<ParcelListProps & ReduxProps> = ({
  advancedFilter = {},
  assignCenter,
  assignZones,
  createZoneApi,
  deleteZoneApi,
  error,
  exportParcels,
  farmId,
  isExporting,
  langId,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToSowing,
  parcels,
  selectedOnPage,
  setAdvancedFilter,
  setTableSelected,
  textFilter,
  updateZoneApi,
}) => {
  const classes = useStyles();
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(
    () => () => {
      setTableSelected([], namespace);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAssignZone = (zoneId: string, parcelIds: string[], bulk: boolean) => {
    const promises = [] as Promise<void>[];
    const selectedParcels = parcels.filter((parcel: ParcelTo) =>
      parcelIds.includes(parcel.id),
    );

    selectedParcels.forEach((parcel: ParcelTo) => {
      const currentParcelZones = parcel.zones ?? [];
      const currentParcelZoneIds = currentParcelZones.map((zone) => zone.id);
      promises.push(
        assignZones(
          [...currentParcelZoneIds, zoneId],
          parcel.id,
        ) as unknown as Promise<void>,
      );
    });
    Promise.all(promises).then(() => {
      setShouldReloadData((prevState) => !prevState);
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onAssignCenter = (
    centerId: string,
    parcelIds: string[],
    bulk: boolean,
  ) => {
    const promises = [] as Promise<void>[];
    parcelIds.forEach((parcelId: string) => {
      promises.push(assignCenter(centerId, parcelId));
    });
    Promise.all(promises).then(() => {
      setShouldReloadData((prevState) => !prevState);
      if (bulk) {
        setTableSelected([], namespace);
      }
    });
  };

  const onCreateZone = (zone: ZoneTo) => {
    const params: CreateUpdateZoneParams = {
      name: zone.name,
    };
    (createZoneApi as unknown as AsyncFn<CreateUpdateZoneParams>)(params).then(
      () => setShouldReloadData((prevState) => !prevState),
    );
  };

  const onUpdateZone = (zoneToUpdate: ZoneTo) => {
    const params: CreateUpdateZoneParams = {
      name: zoneToUpdate.name,
    };
    (updateZoneApi as unknown as AsyncFn<string, CreateUpdateZoneParams>)(
      zoneToUpdate.id,
      params,
    ).then(() => {
      if (advancedFilter.zones) {
        const updatedZones = advancedFilter.zones.map((zone) => {
          if (zone.id === zoneToUpdate.id) {
            return { ...zone, ...zoneToUpdate };
          }
          return zone;
        });
        setAdvancedFilter(
          { ...advancedFilter, zones: updatedZones },
          namespace,
        );
      }
      setShouldReloadData((prevState) => !prevState);
    });
  };

  const onDeleteZone = (zoneToDelete: ZoneDetailTo) => {
    (deleteZoneApi as unknown as AsyncFn<string>)(zoneToDelete.id).then(() => {
      if (advancedFilter.zones) {
        const updatedZones = advancedFilter.zones.filter(
          (zone) => zone.id !== zoneToDelete.id,
        );
        setAdvancedFilter(
          { ...advancedFilter, zones: updatedZones },
          namespace,
        );
      }
      setShouldReloadData((prevState) => !prevState);
    });
  };

  const onFilterZoneInParcels = (zones: ZoneDetailTo[]) => {
    setAdvancedFilter({ zones }, namespace);
  };

  const onFilterCropInParcels = (crops: CropTo[]) => {
    setAdvancedFilter({ crops }, namespace);
  };

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <PageHeader
          actionButtons={
            <div className={classes.exportButton}>
              <ExportButton
                aria-label="export"
                isLoading={isExporting}
                onClick={exportParcels}
              />
            </div>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="parcelDetail-heading"
              value={<FormattedMessage id="common.parcels" />}
            />
          }
        />
        <Grid container spacing={1}>
          <Grid container spacing={0}>
            <Grid className={classes.filtersHeader} item xs={12}>
              <div className={classes.listActionsItem}>
                <ParcelListActions
                  ngRedirectToEph={() => ngRedirectToEph(selectedOnPage)}
                  ngRedirectToMowing={() => ngRedirectToMowing(selectedOnPage)}
                  ngRedirectToSowing={() => ngRedirectToSowing(selectedOnPage)}
                  onAssignCenter={onAssignCenter}
                  onAssignZone={onAssignZone}
                  selected={selectedOnPage}
                  ngRedirectToHarvest={() =>
                    ngRedirectToHarvest(selectedOnPage)
                  }
                  ngRedirectToOtherActionsNew={() =>
                    ngRedirectToOtherActionsNew(selectedOnPage)
                  }
                />
              </div>
              <div className={classes.textFilter}>
                <ParcelListTextFilter
                  namespace={namespace}
                  textFilter={textFilter}
                />
              </div>
              <div className={classes.advancedFilter}>
                <ParcelAdvancedFilter
                  advancedFilter={advancedFilter}
                  langId={langId}
                  namespace={namespace}
                />
              </div>
              <div className={classes.seasonChange}>
                <ParcelListSeason />
              </div>
            </Grid>
            <Grid container spacing={1}>
              <Grid item lg={9} md={8} xs={12}>
                <ParcelTable
                  advancedFilter={advancedFilter}
                  farmId={farmId}
                  onAssignCenter={onAssignCenter}
                  shouldReloadData={shouldReloadData}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <Grid item xs={12}>
                  <ParcelZoneStatistics
                    onCreateZone={onCreateZone}
                    onDeleteZone={onDeleteZone}
                    onFilterCropInParcels={onFilterCropInParcels}
                    onFilterZoneInParcels={onFilterZoneInParcels}
                    onUpdateZone={onUpdateZone}
                    shouldReloadData={shouldReloadData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  textFilter: getParcelListTextFilter(state),
  selectedOnPage: getParcelListSelectedOnPage(state),
  error: getParcelsError(state),
  isExporting: getIsExportingData(state),
  advancedFilter: getParcelListAdvancedFilter(state),
  parcels: getParcels()(state) as ParcelsState["api"]["parcelsNew"]["items"],
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      createZoneApi,
      updateZoneApi,
      deleteZoneApi,
      assignZones,
      assignCenter,
      setTableSelected,
      exportParcels,
      setAdvancedFilter,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withWidth()(ParcelList));

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  textFilter: {
    maxWidth: 400,
    flexGrow: 1,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 3,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  listActionsItem: {
    order: 1,
  },
  filtersHeader: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  seasonChange: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
    order: 4,
  },
}));
