import moment from "moment";

import { getShortDateString } from "../../../../shared/misc/timeHelpers";

import {
  ApplicationBuffersType,
  PlantProtectionApplicationFormValues,
  PlantProtectionDetailFormValues,
} from "./PlantProtectionDetail.types";
import {
  ApplicationBufferType,
  PlantProtectionRegistrationApplicationTo,
  PlantProtectionDetailV2To,
  PlantProtectionCreateUpdateV2To,
  PlantProtectionApplicationCreateToV2,
} from "../../../../shared/api/agroevidence/agroevidence.types";

export const initialEmptyValues: Partial<PlantProtectionDetailFormValues> = {
  bioFunction: "",
  notes: "",
  isEco: false,
  name: "",
  isFavorite: false,
  activeSubstances: [],
  registrations: [],
  usableUntil: null,
};

export const mapInitialValues = (
  plantProtection: PlantProtectionDetailV2To,
) => {
  const initialValues: Partial<PlantProtectionDetailFormValues> = {
    bioFunction: plantProtection.bioFunction,
    notes: plantProtection.note,
    isEco: plantProtection.isEco,
    name: plantProtection.name,
    isFavorite: plantProtection.isFavorite,
    activeSubstances: plantProtection.activeSubstances,
    registrations: plantProtection.registrations,
    usableUntil: plantProtection.usableUntil
      ? moment(plantProtection.usableUntil).startOf("day")
      : initialEmptyValues.usableUntil,
  };

  return initialValues;
};

export const mapRequestBodyCreateUpdatePlantProtection = (
  values: PlantProtectionDetailFormValues,
) => {
  const params: PlantProtectionCreateUpdateV2To = {
    name: values.name,
    notes: values.notes,
    bioFunction: values.bioFunction,
    isEco: values.isEco,
    isFavorite: values.isFavorite,
    activeSubstances: values.activeSubstances.map((obj) => ({
      activeSubstanceId: obj.id,
    })),
  };

  if (values.usableUntil !== null) {
    params.usableUntil = getShortDateString(values.usableUntil);
  }

  return params;
};

// ======================================================================================
// ========================= PLANT PROTECTION APPLICATION ================================
// ======================================================================================

export const initialEmptyValuesApplication: Partial<PlantProtectionApplicationFormValues> =
  {
    registrationExternalId: "",
    registrationHolder: "",
    registrationNumber: "",
    maxDose: 0,
    minDose: 0,
    unit: "l/ha",
    maxDoseWater: 0,
    minDoseWater: 0,
    unitWater: "l/ha",
    minBbch: 0,
    maxBbch: 0,
    allowedApplications: 0,
    protectionPeriod: 0,
    crop: undefined,
    organism: undefined,
    minInterval: 0,
    maxInterval: 0,
    notes: "",
    waterBuffers: {
      withoutReduction: 0,
      reduction50: 0,
      reduction75: 0,
      reduction90: 0,
    },
    boundaryBuffers: {
      withoutReduction: 0,
      reduction50: 0,
      reduction75: 0,
      reduction90: 0,
    },
    legalStart: null,
    legalEnd: null,
    marketEnd: null,
    usableUntil: null,
  };

export const mapInitialValuesApplication = (
  item: PlantProtectionRegistrationApplicationTo,
) => {
  const initialValues: Partial<PlantProtectionApplicationFormValues> = {
    registrationExternalId:
      initialEmptyValuesApplication.registrationExternalId,
    registrationHolder: initialEmptyValuesApplication.registrationHolder,
    registrationNumber: initialEmptyValuesApplication.registrationNumber,
    maxDose: item.maxDose || initialEmptyValuesApplication.maxDose,
    minDose: item.minDose || initialEmptyValuesApplication.minDose,
    unit: item.unit || initialEmptyValuesApplication.unit,
    maxDoseWater:
      item.maxDoseWater || initialEmptyValuesApplication.maxDoseWater,
    minDoseWater:
      item.minDoseWater || initialEmptyValuesApplication.minDoseWater,
    unitWater: item.unitWater || initialEmptyValuesApplication.unitWater,
    minBbch: item.minBbch || initialEmptyValuesApplication.minBbch,
    maxBbch: item.maxBbch || initialEmptyValuesApplication.maxBbch,
    allowedApplications:
      item.allowedApplications ||
      initialEmptyValuesApplication.allowedApplications,
    protectionPeriod:
      item.protectionPeriod || initialEmptyValuesApplication.protectionPeriod,
    crop: item.crop || initialEmptyValuesApplication.crop,
    organism: item.organism || initialEmptyValuesApplication.organism,
    minInterval: item.minInterval || initialEmptyValuesApplication.minInterval,
    maxInterval: item.maxInterval || initialEmptyValuesApplication.maxInterval,
    notes: item.notes || initialEmptyValuesApplication.notes,
    waterBuffers: {
      withoutReduction: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Water,
        0,
      ),
      reduction50: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Water,
        50,
      ),
      reduction75: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Water,
        75,
      ),
      reduction90: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Water,
        90,
      ),
    },
    boundaryBuffers: {
      withoutReduction: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Boundary,
        0,
      ),
      reduction50: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Boundary,
        50,
      ),
      reduction75: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Boundary,
        75,
      ),
      reduction90: getBufferByDriftReduction(
        item,
        ApplicationBufferType.Boundary,
        90,
      ),
    },
  };

  return initialValues;
};

const getBufferByDriftReduction = (
  data: PlantProtectionRegistrationApplicationTo,
  type: string,
  driftReduction: number,
) => {
  const buffer = data.buffers.find(
    (buffer) =>
      buffer.type === type && buffer.driftReduction === driftReduction,
  );

  if (buffer && buffer.isAllowed) return buffer.buffer;

  return 0;
};

export const mapRequestBodyCreateUpdateApplication = (
  values: PlantProtectionApplicationFormValues,
) => {
  const applicationProperties: PlantProtectionApplicationCreateToV2 = {
    maxDose: values.maxDose,
    minDose: values.minDose,
    unit: values.unit,
    maxDoseWater: values.maxDoseWater,
    minDoseWater: values.minDoseWater,
    unitWater: "l/ha",
    minBbch: values.minBbch,
    maxBbch: values.maxBbch,
    allowedApplications: values.allowedApplications,
    protectionPeriod: values.protectionPeriod,
    cropId: values.crop?.id,
    organismId: values.organism?.id,
    minInterval: values.minInterval,
    maxInterval: values.maxInterval,
    notes: values.notes,
    buffers: mapBuffers(values.boundaryBuffers, values.waterBuffers),
  };

  return applicationProperties;
};

const mapBuffers = (
  boundaryBuffers: ApplicationBuffersType,
  waterBuffers: ApplicationBuffersType,
) => {
  const formatBufferByType = (
    buffers: ApplicationBuffersType,
    type: ApplicationBufferType,
  ) =>
    [
      {
        type,
        driftReduction: 0,
        isAllowed: true,
        buffer: buffers.withoutReduction,
      },
      {
        type,
        driftReduction: 50,
        isAllowed: true,
        buffer: buffers.reduction50,
      },
      {
        type,
        driftReduction: 75,
        isAllowed: true,
        buffer: buffers.reduction75,
      },
      {
        type,
        driftReduction: 90,
        isAllowed: true,
        buffer: buffers.reduction90,
      },
    ].filter((buffer) => buffer.buffer && buffer.buffer > 0);

  return [
    ...formatBufferByType(boundaryBuffers, ApplicationBufferType.Boundary),
    ...formatBufferByType(waterBuffers, ApplicationBufferType.Water),
  ];
};
