import React, { ReactNode, useEffect, useState } from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getIsExportingDataCropDetails,
  getParcelsCropDetailsError,
} from "../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.selectors";
import { getSeasonsSowingPlan } from "../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";

import {
  exportParcelsCrop,
  fetchSeasonSowingPlan,
} from "./actions/parcelCropList.actions";
import { setTableSelected } from "../../../shared/actions/table.actions";
import { bulkAssignCrop } from "../shared/actions/parcels.actions";

import { NAMESPACE as namespace } from "./reducer/parcelCropDetailsList.reducer";

import { resetSeasonsSowingPlanApi } from "../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfSnackbar from "../../../shared/components/common/CfSnackbar/CfSnackbar";
import { ExportButton } from "../../../shared/components/common/ExportButton/ExportButton";
import PageHeader from "../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";
import withWidth from "../../../shared/hocs/withWidth";
import { Thunk } from "../../../types";
import ParcelListSeason from "../list/components/PacelListSeason/ParcelListSeason";

import ParcelCropsActions from "./ParcelCropsActions/ParcelCropsActions";
import ParcelCropsAdvancedFilter from "./ParcelCropsAdvancedFilter/ParcelCropsAdvancedFilter";
import ParcelCropsTable from "./ParcelCropsTable/ParcelCropsTable";
import ParcelCropsTextFilter from "./ParcelCropsTextFilter/ParcelCropsTextFilter";
import ParcelSeasonCropStatistics from "./ParcelSeasonCropStatistics/ParcelSeasonCropStatistics";
import {
  getParcelCropDetailsListTextFilter,
  getParcelListCropDetailsSelectedOnPage,
} from "./selectors/parcelCropDetailsList.selector";

import { ParcelsState } from "../../../reducers/parcels.reducer.types";
import { CropTo } from "../../../shared/api/agroevidence/agroevidence.types";
import { ParcelCrops } from "../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.types";

type ParcelCropListProps = OwnProps & ReduxProps;

const ParcelCropList = ({
  bulkAssignCrop,
  error,
  exportParcelsCrop,
  farmId,
  fetchSeasonSowingPlan,
  isExporting,
  langId,
  resetSeasonsSowingPlanApi,
  selectedOnPage,
  setTableSelected,
  sowingPlanList,
  textFilter,
}: ParcelCropListProps) => {
  const classes = useStyles();
  const [shouldReloadData, setShouldReloadData] = useState<boolean>(false);
  const [notificationCrop, setNotificationCrop] = useState<ReactNode | null>(
    null,
  );

  useEffect(
    () => () => {
      setTableSelected([], namespace);
    },
    [setTableSelected],
  );

  useEffect(() => {
    resetSeasonsSowingPlanApi();
  }, [resetSeasonsSowingPlanApi]);

  useEffect(() => {
    fetchSeasonSowingPlan();
  }, [fetchSeasonSowingPlan]);

  const onBulkAssignCrop = (
    crop: CropTo,
    seasonId: string,
    parcelIds: string[],
  ) => {
    bulkAssignCrop(
      parcelIds.map((parcelId) => ({
        parcelId,
        cropId: crop.id,
        seasonId,
      })),
    ).then(() => {
      setShouldReloadData((prevState) => !prevState);
      setTableSelected([], namespace);
      setNotificationCrop(
        <FormattedMessage
          id={
            parcelIds.length === 1
              ? "common.cropAssigned"
              : "common.cropAssignedBulk"
          }
          values={{
            crop: crop.name,
          }}
        />,
      );
    });
  };

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <PageHeader
          actionButtons={
            <div className={classes.exportButton}>
              <ExportButton
                aria-label="export"
                isLoading={isExporting}
                onClick={exportParcelsCrop}
              />
            </div>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="parcelDetail-heading"
              value={<FormattedMessage id="common.parcels" />}
            />
          }
        />
        <Grid container spacing={1}>
          <Grid container spacing={0}>
            <Grid className={classes.filtersHeader} item xs={12}>
              <div className={classes.listActionsItem}>
                <ParcelCropsActions
                  onAssignCrop={onBulkAssignCrop}
                  parcelSowingPlan={sowingPlanList}
                  selected={selectedOnPage}
                />
              </div>
              <div className={classes.textFilter}>
                <ParcelCropsTextFilter
                  namespace={namespace}
                  textFilter={textFilter}
                />
              </div>
              <div className={classes.advancedFilter}>
                <ParcelCropsAdvancedFilter
                  langId={langId}
                  namespace={namespace}
                />
              </div>
              <div className={classes.seasonChange}>
                <ParcelListSeason />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={9} md={8} xs={12}>
              <ParcelCropsTable
                bulkAssignCrop={onBulkAssignCrop}
                farmId={farmId}
                namespace={namespace}
                parcelSowingPlan={sowingPlanList}
                shouldReloadData={shouldReloadData}
              />
            </Grid>
            <Grid item lg={3} md={4} xs={12}>
              <Grid item xs={12}>
                <ParcelSeasonCropStatistics
                  shouldReloadData={shouldReloadData}
                  sowingPlanList={sowingPlanList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CfSnackbar
          isSuccess={true}
          message={notificationCrop}
          onClose={() => setNotificationCrop(null)}
          open={Boolean(notificationCrop)}
        />
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  textFilter: getParcelCropDetailsListTextFilter(state),
  selectedOnPage: getParcelListCropDetailsSelectedOnPage(state),
  error: getParcelsCropDetailsError(state),
  isExporting: getIsExportingDataCropDetails(state),
  sowingPlanList: getSeasonsSowingPlan(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      bulkAssignCrop,
      setTableSelected,
      exportParcelsCrop,
      fetchSeasonSowingPlan,
      resetSeasonsSowingPlanApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  bulkAssignCrop: (parcelCrops: ParcelCrops[]) => Promise<void>;
  farmId: string;
  langId: string;
};

export default connector(withWidth()(ParcelCropList));

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  textFilter: {
    maxWidth: 400,
    flexGrow: 1,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 3,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  listActionsItem: {
    order: 1,
  },
  filtersHeader: {
    width: "100%",
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  seasonChange: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
    order: 4,
  },
}));
