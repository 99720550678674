import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import get from "lodash/get";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";

import TableCell from "./TableCell";

const StatsBodyPure = (props) => {
  const {
    classes,
    item,
    onDeleteZone,
    onEditZone,
    onFilterCropInParcels,
    onFilterZoneInParcels,
  } = props;
  return (
    <TableRow
      className={onDeleteZone ? classes.tableRowZone : classes.tableRowCrop}
      key={get(item, "id")}
    >
      <TableCell classes={{ root: classes.firstCell }} key="name">
        {get(item, "name", "-")}
      </TableCell>
      <TableCell classes={{ root: classes.cell }} key="count">
        <CfTableCellNumber>{get(item, "count", "-")}</CfTableCellNumber>
      </TableCell>
      <TableCell classes={{ root: classes.cell }} key="area">
        <CfTableCellNumber>
          <CfFormattedNumber value={get(item, "totalArea", null) || 0} />
        </CfTableCellNumber>
      </TableCell>
      <CfTableRowTools
        key="crop-filter-btn"
        toolsClass={classes.tableRowToolsCrop}
      >
        <>
          {onFilterCropInParcels && (
            <CfTableRowToolButton
              icon={<FilterListIcon />}
              iconProps={classes.actionBtn}
              onClick={() => onFilterCropInParcels([item])}
              tooltipMessage={
                <FormattedMessage id="ParcelZoneStatistics.list.filter-parcels" />
              }
            />
          )}
        </>
      </CfTableRowTools>
      <CfTableRowTools
        key="area-delete-btn"
        toolsClass={classes.tableRowToolsZone}
      >
        {onFilterZoneInParcels && (
          <CfTableRowToolButton
            icon={<FilterListIcon />}
            iconProps={classes.actionBtn}
            onClick={() => onFilterZoneInParcels([item])}
            tooltipMessage={
              <FormattedMessage id="ParcelZoneStatistics.list.filter-parcels" />
            }
          />
        )}
        <CfTableRowToolButton
          icon={<EditIcon />}
          iconProps={classes.actionBtn}
          onClick={() => onEditZone(item)}
          tooltipMessage={<FormattedMessage id="common.edit" />}
        />
        <CfTableRowToolButton
          icon={<DeleteIcon />}
          iconProps={classes.actionBtn}
          onClick={() => onDeleteZone(item)}
          tooltipMessage={<FormattedMessage id="common.delete" />}
        />
      </CfTableRowTools>
    </TableRow>
  );
};

StatsBodyPure.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onEditZone: PropTypes.func,
  onDeleteZone: PropTypes.func,
  onFilterZoneInParcels: PropTypes.func,
  onFilterCropInParcels: PropTypes.func,
};

StatsBodyPure.defaultProps = {
  onEditZone: null,
  onDeleteZone: null,
  onFilterZoneInParcels: null,
  onFilterCropInParcels: null,
};

const styles = (theme) => ({
  statCellBorderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  statCellBorderBottom: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    color: theme.palette.grey[500],
    backgroundColor: "white",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
  actionBtn: {
    width: 32,
    height: 32,
    padding: 0,
  },
  root: {
    borderBottom: 0,
  },
  tableRowZone: {
    transform: "scale(1)",
    "&:hover $tableRowToolsZone": {
      display: "inline-block",
      padding: "5px 10px 0px 0px",
    },
  },
  tableRowCrop: {
    transform: "scale(1)",
    "&:hover $tableRowToolsCrop": {
      display: "inline-block",
      padding: "5px 10px 0px 0px",
    },
  },
  tableRowToolsCrop: {
    display: "none",
  },
  tableRowToolsZone: {
    display: "none",
  },
});

export const StatsBody = withStyles(styles)(StatsBodyPure);
