import React, { FC, ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getBioFunctions,
  getIsFetchingBioFunctions,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchBioFunctions } from "../../../../actions/catalogues.actions";

import { resetBioFunctionApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues?: string;
  disabled?: boolean;
  error?: boolean;
  label: ReactNode;
  onChange: (items: string) => void;
};
type Props = ReduxProps & OwnProps;

const BioFunctionSelector: FC<Props> = ({
  bioFunctions,
  defaultValues,
  disabled = false,
  error = false,
  fetchBioFunctions,
  isBioFunctionsFetching,
  label,
  onChange,
  resetBioFunctionApi,
}) => {
  useEffect(() => {
    fetchBioFunctions();

    return () => {
      resetBioFunctionApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRenderOption = (option: string) => option;

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="bio-functions-selector"
      isFetching={isBioFunctionsFetching}
      isMultiple={false}
      label={label}
      loadOptions={fetchBioFunctions}
      onChange={onChange}
      renderOption={handleRenderOption}
      suggestions={bioFunctions}
      testId="bio-functions-selector"
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  bioFunctions: getBioFunctions(state),
  isBioFunctionsFetching: getIsFetchingBioFunctions(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchBioFunctions,
      resetBioFunctionApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(BioFunctionSelector);
