import React, { Component, Fragment } from "react";

import debounce from "lodash/debounce";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getGeometry } from "../../../../shared/api/core/geometry/geometry.selectors";
import {
  getParcelsSuggestions,
  getSelectedParcels,
  getBufferSize,
  getBufferType,
  getLastCurrentSeedDate,
  getValidFrom,
  getValidFromError,
} from "../../selectors/editor.selectors";

import {
  onMouseClick,
  setBufferSize,
  updateBuffer,
  setBufferType,
  startPartialBuffer,
  endPartialBuffer,
  invertPartialBuffer,
} from "../../actions/buffer/buffer.actions";
import {
  editorToolEnd,
  editorToolStart,
  setValidFrom,
  validateValidFrom,
} from "../../actions/editor/editor.actions";
import { refreshSplitStyles } from "../../actions/style/style.actions";

import { NO_CROP_LEGISLATIVE_ID } from "../../constants/crops.constants";
import * as stages from "../../constants/stages.constants";
import * as tools from "../../constants/tools.constants";

import {
  getParcelsMapActionable,
  resetParcelsMapActionable,
} from "../../../../shared/api/core/parcels/parcels.api";
import { BUFFER_TYPES } from "../../actions/buffer/bufferTypes";
import BufferInvertButton from "../../components/BufferInvertButton/BufferInvertButton";
import BufferSize from "../../components/BufferSize/BufferSize";
import BufferType from "../../components/BufferType/BufferType";
import MapDatePicker from "../../components/MapDatePicker/MapDatePicker";
import SelectParcelForm from "../../components/SelectParcelForm/SelectParcelForm";
import ToolbarBtnCancel from "../../components/ToolbarBtnCancel/ToolbarBtnCancel";
import ToolbarBtnSubmit from "../../components/ToolbarBtnSubmit/ToolbarBtnSubmit";

export class BufferToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      bufferSize,
      bufferType,
      lastCurrentSeedDate,
      parcelGeometry,
      validFrom,
    } = this.props;

    if (
      bufferSize !== prevProps.bufferSize ||
      bufferType !== prevProps.bufferType
    ) {
      this.debouncedUpdateBuffer(parcelGeometry, bufferType, bufferSize);
    }

    if (bufferType !== prevProps.bufferType) {
      if (bufferType === BUFFER_TYPES.PARTIAL) {
        this.props.startPartialBuffer(parcelGeometry);
      } else if (bufferType === BUFFER_TYPES.OVERALL) {
        this.props.endPartialBuffer();
      }
    }

    if (
      validFrom !== prevProps.validFrom ||
      (moment.isMoment(lastCurrentSeedDate) &&
        !lastCurrentSeedDate.isSame(prevProps.lastCurrentSeedDate))
    ) {
      this.props.validateValidFrom();
    }
  }

  componentWillUnmount() {
    this.props.setBufferSize(null);
    this.props.setBufferType(BUFFER_TYPES.PARTIAL);
  }

  onSuggestionFetch = (textFilter) => {
    this.props.getParcelsMapActionable({
      ...(textFilter && textFilter.length
        ? { localNameBlockNr: textFilter }
        : {}),
    });
  };

  onSuggestionClear = () => {
    this.props.resetParcelsMapActionable();
  };

  onSuggestionsReset = () => {
    this.props.resetParcelsMapActionable();
  };

  onParcelSelect = (suggestion) => {
    const feature = {
      get(prop) {
        return this[prop];
      },
      id: suggestion.id,
      local_name: suggestion.localName,
      crop_legislative_code: NO_CROP_LEGISLATIVE_ID,
      seed_date_start: suggestion.currentSeedDate,
    };
    this.props.onMouseClick(feature).then(() => {
      this.props.refreshSplitStyles();
    });
  };

  onParcelRemove = () => {
    this.props.editorToolEnd(tools.BUFFER);
    this.props.editorToolStart(tools.BUFFER);
  };

  onBufferInvert = () => {
    const { bufferSize, parcelGeometry } = this.props;
    this.props.invertPartialBuffer(parcelGeometry, bufferSize);
  };

  debouncedUpdateBuffer = debounce(this.props.updateBuffer, 500);

  render() {
    const {
      bufferType,
      error,
      langId,
      lastCurrentSeedDate,
      onCancel,
      onSubmit,
      parcels,
      stage,
      suggestions,
      validFrom,
      validFromError,
    } = this.props;

    return (
      <Fragment>
        <SelectParcelForm
          disabled={stage !== stages.STAGE_1}
          langId={langId}
          onParcelRemove={this.onParcelRemove}
          onParcelSelect={this.onParcelSelect}
          onSuggestionClear={this.onSuggestionClear}
          onSuggestionFetch={this.onSuggestionFetch}
          onSuggestionsReset={this.onSuggestionsReset}
          parcels={parcels}
          suggestions={suggestions}
        />
        {stage !== stages.STAGE_1 && (
          <BufferType
            bufferType={bufferType}
            setBufferType={this.props.setBufferType}
          />
        )}
        <span>
          {stage !== stages.STAGE_1 && (
            <BufferSize setBufferSize={this.props.setBufferSize} />
          )}
          {stage !== stages.STAGE_1 && bufferType === BUFFER_TYPES.PARTIAL && (
            <BufferInvertButton
              callback={this.onBufferInvert}
              isDisabled={stage === stages.STAGE_2}
            />
          )}
        </span>
        {stage !== stages.STAGE_1 && (
          <MapDatePicker
            error={!!validFromError}
            minDate={lastCurrentSeedDate}
            setDate={this.props.setValidFrom}
            validFrom={validFrom}
          />
        )}
        <span>
          <ToolbarBtnCancel onCancel={onCancel} />
          <ToolbarBtnSubmit
            disabled={stage !== stages.STAGE_4 || !!error || !!validFromError}
            onSubmit={onSubmit}
            translationId="tools.SPLIT"
          />
        </span>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  parcels: getSelectedParcels(state),
  suggestions: getParcelsSuggestions(state),
  bufferSize: getBufferSize(state),
  bufferType: getBufferType(state),
  parcelGeometry: getGeometry(state),
  lastCurrentSeedDate: getLastCurrentSeedDate(state),
  validFrom: getValidFrom(state),
  validFromError: getValidFromError(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParcelsMapActionable,
      resetParcelsMapActionable,
      onMouseClick,
      refreshSplitStyles,
      editorToolEnd,
      editorToolStart,
      setBufferSize,
      updateBuffer,
      setBufferType,
      startPartialBuffer,
      endPartialBuffer,
      invertPartialBuffer,
      setValidFrom,
      validateValidFrom,
    },
    dispatch,
  );

BufferToolbar.propTypes = {
  langId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getParcelsMapActionable: PropTypes.func.isRequired,
  onMouseClick: PropTypes.func.isRequired,
  refreshSplitStyles: PropTypes.func.isRequired,
  editorToolEnd: PropTypes.func.isRequired,
  resetParcelsMapActionable: PropTypes.func.isRequired,
  editorToolStart: PropTypes.func.isRequired,
  setBufferSize: PropTypes.func.isRequired,
  setBufferType: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  updateBuffer: PropTypes.func.isRequired,
  startPartialBuffer: PropTypes.func.isRequired,
  endPartialBuffer: PropTypes.func.isRequired,
  invertPartialBuffer: PropTypes.func.isRequired,
  stage: PropTypes.string,
  error: PropTypes.string,
  parcels: PropTypes.array,
  bufferSize: PropTypes.number,
  bufferType: PropTypes.string.isRequired,
  parcelGeometry: PropTypes.object,
  lastCurrentSeedDate: PropTypes.object,
  setValidFrom: PropTypes.func.isRequired,
  validFrom: PropTypes.string,
  validateValidFrom: PropTypes.func.isRequired,
  validFromError: PropTypes.string,
};

BufferToolbar.defaultProps = {
  stage: null,
  error: null,
  parcels: [],
  bufferSize: null,
  parcelGeometry: {},
  lastCurrentSeedDate: null,
  validFrom: null,
  validFromError: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(BufferToolbar);
