import React, { FC, useState, useEffect } from "react";

import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getParcelsStatistics,
  getIsFetchingParcelsStatistics,
} from "../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getIsZonesFetching,
  getZonesStatistics,
} from "../../../../../shared/api/agroevidence/zones/zones.selectors";

import {
  getParcelsStatisticsApi,
  resetParcelsStatisticsApi,
} from "../../../../../shared/api/agroevidence/parcels/parcels.api";
import {
  getZonesApi,
  resetZonesApi,
} from "../../../../../shared/api/agroevidence/zones/zones.api";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import CfPrimaryTab from "../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab";
import CfPrimaryTabs from "../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs";
import NameEditDialog from "../../../../../shared/components/common/NameEditDialog/NameEditDialog";
import { Thunk } from "../../../../../types";

import { CropParcelStats } from "./CropParcelStats";
import { useParcelZoneStatisticsStyles } from "./style";
import { ZoneStats } from "./ZoneStats";

import { ParcelsState } from "../../../../../reducers/parcels.reducer.types";
import {
  CropTo,
  FarmStatisticsTo,
  ZoneDetailTo,
  ZoneTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ZoneStatistics } from "../../../../../shared/api/agroevidence/zones/zones.types";

const PARCEL_STATS_TAB = 0;
const ZONE_STATS_TAB = 1;

export interface ParcelZoneStatisticsProps {
  getParcelsStatisticsApi: () => void;
  getZonesApi: (search?: string) => void;
  isFetchingParcels: boolean;
  isFetchingZones: boolean;
  onCreateZone: (zone: ZoneTo) => void;
  onDeleteZone: (zone: ZoneTo) => void;
  onFilterCropInParcels: (zones: CropTo[]) => void;
  onFilterZoneInParcels: (zones: ZoneDetailTo[]) => void;
  onUpdateZone: (zone: ZoneTo) => void;
  parcelsStats?: FarmStatisticsTo;
  resetParcelsStatisticsApi: () => void;
  resetZonesApi: () => void;
  shouldReloadData: boolean;
  zonesStats?: ZoneStatistics;
}

export const ParcelZoneStatistics: FC<ParcelZoneStatisticsProps> = ({
  getParcelsStatisticsApi,
  getZonesApi,
  isFetchingParcels,
  isFetchingZones,
  onCreateZone,
  onDeleteZone,
  onFilterCropInParcels,
  onFilterZoneInParcels,
  onUpdateZone,
  parcelsStats,
  resetParcelsStatisticsApi,
  resetZonesApi,
  shouldReloadData,
  zonesStats,
}) => {
  const classes = useParcelZoneStatisticsStyles();

  const [tabIndex, setTabIndex] = useState<number>(PARCEL_STATS_TAB);
  const [editZone, setEditZone] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState<ZoneTo>();
  const [createNewZone, setCreateNewZone] = useState(false);

  useEffect(
    () => () => {
      resetParcelsStatisticsApi();
      resetZonesApi();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (tabIndex === PARCEL_STATS_TAB) {
      getParcelsStatisticsApi();
    } else if (tabIndex === ZONE_STATS_TAB) {
      getZonesApi();
    }
  }, [getParcelsStatisticsApi, getZonesApi, shouldReloadData, tabIndex]);

  const onCreateZoneConfirm = (zone: ZoneTo) => {
    onCreateZone(zone);
    setCreateNewZone(false);
  };

  const onEditZone = (zone: ZoneTo) => {
    setEditZone(true);
    setZoneToEdit(zone);
  };

  const onEditZoneClose = () => {
    setEditZone(false);
    setZoneToEdit(undefined);
  };

  const onEditZoneAccept = (zone: ZoneTo) => {
    onUpdateZone(zone);
    onEditZoneClose();
  };

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
  ) => {
    setTabIndex(value);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <CfPrimaryTabs
              centered
              onChange={handleChange}
              tabValue={tabIndex}
              variant="fullWidth"
            >
              <CfPrimaryTab
                data-test="parcel-list-crops-tab"
                label={<FormattedMessage id="common.crops" />}
              />
              <CfPrimaryTab
                data-test="parcel-list-zones-tab"
                label={<FormattedMessage id="common.zones" />}
              />
            </CfPrimaryTabs>
            {isFetchingParcels || isFetchingZones ? (
              <CfLoader classes={{ wrapper: classes.wrapper }} />
            ) : (
              <>
                {tabIndex === PARCEL_STATS_TAB && parcelsStats?.crops && (
                  <CropParcelStats
                    onFilterCropInParcels={onFilterCropInParcels}
                    parcelsStats={parcelsStats}
                  />
                )}
                {tabIndex === ZONE_STATS_TAB && (
                  <ZoneStats
                    createNewZone={createNewZone}
                    onCreateZoneConfirm={onCreateZoneConfirm}
                    onDeleteZone={onDeleteZone}
                    onEditZone={onEditZone}
                    onFilterZoneInParcels={onFilterZoneInParcels}
                    setCreateNewZone={setCreateNewZone}
                    zonesStats={zonesStats}
                  />
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {editZone && (
        <NameEditDialog
          item={zoneToEdit}
          onAccept={onEditZoneAccept}
          onClose={onEditZoneClose}
          opened={editZone}
          title={<FormattedMessage id="ParcelZone.edit" />}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  parcelsStats: getParcelsStatistics(state),
  isFetchingParcels: getIsFetchingParcelsStatistics(state),
  zonesStats: getZonesStatistics(state),
  isFetchingZones: getIsZonesFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getParcelsStatisticsApi,
      getZonesApi,
      resetParcelsStatisticsApi,
      resetZonesApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParcelZoneStatistics);
