import React, { useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getIsFetching,
  getPlantProtectionDetail,
} from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import {
  createPlantProtection,
  updatePlantProtection,
  fetchPlantProtection,
  resetPlantProtection,
  patchPlantProtection,
} from "../../../actions/catalogues.actions";

import { CATALOGUES_URLS } from "../../../catalogues.constants";

import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import useWidth from "../../../../shared/hooks/useWidth";
import {
  AsyncFn,
  CfFormikErrors,
  CfFormikProps,
  Thunk,
} from "../../../../types";
import { CataloguesContext } from "../../../containers/CataloguesWrapper/CataloguesWrapper";
import Buttons from "../../shared/Buttons";
import CheckboxField from "../../shared/CheckboxField";
import FormikTextField from "../../shared/FormikTextField";

import ActiveSubstancesControl from "./ActiveSubstancesControl/ActiveSubstancesControl";
import ApplicationsTable from "./Applications/ApplicationsTable";
import BioFunctionSelector from "./components/BioFunctionSelector";
import PlantProtectionDetailHeader from "./components/PlantProtectionDetailHeader";
import {
  initialEmptyValues,
  mapInitialValues,
  mapRequestBodyCreateUpdatePlantProtection,
} from "./PlantProtectionDetail.services";
import { usePlantProtectionDetailStyles } from "./PlantProtectionDetail.styles";
import { PlantProtectionDetailValidity } from "./PlantProtectionDetailValidity";

import { PlantProtectionDetailFormValues } from "./PlantProtectionDetail.types";
import { CataloguesState } from "../../../../reducers/catalogues.reducer.types";
import {
  PlantProtectionCreateUpdateTo,
  PlantProtectionDetailTo,
  PlantProtectionPatchTo,
} from "../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  isNew: boolean;
};
type Props = ReduxProps & OwnProps;

const PlantProtectionDetail = ({
  createPlantProtection,
  fetchPlantProtection,
  isFetching,
  isNew,
  patchPlantProtection,
  plantProtection,
  resetPlantProtection,
  updatePlantProtection,
}: Props) => {
  const classes = usePlantProtectionDetailStyles();
  const history = useHistory();
  const width = useWidth();
  const { farmId } = useContext(CataloguesContext);
  const { plantProtectionId } = useParams<{ plantProtectionId: string }>();
  const showSnackbar = useContext(SnackbarContext);

  const [isEditing, setIsEditing] = useState(isNew);

  useEffect(() => {
    resetPlantProtection();
    if (!isNew) {
      fetchPlantProtection(plantProtectionId);
    }
  }, [plantProtectionId, fetchPlantProtection, resetPlantProtection, isNew]);

  const initialValues = useMemo(() => {
    if (isNew || !plantProtection) return initialEmptyValues;

    return mapInitialValues(plantProtection);
  }, [plantProtection, isNew]);

  const handleFavoriteClick = () => {
    (
      patchPlantProtection as unknown as AsyncFn<string, PlantProtectionPatchTo>
    )(plantProtectionId, { isFavorite: !plantProtection?.isFavorite }).then(
      () => fetchPlantProtection(plantProtectionId),
    );
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleResetForm = () => {
    setIsEditing(false);
  };

  const handleSubmit = (values: PlantProtectionDetailFormValues) => {
    const data = mapRequestBodyCreateUpdatePlantProtection(values);
    if (isNew) {
      (
        createPlantProtection as unknown as AsyncFn<PlantProtectionCreateUpdateTo>
      )(data).then((res: RSAAResultAction<PlantProtectionDetailTo>) => {
        if (!res.error && values.isFavorite) {
          const newPlantProtectionId = res.payload.id;
          patchPlantProtection(newPlantProtectionId as string, {
            isFavorite: true,
          });
        }
        handleResponse(res, true);
      });
    } else {
      (
        updatePlantProtection as unknown as AsyncFn<
          string,
          PlantProtectionCreateUpdateTo
        >
      )(plantProtectionId, data).then(
        (res: RSAAResultAction<PlantProtectionDetailTo>) => handleResponse(res),
      );
      setIsEditing(false);
    }
  };

  const handleResponse = (
    res: RSAAResultAction<PlantProtectionDetailTo>,
    newAction = false,
  ) => {
    if (!res.error) {
      if (newAction) handleGoBack();
      return;
    }
    showSnackbar({
      message: (
        <FormattedMessage
          id={`Catalogues.plantProtection.${
            newAction ? "createError" : "updateError"
          }`}
        />
      ),
      isError: true,
    });
  };

  const handleGoBack = () => {
    history.push(`/farm/${farmId}/${CATALOGUES_URLS.plantProtectionReact}`);
  };

  const validate = (values: PlantProtectionDetailFormValues) => {
    const errors: CfFormikErrors<PlantProtectionDetailFormValues> = {};
    if (!values.name) {
      errors.name = "validation.required";
    }

    if (!values.bioFunction) {
      errors.bioFunction = "validation.required";
    }

    if (!values.usableUntil) {
      errors.usableUntil = <FormattedMessage id="validation.required" />;
    }

    return errors;
  };

  const isMobile = width === "xs" || width === "sm";
  const canUpdate = isNew || plantProtection?.catalogue?.canUpdate;

  if (!plantProtection && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Formik<Partial<PlantProtectionDetailFormValues>>
      enableReinitialize
      initialValues={initialValues}
      onReset={handleResetForm}
      onSubmit={handleSubmit}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({
        errors,
        setFieldValue,
        values,
      }: CfFormikProps<PlantProtectionDetailFormValues>) => {
        const handleChangeName = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue("name", event.target.value);
        };

        const handleChangeFavoriteOnCreate = () => {
          setFieldValue("isFavorite", !values.isFavorite);
        };

        const handleChangeCheckbox = (
          event: React.ChangeEvent<HTMLInputElement>,
          name: string,
        ) => {
          setFieldValue(name, event.target.checked);
        };

        const handleChangeBioFunction = (bioFunction: string) => {
          setFieldValue("bioFunction", bioFunction);
        };

        const renderSectionHeading = (messageId: string) => (
          <Grid data-test="sectionHeading" item xs={12}>
            <Typography className={classes.sectionHeading} variant="h5">
              <FormattedMessage id={messageId} />
            </Typography>
          </Grid>
        );

        return (
          <Form>
            <div className={classes.body}>
              <Grid className={classes.headWrapper} container>
                <PlantProtectionDetailHeader
                  backButtonLabel="Catalogues.plantProtection.detail.backToPlantProtection"
                  canUpdate={canUpdate}
                  defaultIsFavorite={values.isFavorite}
                  defaultName={values.name}
                  eagriRegistrationId={plantProtection?.externalRegistrationId}
                  error={!!errors.name}
                  handleGoBack={handleGoBack}
                  handleStartEdit={handleStartEdit}
                  isEditing={isEditing}
                  itemNamePlaceholder="Catalogues.plantProtection.detail.name"
                  onChange={handleChangeName}
                  plantProtectionId={plantProtectionId}
                  handleFavoriteClick={
                    isNew ? handleChangeFavoriteOnCreate : handleFavoriteClick
                  }
                />
              </Grid>
              <Grid className={classes.wrapper} container spacing={2}>
                <Grid
                  container
                  data-test="ppp-source"
                  item
                  justifyContent="flex-end"
                  style={{ paddingTop: 8 }}
                  xs={12}
                >
                  <FormattedMessage
                    id={`Catalogues.detail.shared.source.${
                      canUpdate ? "custom" : "eAgri"
                    }`}
                    values={{
                      br: <br />,
                      b: (chunks: string) => <b>{chunks}</b>,
                    }}
                  />
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  {renderSectionHeading(
                    "Catalogues.plantProtection.detail.generalSection",
                  )}
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      direction={isMobile ? "column-reverse" : "row"}
                      item
                      justifyContent="space-between"
                    >
                      <Grid
                        container
                        direction={isMobile ? "column" : "row"}
                        item
                        xs={8}
                      >
                        <Grid className={classes.fieldFormik} item>
                          <FormikTextField
                            fullWidth={true}
                            isEditing={isEditing}
                            isValidation={true}
                            label="Catalogues.table.plantProtection.column.authorizationHolder"
                            name="authorizationHolder"
                          />
                        </Grid>
                        <Grid className={classes.bioFunctionSelector} item>
                          <BioFunctionSelector
                            defaultValues={values.bioFunction}
                            disabled={!isEditing}
                            error={!!errors.bioFunction}
                            onChange={handleChangeBioFunction}
                            label={
                              <FormattedMessage id="Catalogues.plantProtection.detail.bioFunction" />
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        alignItems="flex-start"
                        container
                        item
                        justifyContent={isMobile ? "flex-start" : "flex-end"}
                        xs={4}
                      >
                        <CheckboxField
                          defaultValues={values.isEco}
                          isEditing={isEditing}
                          label="Catalogues.plantProtection.detail.isEco"
                          name="isEco"
                          onChange={handleChangeCheckbox}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction={isMobile ? "column" : "row"}
                      item
                    >
                      <Grid className={classes.fieldFormik} item>
                        <FormikTextField
                          fullWidth={true}
                          isEditing={isEditing}
                          isValidation={true}
                          label="Catalogues.table.plantProtection.column.registrationNumber"
                          name="registrationNumber"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <FormikTextField
                          fullWidth={true}
                          isEditing={isEditing}
                          label="common.note"
                          name="notes"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item lg={3} md={9} xs={12}>
                  {renderSectionHeading("common.validity")}
                  <PlantProtectionDetailValidity isEditing={isEditing} />
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  {renderSectionHeading(
                    "Catalogues.plantProtection.detail.activeSubstancesSection",
                  )}
                  <Paper className={classes.paper}>
                    <FieldArray
                      name="activeSubstances"
                      render={(arrayHelpers) => (
                        <ActiveSubstancesControl
                          arrayHelpers={arrayHelpers}
                          isEditing={isEditing}
                          name={arrayHelpers.name}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
                {isEditing && <Buttons isNew={isNew} />}
                {!isNew && (
                  <Grid item xs={12}>
                    {renderSectionHeading(
                      "Catalogues.plantProtection.detail.applicationsSection",
                    )}
                    <ApplicationsTable
                      allowEditing={canUpdate}
                      isEditing={isEditing}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  plantProtection: getPlantProtectionDetail(state),
  isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchPlantProtection,
      resetPlantProtection,
      createPlantProtection,
      updatePlantProtection,
      patchPlantProtection,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PlantProtectionDetail);
