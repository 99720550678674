import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";

import editorUI from "../core/map/reducer/editor.reducer";
import layersUI from "../core/map/reducer/layersUI.reducer";
import mapUI from "../core/map/reducer/map.reducer";
import managementUI from "../core/precision/reducer/management.reducer";
import monitoringUI from "../core/precision/reducer/monitoring.reducer";
import precisionAsAppliedUI from "../core/precision/reducer/precisionAsApplied.reducer";
import precisionAsAppliedDetailReducer from "../core/precision/reducer/precisionAsAppliedDetail.reducer";
import precisionParcelListUI from "../core/precision/reducer/precisionParcelList.reducer";
import parcelsApiNew from "../shared/api/agroevidence/parcels/parcels.reducer";
import dataApi from "../shared/api/api.reducer";
import geometryApi from "../shared/api/core/geometry/geometry.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import layersApi from "../shared/api/other/layers/layers.reducer";
import managementZonesApi from "../shared/api/sentinel/managementZones/managementZones.reducer";
import monitoringApi from "../shared/api/sentinel/monitoring/monitoring.reducer";
import precisionParcelsApi from "../shared/api/sentinel/precision/precision.reducer";
import precisionAsAppliedListApi from "../shared/api/sentinel/precision/precisionAsApplied.reducer";
import satelliteProductsApi from "../shared/api/sentinel/satelliteProducts/satelliteProducts.reducer";

import {
  TaskDataListTo,
  TaskDataReportTo,
  TaskDataTaskTo,
  TaskDataTimelineTo,
} from "../shared/api/satellite/satellite.types";
import { SoilSamplesData } from "../shared/api/sentinel/soilSamples/soilSamples.types";

const reducer = (history: History) =>
  combineReducers({
    ui: combineReducers({
      precisionParcelsList: precisionParcelListUI(),
      precisionAsApplied: precisionAsAppliedUI(),
      precisionAsAppliedDetail: precisionAsAppliedDetailReducer(),
      monitoring: monitoringUI(),
      management: managementUI(),
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
      cache: () => ["precisionParcelsList"],
    }),
    api: combineReducers({
      asApplied: combineReducers({
        detail: combineReducers({
          content: dataApi<TaskDataTaskTo>(
            "PRECISION",
            "GET_PRECISION_TASK_DATA",
          ),
          report: dataApi<TaskDataReportTo>(
            "PRECISION",
            "GET_PRECISION_TASK_DATA_REPORT",
          ),
          map: dataApi<TaskDataTimelineTo[]>(
            "PRECISION",
            "GET_PRECISION_TASK_TIMELINE_DATA",
          ),
        }),
        list: dataApi<TaskDataListTo[]>(
          "PRECISION",
          "GET_PRECISION_TASK_LIST_DATA",
          {
            onSuccess: (state, action) => ({
              ...state,
              count: Number(action.meta.headers.get("X-Total-Count")),
            }),
          },
        ),
      }),
      precisionParcels: precisionParcelsApi(),
      precisionAsApplied: precisionAsAppliedListApi(),
      parcelsNew: parcelsApiNew(),
      monitoring: monitoringApi(),
      management: managementZonesApi(),
      soilSamples: dataApi<SoilSamplesData>("PRECISION", "GET_SOIL_SAMPLES"),
      soilSamplesYears: dataApi<number[]>(
        "PRECISION",
        "GET_SOIL_SAMPLES_YEARS",
      ),
      satelliteProducts: satelliteProductsApi(),
      geometry: geometryApi(),
      layers: layersApi(),
    }),
    router: connectRouter(history),
    auth: authReducer,
  });

export default reducer;
