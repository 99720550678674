import React, { useEffect, useContext, useState } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import { FormControlLabel, Switch, TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getPlantProtectionCount,
  getIsFetching,
  getPlantProtectionV2,
} from "../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";
import {
  getSectionListAdvancedFilter,
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../selectors/sectionList.selectors";

import {
  fetchPlantProtectionsV2,
  resetPlantProtections,
} from "../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../reducers/namespace.reducer";

import { patchPlantProtectionApiV2 } from "../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfTableBodyEmpty from "../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableWrapper from "../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../shared/containers/CfTextFilter/CfTextFilter";
import { getColDesc } from "../../../shared/misc/helper";
import { getShortDateString } from "../../../shared/misc/timeHelpers";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";

import { COLUMN_NAMES } from "./plantProtection.columns";
import PlantProtectionAdvancedFilter from "./PlantProtectionAdvancedFilter/PlantProtectionAdvancedFilter";
import { PlantProtectionListRowV2 } from "./PlantProtectionListRowV2";

import { CATALOGUES_NAMESPACES } from "../../../reducers/catalogues.reducer.types";

export const PlantProtectionListV2 = () => {
  const { langId } = useContext(CataloguesContext);
  const classes = useStyles();

  const dispatch = useDispatch();
  const plantProtection = useSelector(getPlantProtectionV2);
  const isFetching = useSelector(getIsFetching);
  const count = useSelector(getPlantProtectionCount);
  const namespace = useSelector(getSelectedNamespace);
  const search = useSelector(getSectionListSearch);
  const advancedFilter = useSelector(getSectionListAdvancedFilter);
  const order = useSelector(getSectionListOrder);
  const orderBy = useSelector(getSectionListOrderBy);
  const page = useSelector(getSectionListPage);
  const rowsPerPage = useSelector(getSectionListRowsPerPage);

  const [showHistorical, setShowHistorical] = useState(false);
  const [shouldReloadData, setShouldReloadData] = useState(false);

  useEffect(() => {
    dispatch(resetPlantProtections());
    dispatch(setSelectedNamespace(CATALOGUES_NAMESPACES.PLANT_PROTECTION));
  }, [dispatch]);

  useEffect(() => {
    const usableUntil = !showHistorical ? getShortDateString() : undefined;
    dispatch(fetchPlantProtectionsV2(usableUntil));
  }, [
    page,
    order,
    orderBy,
    rowsPerPage,
    search,
    advancedFilter,
    langId,
    showHistorical,
    shouldReloadData,
    dispatch,
  ]);

  if (namespace !== CATALOGUES_NAMESPACES.PLANT_PROTECTION) return null;

  const onUpdateIsFavorite = (
    plantProtectionId: string,
    isFavorite: boolean,
  ) => {
    (
      dispatch(
        patchPlantProtectionApiV2(plantProtectionId, {
          isFavorite: !isFavorite,
        }),
      ) as unknown as Promise<unknown>
    ).then(() => setShouldReloadData((prevState) => !prevState));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="plantProtection-list-text-filter"
              namespace={namespace}
              translId="Catalogues.table.plantProtection.search"
            />
          </div>
          <div className={classes.advancedFilter}>
            <PlantProtectionAdvancedFilter
              langId={langId}
              namespace={namespace}
            />
          </div>
        </div>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          className={classes.switch}
          label={<FormattedMessage id="Catalogues.table.shared.showHistory" />}
          control={
            <Switch
              checked={showHistorical}
              color="primary"
              onChange={() => setShowHistorical((prevState) => !prevState)}
            />
          }
        />
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />
        {isFetching && <CfTableBodyLoader columns={columns} />}

        {plantProtection.length && !isFetching ? (
          <TableBody>
            {plantProtection.map((item) => (
              <PlantProtectionListRowV2
                data={item}
                key={item.id}
                onUpdateIsFavorite={onUpdateIsFavorite}
              />
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </CfTableWrapper>
    </div>
  );
};

export const columns = {
  [COLUMN_NAMES.IS_FAVORITE]: getColDesc(true, <StarBorderRoundedIcon />, {
    paddingLeft: 16,
    width: 30,
  }),
  [COLUMN_NAMES.NAME]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.plantProtection.column.name" />,
  ),
  [COLUMN_NAMES.SOURCE]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.shared.column.source" />,
  ),
  [COLUMN_NAMES.VALID_TO]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.table.plantProtection.column.validTo" />,
  ),
  [COLUMN_NAMES.ACTIVE_SUBSTANCES]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.plantProtection.activeSubstances" />,
  ),
  [COLUMN_NAMES.CROP]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.plantProtection.column.crop" />,
    { maxWidth: 100 },
  ),
  [COLUMN_NAMES.ORGANISMUS]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.table.plantProtection.column.organismus" />,
  ),
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  advancedFilter: {
    marginLeft: 8,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  switch: {
    marginLeft: "auto",
  },
  switchLabel: {
    fontSize: 14,
  },
}));
