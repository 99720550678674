import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import cropsUI from "../core/map/reducer/crops.reducer";
import editorUI from "../core/map/reducer/editor.reducer";
import layersUI from "../core/map/reducer/layersUI.reducer";
import mainMapTelematicsUI from "../core/map/reducer/mainMapTelematics.reducer";
import mapUI from "../core/map/reducer/map.reducer";
import sowingPlanUI from "../core/map/reducer/sowingPlan.reducer";
import weatherUI from "../core/map/reducer/weather.reducer";
import agroevidenceActionsApi from "../shared/api/agroevidence/actions/actions.reducer";
import cropsApi from "../shared/api/agroevidence/catalogues/crops/crops.reducer";
import farmsApi from "../shared/api/agroevidence/farms/farms.reducer";
// TODO po dokonceni prepisu na AGROEVIDENCE prejmenovat parcelsApiNew -> parcelsApi
import parcelsApiNew from "../shared/api/agroevidence/parcels/parcels.reducer";
import sowingPlanApi from "../shared/api/agroevidence/sowingPlan/sowingPlan.reducer";
import geometryApi from "../shared/api/core/geometry/geometry.reducer";
import landuseApi from "../shared/api/core/landuse/landuse.reducer";
// TODO po dokonceni prepisu na AGROEVIDENCE odstranit parcelsApi
import parcelsApi from "../shared/api/core/parcels/parcels.reducer";
import authReducer from "../shared/api/gateway/auth/auth.reducer";
import layersApi from "../shared/api/other/layers/layers.reducer";
import radarListApi from "../shared/api/other/radarList/radarList.reducer";
import mainMapTelematicsMachinesDashboard from "../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.reducer";
import mainMapTelematicsApi from "../shared/api/telematics/mainMapTelematics/mainMapTelematics.reducer";
import SectionStatusUIReducer from "../shared/api/telematics/sectionStatus/sectionStatus.reducer";
import weatherApi from "../shared/api/weather/weather.reducer";
import langReducer from "../shared/reducers/lang.reducer";

export default (history) =>
  combineReducers({
    ui: combineReducers({
      map: mapUI(),
      editor: editorUI(),
      layers: layersUI(),
      crops: cropsUI(),
      sowingPlan: sowingPlanUI(),
      weather: weatherUI(),
      mainMapTelematics: mainMapTelematicsUI(),
    }),
    api: combineReducers({
      landuse: landuseApi(),
      parcels: parcelsApi(),
      parcelsNew: parcelsApiNew(),
      sowingPlan: sowingPlanApi(),
      agroevidence: combineReducers({
        crops: cropsApi(),
        actions: agroevidenceActionsApi(),
      }),
      geometry: geometryApi(),
      layers: layersApi(),
      farms: farmsApi(),
      weather: weatherApi(),
      radarList: radarListApi(),
      mainMapTelematics: mainMapTelematicsApi(),
      mainMapTelematicsMachinesDashboard: mainMapTelematicsMachinesDashboard(),
    }),
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
    lang: langReducer,
    telematicsStatus: SectionStatusUIReducer(),
  });
