import React from "react";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";

import TableCell from "./TableCell";

const StatsHeaderPure = (props) => (
  <TableHead>
    <TableRow>
      <TableCell
        key="name"
        classes={{
          root: `${props.classes.statCellBorderBottom} ${props.classes.firstCell}`,
        }}
      >
        <FormattedMessage id="common.name" />
      </TableCell>
      <TableCell
        key="count"
        classes={{
          root: `${props.classes.statCellBorderBottom} ${props.classes.cell}`,
        }}
      >
        <CfTableCellNumber>
          <FormattedMessage id="common.count" />
        </CfTableCellNumber>
      </TableCell>
      <TableCell
        key="area"
        classes={{
          root: `${props.classes.statCellBorderBottom} ${props.classes.cell}`,
        }}
      >
        <CfTableCellNumber>
          <FormattedMessage id="common.area" />
        </CfTableCellNumber>
      </TableCell>
    </TableRow>
  </TableHead>
);

StatsHeaderPure.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  statCellBorderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  statCellBorderBottom: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    color: theme.palette.grey[500],
    backgroundColor: "white",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
});

export const StatsHeader = withStyles(styles)(StatsHeaderPure);
