import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FieldArrayRenderProps, useField, useFormikContext } from "formik";
import { FormattedMessage } from "react-intl";

import OrganismsSelector from "../../../../../catalogues/components/plantProtection/PlantProtectionAdvancedFilter/OrganismsSelector/OrganismsSelector";
import { mapActivePest } from "../../actionEph.services";

import { PestsList } from "./PestsList";

import {
  ActiveSubstanceTo,
  PlantProtectionRegistrationApplicationTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";
import { ActionEphFormValues } from "../../actionEph.types";

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  name: string;
  isEditing: boolean;
  plantProtectionApplication: PlantProtectionRegistrationApplicationTo[];
  dosePerHa: number;
  hasError: boolean;
}

export const PestControl = ({
  arrayHelpers,
  dosePerHa,
  hasError,
  isEditing,
  name,
  plantProtectionApplication,
}: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<ActionEphFormValues>();
  const [field] = useField(name);
  const currentItemsList = field.value ?? [];

  const [targetCropField] = useField("targetCrop");
  const selectedTargetCropId = targetCropField?.value?.id;

  const handleItemRemove = (index: number) => {
    arrayHelpers.remove(index);
  };

  const handleChangeActiveSubstances = (activeSubstance: ActiveSubstanceTo) => {
    const mappedPest = mapActivePest({
      activeSubstance,
      applications: plantProtectionApplication,
      targetCropId: selectedTargetCropId,
    });

    const activeSubstancesIndFields = field.value || [];

    if (activeSubstance && !isActiveSubstanceInList(activeSubstance)) {
      setFieldValue(name, [...activeSubstancesIndFields, mappedPest]);
    }
  };

  const isActiveSubstanceInList = (activeSubstanceToCheck: ActiveSubstanceTo) =>
    currentItemsList.some(
      (activeSubstance: ActiveSubstanceTo) =>
        activeSubstance.id === activeSubstanceToCheck.id,
    );

  return (
    <div className={classes.container}>
      <OrganismsSelector
        disabled={!isEditing}
        error={hasError}
        isUsed
        label={<FormattedMessage id="Por.pestSelector.placeholder" />}
        onChange={handleChangeActiveSubstances}
      />

      <PestsList
        dosePerHa={dosePerHa}
        isEditing={isEditing}
        items={field.value}
        onItemRemove={handleItemRemove}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
    marginLeft: 0,
    marginRight: "auto",
  },
}));
