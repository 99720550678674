import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import CfNoData from "../../../../../shared/components/charts/CfNoData/CfNoData";
import CfStaticMap from "../../../../../shared/components/specific/CfStaticMap/CfStaticMap";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import PrecisionMap from "../../PrecisionMapModal/PrecisionMap";
import PrecisionMapModal from "../../PrecisionMapModal/PrecisionMapModal";
import SectionHeader from "../SectionHeader";

import { ManagementZoneResponseDto } from "../../../../../shared/api/satellite/satellite.types";

interface Props {
  item?: ManagementZoneResponseDto;
}

const RelativeCoverInvolvementMap = ({ item }: Props) => {
  const classes = useStyles();
  const { on: isOpen, setOff: closeDialog, setOn: openDialog } = useToggle();

  const getLegend = (isModal?: boolean) => (
    <div className={classes.mapLegend}>
      <SectionHeader
        headingTranslationId="ManagementZones.detail.heading"
        isModal={isModal}
      />
    </div>
  );

  return (
    <>
      <div className={classes.wrapper} onClick={item ? openDialog : undefined}>
        {getLegend()}
        <div className={classes.imageWrapper}>
          {!item ? (
            <CfNoData />
          ) : (
            <CfStaticMap
              interactive={false}
              mapId="relative-cover-involvement-map"
              sourceImageSmoothing={false}
              classes={{
                map: classes.map,
              }}
              image={{
                url: item.rasterUrl,
                extent: [
                  item.rasterCoordinates.minX,
                  item.rasterCoordinates.minY,
                  item.rasterCoordinates.maxX,
                  item.rasterCoordinates.maxY,
                ],
              }}
            />
          )}
        </div>
      </div>
      {isOpen && item && (
        <PrecisionMapModal
          header={null}
          legend={getLegend(true)}
          onClose={closeDialog}
          map={
            <PrecisionMap
              geometries={null}
              header={null}
              parcelId={item.parcelId}
              sourceImageSmoothing={false}
              mapImage={{
                url: item.rasterUrl,
                extent: [
                  item.rasterCoordinates.minX,
                  item.rasterCoordinates.minY,
                  item.rasterCoordinates.maxX,
                  item.rasterCoordinates.maxY,
                ],
              }}
            />
          }
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    margin: "0 15px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  imageWrapper: {
    flex: 1,
  },
  map: {
    borderRadius: 0,
    "& .ol-viewport": {
      borderRadius: 0,
    },
  },
  mapLegend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 12,
  },
}));

export default RelativeCoverInvolvementMap;
