import React, { useContext, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, TableBody, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getApplicationsSortedPaginated,
  getApplicationsTotalCount,
} from "../../../../selectors/plantProtectionApplications.selectors";
import {
  getSectionListSearch,
  getSectionListOrder,
  getSectionListOrderBy,
  getSectionListPage,
  getSectionListRowsPerPage,
} from "../../../../selectors/sectionList.selectors";

import {
  deletePlantProtectionApplication,
  fetchPlantProtection,
} from "../../../../actions/catalogues.actions";

import {
  getSelectedNamespace,
  setSelectedNamespace,
} from "../../../../reducers/namespace.reducer";

import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableFooter from "../../../../../shared/containers/CfTableFooter/CfTableFooter";
import CfTableHead from "../../../../../shared/containers/CfTableHead/CfTableHead";
import CfTextFilter from "../../../../../shared/containers/CfTextFilter/CfTextFilter";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { getColDesc } from "../../../../../shared/misc/helper";
import { AsyncFn, Thunk } from "../../../../../types";
import { APPLICATIONS_COLUMN_NAMES } from "../../plantProtection.columns";

import ApplicationEditDialog from "./ApplicationEditDialog";
import { ApplicationRow } from "./ApplicationRow";

import {
  CATALOGUES_NAMESPACES,
  CataloguesState,
} from "../../../../../reducers/catalogues.reducer.types";

export const columns = {
  actions: getColDesc(false, <span />, { width: "30px" }),
  [APPLICATIONS_COLUMN_NAMES.CROP]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.cropName" />,
    {
      paddingLeft: 16,
    },
  ),
  [APPLICATIONS_COLUMN_NAMES.ORGANISM]: getColDesc(
    true,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.organismName" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.MIN_DOSE]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.minDose" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.MAX_DOSE]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.maxDose" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.MIN_DOSE_WATER]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.minDoseWater" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.MAX_DOSE_WATER]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.maxDoseWater" />,
  ),
  [APPLICATIONS_COLUMN_NAMES.PROTECTION_PERIOD]: getColDesc(
    false,
    <FormattedMessage id="Catalogues.plantProtection.applications.column.protectionPeriod" />,
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  allowEditing?: boolean;
  isEditing: boolean;
};

type Props = ReduxProps & OwnProps;

const ApplicationsTable = ({
  allowEditing,
  applications,
  count,
  deletePlantProtectionApplication,
  fetchPlantProtection,
  isEditing,
  namespace,
  order,
  orderBy,
  page,
  rowsPerPage,
  search,
  setNamespace,
}: Props) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const { plantProtectionId } = useParams<{ plantProtectionId: string }>();

  const {
    on: showEditDialog,
    setOff: handleEditDialogClose,
    setOn: handleEditDialogOpen,
  } = useToggle();

  useEffect(() => {
    setNamespace(CATALOGUES_NAMESPACES.PLANT_PROTECTION_APPLICATIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => handleEditDialogOpen();

  const onDeleteAccept = (applicationId: string) => {
    (deletePlantProtectionApplication as unknown as AsyncFn<string, string>)(
      plantProtectionId,
      applicationId,
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage id="Catalogues.plantProtection.applications.delete.error" />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: (
          <FormattedMessage id="Catalogues.plantProtection.applications.delete.success" />
        ),
        isSuccess: true,
      });
      fetchPlantProtection(plantProtectionId);
    });
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.filtersHeader}>
          <div className={classes.textFilter}>
            <CfTextFilter
              customStyles={{ width: 330 }}
              initialValue={search}
              name="plantProtection-applications-list-text-filter"
              namespace={namespace}
              translId="Catalogues.plantProtection.applications.search"
            />
          </div>
        </div>
        {allowEditing && (
          <Button
            className={classes.addButton}
            color="secondary"
            disabled={isEditing}
            id="add-application"
            onClick={handleCreate}
            size="small"
            variant="contained"
          >
            <AddIcon sx={{ mr: 1 }} />
            <FormattedMessage id="Catalogues.plantProtection.applications.add" />
          </Button>
        )}
      </div>
      <CfTableWrapper>
        <CfTableHead
          columns={columns}
          namespace={namespace}
          order={order}
          orderBy={orderBy}
        />

        {applications.length ? (
          <TableBody>
            {applications.map((application) => (
              <ApplicationRow
                allowEditing={allowEditing}
                data={application}
                isEditing={isEditing}
                key={application.id}
                onDeleteAccept={() => onDeleteAccept(application.id)}
              />
            ))}
          </TableBody>
        ) : (
          <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
        )}
        <CfTableFooter
          count={count}
          namespace={namespace}
          page={page}
          rowsPerPage={rowsPerPage}
        />
        {showEditDialog && (
          <ApplicationEditDialog
            handleClose={handleEditDialogClose}
            isNew={true}
            opened={showEditDialog}
          />
        )}
      </CfTableWrapper>
    </div>
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  applications: getApplicationsSortedPaginated(state),
  namespace: getSelectedNamespace(state),
  search: getSectionListSearch(state),
  order: getSectionListOrder(state),
  orderBy: getSectionListOrderBy(state),
  page: getSectionListPage(state),
  rowsPerPage: getSectionListRowsPerPage(state),
  count: getApplicationsTotalCount(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      setNamespace: setSelectedNamespace,
      deletePlantProtectionApplication,
      fetchPlantProtection,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ApplicationsTable);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  filtersHeader: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      width: "100%",
    },
  },
  textFilter: {
    flexGrow: 1,
    order: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
      order: 1,
    },
  },
  addButton: {
    color: theme.palette.common.white,
    padding: "4px 8px",
    borderRadius: "50px",
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));
