import React from "react";

import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";
import moment, { Moment } from "moment";
import { useIntl } from "react-intl";

import { getError } from "../../../misc/formHelpers";
import CfDatePickerTranslator from "../CfDatePickerTranslator/CfDatePickerTranslator";
import CfFormControl from "../CfFormControl/CfFormControl";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

type OwnProps = {
  children?: React.ReactNode | React.ReactNode[];
  name: string;
  format?: string;
  propagateInvalidDate?: boolean;
};

type MuiProps = DatePickerProps<Moment>;

type Props = OwnProps & MuiProps;

const FormDatePicker = ({
  children = undefined,
  format,
  name,
  ...restProps
}: Props) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const [field, meta, form] = useField(name);
  const error = getError(meta);

  return (
    <CfFormControl>
      <CfDatePickerTranslator
        cancelLabel={formatMessage({ id: "common.cancel" })}
        clearLabel={formatMessage({ id: "common.clear" })}
        error={!!error}
        format={format || "L"}
        helperText={error}
        invalidDateMessage=""
        momentLocale={moment().locale()}
        name={field.name}
        okLabel={formatMessage({ id: "common.select" })}
        placeholder={moment().startOf("year").format("L")}
        value={field.value}
        onChange={(inputVal: Moment) => {
          form.setValue(inputVal).then(() => {
            formik.validateField(field.name);
          });
        }}
        {...restProps}
      >
        {children}
      </CfDatePickerTranslator>
      <FormErrorMessage name={field.name} />
    </CfFormControl>
  );
};

export default FormDatePicker;
