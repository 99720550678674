import React from "react";

import { Tooltip } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import countBy from "lodash/countBy";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getLayers } from "../../selectors/layers.selectors";
import { getContext, getMainMap } from "../../selectors/map.selectors";

import {
  setLayerVisibility,
  setAllLayersVisibility,
} from "../../actions/layersUI/layersUI.actions";

import { TELEMATICS } from "../../constants/contexts.constants";

import { PUBLIC_LPIS_CONFIG } from "../../../../shared/services/LayersConfig.service";
import { AnyTodo } from "../../../../types";
import MapSwitcherBody from "../../components/MapSwitcherBody/MapSwitcherBody";
import MapSwitcherCheckbox from "../../components/MapSwitcherCheckbox/MapSwitcherCheckbox";
import MapSwitcherHeader from "../../components/MapSwitcherHeader/MapSwitcherHeader";

type Props = {
  expanded: boolean;
  handleExpansion: () => void;
  testId: string;
};

const LayerSwitcher = ({ expanded, handleExpansion, testId }: Props) => {
  const dispatch = useDispatch();
  const context = useSelector(getContext);
  const layers = useSelector(getLayers);
  const map = useSelector(getMainMap);

  const intl = useIntl();

  const getVisibleLayersCount = () =>
    countBy(layers, (layer) => layer.visible).true;

  const getLayerLabel = (layer: AnyTodo) => (
    <span>
      <FormattedMessage id={`LayerSwitcher.${layer.layerId}`} />
      {layer.linkedLayers.map((linkedLayer: AnyTodo) => {
        if (linkedLayer.title) {
          return `/${intl.formatMessage({
            id: `LayerSwitcher.${linkedLayer.layerId}`,
          })}`;
        }
        return "";
      })}
    </span>
  );

  const handleLayerSelect = (layer: AnyTodo, visible: boolean) => {
    dispatch(setLayerVisibility(layer, visible));
  };

  const handleAllLayersSelect = (visible: boolean) => {
    dispatch(setAllLayersVisibility(layers, visible));
  };

  const visibleCount = getVisibleLayersCount();
  const isTelematicsContext = context === TELEMATICS;
  const currentViewResolution = map.getResolution();
  const isLpisLayerVisible =
    currentViewResolution <= PUBLIC_LPIS_CONFIG.MAX_RESOLUTION;

  return (
    <div data-test={testId}>
      <MapSwitcherHeader
        expanded={expanded}
        handleExpansion={handleExpansion}
        testId={testId}
      >
        <MapSwitcherCheckbox
          checked={visibleCount > 0 && layers.length === visibleCount}
          header={true}
          indeterminate={visibleCount > 0 && visibleCount < layers.length}
          label={<FormattedMessage id="LayerSwitcher.layers" />}
          onChange={(event) => handleAllLayersSelect(event.target.checked)}
        />
      </MapSwitcherHeader>
      <MapSwitcherBody expanded={expanded} testId={testId}>
        <FormGroup>
          {layers.map((layer: AnyTodo) => {
            const isPublicLpisLayer =
              layer.layerId === PUBLIC_LPIS_CONFIG.LAYER_ID;
            if (!isTelematicsContext && isPublicLpisLayer) return null;
            const isOptionGrayed =
              isTelematicsContext && isPublicLpisLayer && !isLpisLayerVisible;
            return (
              <Tooltip
                key={layer.layerId}
                title={
                  isOptionGrayed
                    ? intl.formatMessage({
                        id: "LayerSwitcher.not_available",
                      })
                    : ""
                }
              >
                <div>
                  <MapSwitcherCheckbox
                    checked={layer.visible}
                    grayed={isOptionGrayed}
                    label={getLayerLabel(layer)}
                    onChange={(event) =>
                      handleLayerSelect(layer, event.target.checked)
                    }
                  />
                </div>
              </Tooltip>
            );
          })}
        </FormGroup>
      </MapSwitcherBody>
    </div>
  );
};

export default LayerSwitcher;
