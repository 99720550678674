import React from "react";

import FabWithOptions, {
  FabOptionType,
} from "../../../../../../shared/components/common/FabWithOptions/FabWithOptions";

type Props = {
  isHistorical?: boolean;
  isVraAllowed: boolean;
  ngRedirectToEph: (parcelId: string[]) => void;
  ngRedirectToHarvest: (parcelId: string[]) => void;
  ngRedirectToMowing: (parcelId: string[]) => void;
  ngRedirectToOtherActionsNew: (parcelId: string[]) => void;
  ngRedirectToSowing: (parcelId: string[]) => void;
  ngRedirectToVrf: (parcelId: string[]) => void;
  ngRedirectToVrs: (parcelId: string[]) => void;
  parcelId: string;
};

const ParcelDetailFabButton = ({
  isHistorical = false,
  isVraAllowed,
  ngRedirectToEph,
  ngRedirectToHarvest,
  ngRedirectToMowing,
  ngRedirectToOtherActionsNew,
  ngRedirectToSowing,
  ngRedirectToVrf,
  ngRedirectToVrs,
  parcelId,
}: Props) => {
  const fabOptions: FabOptionType[] = [
    {
      id: "sowing",
      translId: "common.addOtherSowingActivity",
      action: () => ngRedirectToSowing([parcelId]),
    },
    {
      id: "eph",
      translId: "common.addEph",
      action: () => ngRedirectToEph([parcelId]),
    },
    {
      id: "other",
      translId: "common.addOtherActivity",
      action: () => ngRedirectToOtherActionsNew([parcelId]),
    },
  ];

  const fabOptsMowing: FabOptionType[] = [
    {
      id: "mowing",
      translId: "common.addOtherMowingActivity",
      action: () => ngRedirectToMowing([parcelId]),
    },
  ];

  const fabOptsHarvest: FabOptionType[] = [
    {
      id: "harvest",
      translId: "common.addHarvestActivity",
      action: () => ngRedirectToHarvest([parcelId]),
      divider: true,
    },
  ];

  const fabOptsVrf: FabOptionType[] = [
    {
      id: "add-var-vert-link",
      translId: "common.addVrfActivity",
      action: () => ngRedirectToVrf([parcelId]),
    },
  ];

  const fabOptsVrs: FabOptionType[] = [
    {
      id: "add-var-seed-link",
      translId: "common.addVrsActivity",
      action: () => ngRedirectToVrs([parcelId]),
    },
  ];

  return (
    <FabWithOptions
      disabled={isHistorical}
      options={[
        ...fabOptions,
        ...fabOptsMowing.map((opt) => ({
          ...opt,
        })),
        ...fabOptsHarvest.map((opt) => ({
          ...opt,
        })),
        ...fabOptsVrf.map((opt) => ({
          ...opt,
          disabled: !isVraAllowed,
        })),
        ...fabOptsVrs.map((opt) => ({
          ...opt,
          disabled: !isVraAllowed,
        })),
      ]}
    />
  );
};

export default ParcelDetailFabButton;
