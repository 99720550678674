import React from "react";

import { Paper, Grid } from "@mui/material";

import DatePickerField from "../../shared/DatePickerField";

import { usePlantProtectionDetailStyles } from "./PlantProtectionDetail.styles";

interface PlantProtectionDetailValidityProps {
  isEditing: boolean;
}
export const PlantProtectionDetailValidity = ({
  isEditing,
}: PlantProtectionDetailValidityProps) => {
  const classes = usePlantProtectionDetailStyles();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="column"
        item
        spacing={2}
        style={{ marginRight: 32 }}
        xs="auto"
      >
        <Grid item>
          <DatePickerField
            className={classes.datePickerField}
            isEditing={isEditing}
            label="Catalogues.plantProtection.applications.legalStart"
            name="legalStart"
          />
        </Grid>
        <Grid item>
          <DatePickerField
            className={classes.datePickerField}
            isEditing={isEditing}
            label="Catalogues.plantProtection.applications.legalEnd"
            name="legalEnd"
          />
        </Grid>
        <Grid item>
          <DatePickerField
            className={classes.datePickerField}
            isEditing={isEditing}
            label="Catalogues.plantProtection.applications.marketEnd"
            name="marketEnd"
          />
        </Grid>
        <Grid item>
          <DatePickerField
            className={classes.datePickerField}
            isEditing={isEditing}
            label="Catalogues.plantProtection.applications.usableUntil"
            name="usableUntil"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
