import React, { Component } from "react";

import { Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel, {
  TableSortLabelProps,
} from "@mui/material/TableSortLabel";
import { withStyles } from "@mui/styles";
import classnames from "classnames";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { setTableSorting, setTableSelected } from "../../actions/table.actions";

import * as sortOrder from "../../constants/sortOrder.constants";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = React.PropsWithChildren<{
  bulkMode?: boolean;
  checked?: boolean;
  className?: string;
  classes: Record<
    "root" | "rightPadding" | "superRightPadding" | "desktopOnly",
    string
  >;
  columns: Record<
    string,
    {
      align?: TableCellProps["align"];
      hideOnMobile?: boolean;
      label?: (() => React.ReactNode) | React.ReactNode;
      rightPadding?: boolean;
      sortable?: boolean;
      style?: React.CSSProperties;
      superRightPadding?: boolean;
    }
  >;
  customSelectHandler?: (checked: boolean) => void;
  customSortHandler?: (
    colId: string,
  ) => React.MouseEventHandler<HTMLSpanElement>;
  disabledCheckbox?: boolean;
  indeterminate?: boolean;
  itemId?: string;
  items?: unknown[];
  namespace?: string;
  order?: TableSortLabelProps["direction"];
  orderBy?: string;
  selected?: unknown[];
  selectedOnPage?: unknown[];
}>;
type Props = ReduxProps & OwnProps;

export class CfTableHead extends Component<Props> {
  getCheckboxEl = () => {
    const {
      checked,
      classes,
      customSelectHandler,
      disabledCheckbox,
      indeterminate,
      items,
      selectedOnPage,
    } = this.props;
    const itemsLength = items ? items.length : null;
    const selectedItemsLength = selectedOnPage?.length ?? 0;

    const anyItemsChecked =
      selectedItemsLength > 0 &&
      itemsLength !== null &&
      selectedItemsLength < itemsLength;

    return (
      <TableCell
        padding="checkbox"
        style={{ width: "50px" }}
        classes={{
          root: classes.root,
        }}
      >
        <Checkbox
          color="primary"
          data-test="table-checkbox"
          disabled={disabledCheckbox}
          indeterminate={indeterminate ?? anyItemsChecked}
          checked={
            checked !== undefined
              ? checked
              : selectedItemsLength > 0 && selectedItemsLength === itemsLength
          }
          onChange={(e, checked) => {
            if (customSelectHandler) {
              customSelectHandler(
                anyItemsChecked || indeterminate ? !checked : checked,
              );
            } else {
              this.handleSelectAll(
                e,
                anyItemsChecked || indeterminate ? !checked : checked,
              );
            }
          }}
        />
      </TableCell>
    );
  };

  handleSelectAll = (
    _evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const {
      itemId = "id",
      items = [],
      namespace,
      selected = [],
      selectedOnPage = [],
    } = this.props;
    if (checked) {
      this.props.setTableSelected(
        // @ts-expect-error item is of type unknown
        uniq([...selected, ...items.map((item) => item[itemId])]),
        namespace,
      );
    } else {
      this.props.setTableSelected(
        difference(selected, selectedOnPage),
        namespace,
      );
    }
  };

  createSortHandler = (property: string) => (event: React.MouseEvent) => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (_evt: React.MouseEvent, colId: string) => {
    const { namespace = null, order, orderBy } = this.props;
    const newOrder =
      colId !== orderBy || order === sortOrder.DESC
        ? sortOrder.ASC
        : sortOrder.DESC;
    this.props.setTableSorting(newOrder, colId, namespace);
  };

  render() {
    const {
      bulkMode = true,
      children,
      className,
      classes,
      columns,
      customSortHandler,
      order,
      orderBy,
      selected,
    } = this.props;
    return (
      <TableHead>
        {children}
        <TableRow className={className}>
          {Array.isArray(selected) && bulkMode && this.getCheckboxEl()}
          {Object.entries(columns).map(
            ([colId, col]) => (
              <TableCell
                align={col.align}
                key={colId}
                size="small"
                style={col.style}
                classes={{
                  root: classnames({
                    [classes.rightPadding]: col.rightPadding,
                    [classes.superRightPadding]: col.superRightPadding,
                    [classes.desktopOnly]: col.hideOnMobile,
                    [classes.root]: true,
                  }),
                }}
              >
                {col.sortable ? (
                  <TableSortLabel
                    active={orderBy === colId}
                    data-test={`order-${order}`}
                    direction={orderBy === colId ? order : sortOrder.ASC}
                    onClick={
                      customSortHandler
                        ? customSortHandler(colId)
                        : this.createSortHandler(colId)
                    }
                  >
                    {typeof col.label === "function" ? col.label() : col.label}
                  </TableSortLabel>
                ) : (
                  <span>
                    {typeof col.label === "function" ? col.label() : col.label}
                  </span>
                )}
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTableSorting,
      setTableSelected,
    },
    dispatch,
  );

const connector = connect(null, mapDispatchToProps);

const styles = (theme: Theme) => ({
  root: {
    color: theme.palette.grey[500],
  },
  rightPadding: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
    },
  },
  superRightPadding: {
    [theme.breakpoints.up("xl")]: {
      paddingRight: "200px",
    },
  },
  desktopOnly: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

export default connector(withStyles(styles)(CfTableHead));
