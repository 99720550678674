import React, { useEffect, useRef, useState } from "react";

import { Theme } from "@mui/material";
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderGroupParams,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import { FormattedMessage } from "react-intl";

import { AnyTodo } from "../../../../types";

import { sortedSuggestions } from "./helper";

type MuiProps = Omit<
  AutocompleteProps<
    AnyTodo,
    false, // Multiple
    boolean, // DisableClearable
    false, // FreeSolo
    "div" // ChipComponent
  >,
  "onChange" | "renderOption" | "renderInput" | "options" | "input"
>;

type OwnProps = {
  defaultValues?: AnyTodo;
  disableClearable?: boolean;
  error?: boolean;
  getLabel?: (option: AnyTodo) => string;
  getSelected?: (option: AnyTodo, value: AnyTodo) => boolean;
  getSortingString?: (item: AnyTodo) => string;
  groupByOptions?: (option: AnyTodo) => string;
  helperText?: React.ReactNode;
  input?: object;
  isFetching?: boolean;
  isMultiple?: boolean;
  label?: React.ReactNode;
  loadOptions?: (inputValue?: string) => void;
  multilineInput?: boolean;
  onChange: (value: AnyTodo) => void;
  renderGroup?: (option: AutocompleteRenderGroupParams) => void;
  renderOption?: (option: AnyTodo) => React.ReactNode;
  suggestions: AnyTodo[];
  testId?: string;
  clearInputOnSelect?: boolean;
};

export type CfAutocompleteProps = MuiProps & OwnProps;

const CfAutocomplete = ({
  clearInputOnSelect = false,
  defaultValues = null,
  disableClearable = false,
  disabled,
  error,
  filterOptions,
  fullWidth,
  getLabel,
  getSelected = (option, value) => option.name === value?.name,
  getSortingString,
  groupByOptions,
  helperText,
  id = "cf-autocomplete",
  input = {},
  isFetching,
  isMultiple,
  label,
  loadOptions,
  multilineInput,
  onChange,
  placeholder,
  renderGroup = (option) => option.children,
  renderOption = (option) => option?.label || option.name,
  renderTags,
  suggestions,
  testId = "cf-autocomplete",
}: CfAutocompleteProps) => {
  const classes = useStyles();

  const [value, setValue] = useState<AnyTodo>(defaultValues || null);
  const [inputValue, setInputValue] = useState("");
  const prevDefaultValues = useRef(defaultValues);

  useEffect(() => {
    if (
      !isEqual(defaultValues, prevDefaultValues.current) &&
      !isEqual(defaultValues, value)
    ) {
      setValue(defaultValues);
    }
    prevDefaultValues.current = defaultValues;
  }, [defaultValues, value]);

  const onSelect = (v?: AnyTodo) => {
    clearInputOnSelect ? setValue("") : setValue(v);
    onChange(v);
  };

  const handleRenderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AnyTodo,
  ) => {
    const uniqueKey = option?.id || Math.random().toString();
    return (
      <li {...props} key={uniqueKey}>
        {renderOption(option)}
      </li>
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        {...input}
        data-test={testId}
        disableClearable={disableClearable}
        disabled={disabled}
        filterOptions={filterOptions}
        fullWidth={fullWidth}
        groupBy={(option) => (groupByOptions ? groupByOptions(option) : "")}
        id={id}
        loading={isFetching}
        multiple={isMultiple}
        noOptionsText={<FormattedMessage id="AutosuggestSelect.noOptions" />}
        onChange={(event, v) => onSelect(v)}
        options={sortedSuggestions(suggestions, inputValue, getSortingString)}
        renderGroup={(option) => renderGroup && renderGroup(option)}
        renderOption={(p, o) => handleRenderOption(p, o)}
        renderTags={renderTags}
        value={value}
        getOptionLabel={(option) => {
          if (getLabel) return getLabel(option);
          else
            return typeof option === "string"
              ? option
              : option?.label || option?.name || "";
        }}
        isOptionEqualToValue={(option, v) =>
          getSelected && getSelected(option, v)
        }
        onInputChange={(event, inputValue, reason) => {
          if (loadOptions) {
            if (reason === "input") {
              loadOptions(inputValue);
            }
            if (reason === "clear" || !inputValue) {
              loadOptions();
            }
          }
          setInputValue(inputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={error ? classes.errorInput : undefined}
            error={error}
            fullWidth
            helperText={helperText}
            label={label}
            multiline={multilineInput}
            placeholder={placeholder}
            variant="standard"
          />
        )}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiAutocomplete-tag": {
      fontSize: 14,
      margin: "0px 2px 2px 0px",
      "& .MuiChip-deleteIcon": {
        color: theme.palette.grey[500],
        "&:hover": {
          color: theme.palette.grey[700],
        },
      },
    },
    "& .MuiInput-root": {
      paddingTop: 0,
    },
  },
  errorInput: {
    "& .MuiAutocomplete-input": {
      "&::placeholder": {
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
  },
}));

export default CfAutocomplete;
