import React, { useContext } from "react";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import CfTableCell from "../../../shared/components/tables/CfTableCell/CfTableCell";
import { CataloguesContext } from "../../containers/CataloguesWrapper/CataloguesWrapper";
import { getSourceId } from "../../helpers/selectorHelpers";

import { COLUMN_NAMES } from "./plantProtection.columns";

import { PlantProtectionOverviewToV2 } from "../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  data: PlantProtectionOverviewToV2;
  onUpdateIsFavorite: (plantProtectionId: string, isFavorite: boolean) => void;
}

export const PlantProtectionListRowV2 = ({
  data,
  onUpdateIsFavorite,
}: Props) => {
  const { farmId } = useContext(CataloguesContext);

  const classes = useStyles();
  const history = useHistory();

  const isValid = moment(data.validUntil).isAfter(moment().startOf("day"));

  const handleFavoriteClick = () => {
    onUpdateIsFavorite(data.id, data.isFavorite);
  };

  return (
    <TableRow
      className={classes.pointer}
      hover
      key={data.id}
      onClick={() =>
        history.push(`/farm/${farmId}/catalogues/plantProtection/${data.id}`)
      }
    >
      <TableCell
        classes={{ root: classes.isFavorite }}
        key={COLUMN_NAMES.IS_FAVORITE}
        onClick={(evt) => evt.stopPropagation()}
        padding="checkbox"
      >
        <Checkbox
          checked={data.isFavorite}
          checkedIcon={<StarRoundedIcon />}
          color="secondary"
          icon={<StarBorderRoundedIcon />}
          onClick={handleFavoriteClick}
        />
      </TableCell>
      <CfTableCell classes={{ root: classes.name }} name={COLUMN_NAMES.NAME}>
        {data.name}
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.SOURCE}>
        <FormattedMessage id={getSourceId(data.catalogue?.type)} />
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.VALID_TO}>
        <div
          className={classNames({
            [classes.dataIsHistorical]: !isValid,
          })}
        >
          {data?.validUntil ? <FormattedDate value={data.validUntil} /> : "-"}
        </div>
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.ACTIVE_SUBSTANCES}>
        <div className={classes.truncate}>
          {data.activeSubstances.length > 0
            ? data.activeSubstances.join(", ")
            : "-"}
        </div>
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.CROP}>
        <div className={classes.truncate}>
          {data.crops.length > 0 ? data.crops.join(", ") : "-"}
        </div>
      </CfTableCell>
      <CfTableCell name={COLUMN_NAMES.ORGANISMUS}>
        <div className={classes.truncate}>
          {data.organisms.length > 0 ? data.organisms.join(", ") : "-"}
        </div>
      </CfTableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pointer: {
    cursor: "pointer",
  },
  isFavorite: {
    paddingLeft: 7,
    width: 30,
  },
  name: {
    fontWeight: 500,
  },
  operation: {
    paddingLeft: theme.spacing(2),
  },
  dataIsHistorical: {
    color: theme.palette.error.main,
  },
  truncate: {
    overflow: "hidden",
    maxWidth: 550,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));
