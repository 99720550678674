import React, { FC, Fragment, useContext, useState } from "react";

import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { FertilizerUsageReportParams } from "../../../../shared/api/agroevidence/reports/reports.api";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { AsyncFn } from "../../../../types";
import { FarmerPortalHeading } from "../shared";

import FertilizerUsageReportDialog from "./FertilizerUsageReportDialog";
import FertilizerUsageReportErrorDialog from "./FertilizerUsageReportErrorDialog";

import {
  FertilizerUseReportMissingDataBody,
  EagriFertilizerUseReportType,
} from "../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    marginBottom: 10,
    fontSize: 24,
    fontWeight: 500,
  },
  info: {
    fontSize: 13,
    color: theme.palette.grey[500],
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  errorButton: {
    marginLeft: 30,
  },
}));

const setStatisticsReportDeadlineDate = () => {
  let year;
  if (moment().month() <= 1) {
    year = moment().year();
  } else {
    year = moment().year() + 1;
  }

  // exception for year 2024
  if (moment().year() === 2024) {
    year = moment().year() + 1;
  }

  return moment().year(year).month(1).endOf("month");
};

interface FertilizerUsageReportProps {
  errorDetailedMessage?: FertilizerUseReportMissingDataBody;
  farmId: string;
  sendFertilizerUsageReportToEagri: (
    params: FertilizerUsageReportParams,
  ) => void;
}

const FertilizerUsageReport: FC<FertilizerUsageReportProps> = ({
  errorDetailedMessage,
  farmId,
  sendFertilizerUsageReportToEagri,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const date = setStatisticsReportDeadlineDate();
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleDialogAccept = (
    dateFrom: string,
    dateTo: string,
    reportType: EagriFertilizerUseReportType,
  ) => {
    const params = {
      from: dateFrom,
      to: dateTo,
      type: reportType,
    };
    (sendFertilizerUsageReportToEagri as AsyncFn<FertilizerUsageReportParams>)(
      params,
    ).then((res) => {
      if (res.error) {
        showSnackbar({
          message: (
            <FormattedMessage id="Reports.fertilizerUsageReport.error" />
          ),
          isError: true,
        });
        return;
      }
      showSnackbar({
        message: (
          <FormattedMessage id="Reports.fertilizerUsageReport.success" />
        ),
        isSuccess: true,
      });
    });
    handleDialogClose();
  };

  const handleDialogOpen = () => {
    setShowDialog(true);
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleErrorDialogOpen = () => {
    setShowErrorDialog(true);
  };
  const handleErrorDialogClose = () => {
    setShowErrorDialog(false);
  };

  return (
    <Fragment>
      {showDialog && (
        <FertilizerUsageReportDialog
          onAccept={handleDialogAccept}
          onClose={handleDialogClose}
          showDialog={showDialog}
        />
      )}
      {showErrorDialog && (
        <FertilizerUsageReportErrorDialog
          errorDetailedMessage={errorDetailedMessage}
          farmId={farmId}
          onClose={handleErrorDialogClose}
          showErrorDialog={showErrorDialog}
        />
      )}
      <FarmerPortalHeading titleTranslateString="Reports.fertilizerUsageReport.Heading" />
      <Grid className={classes.info} container justifyContent="center">
        <Grid item sm={2} xs={1} />
        <Grid item sm={8} xs={12}>
          <FormattedMessage
            id="Reports.fertilizerUsageReport.Info"
            values={{ date: date.format("D.M.YYYY") }}
          />
        </Grid>
        <Grid item sm={2} xs={1} />
      </Grid>
      <div className={classes.button}>
        <Button color="primary" onClick={handleDialogOpen} variant="contained">
          <FormattedMessage id="Reports.sendData.Button" />
        </Button>
        {errorDetailedMessage && (
          <Button
            className={classes.errorButton}
            color="error"
            onClick={handleErrorDialogOpen}
            variant="contained"
          >
            <FormattedMessage id="Reports.fertilizerUsageReport.ErrorButton" />
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default FertilizerUsageReport;
