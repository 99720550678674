import React, { FC, useMemo, useState } from "react";

import { Theme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import CfDialogTabs from "../../../../../../shared/components/common/CfDialogTabs/CfDialogTabs";
import CfSimpleDialog from "../../../../../../shared/components/common/CfSimpleDialog/CfSimpleDialog";

import {
  ParcelEagriInfoTo,
  EagriNitrateRestrictionTo,
  EagriSubsidyTo,
} from "../../../../../../shared/api/agroevidence/agroevidence.types";

const useStyles = makeStyles((theme: Theme) => ({
  restrictionCode: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary.main,
    margin: "20px 0 12px 0",
  },
  restrictionMessage: {
    fontSize: 16,
  },
  restrictionMessageEmpty: {
    fontSize: 16,
    fontWeight: 500,
  },
  content: {
    margin: "24px 0px",
  },
  divider: {
    margin: "20px 0 20px 0",
  },
}));

enum RESTRICTIONS_GROUPS {
  AEKO = "aeko",
  NITRAT = "nitrate",
}

interface ParcelDetailRestrictionsProps {
  restrictions: ParcelEagriInfoTo;
}

export const ParcelDetailRestrictions: FC<ParcelDetailRestrictionsProps> = ({
  restrictions,
}) => {
  const classes = useStyles();
  const { aeko, nitrate } = restrictions;

  const [dialogOpened, setDialogOpened] = useState(false);

  const [tabValue, setTabValue] = useState(
    !nitrate.length && aeko.length
      ? RESTRICTIONS_GROUPS.AEKO
      : RESTRICTIONS_GROUPS.NITRAT,
  );

  const tabs = useMemo(() => {
    const showTabs = [
      {
        id: RESTRICTIONS_GROUPS.NITRAT,
        labelId: "ParcelDetailRestrictions.nitrat",
        visible: !!nitrate.length,
      },
      {
        id: RESTRICTIONS_GROUPS.AEKO,
        labelId: "ParcelDetailRestrictions.aeko",
        visible: !!aeko.length,
      },
    ];
    return showTabs.filter((t) => t.visible);
  }, [aeko, nitrate]);

  const renderRestrictions = (
    restrictions: EagriNitrateRestrictionTo[] | EagriSubsidyTo[],
  ) =>
    restrictions.map((r, index) => (
      <div data-test="restrictions-content" key={r.code}>
        <div className={classes.content}>
          <div className={classes.restrictionCode}>{r.code}</div>
          {r.description?.length === 0 ? (
            <div className={classes.restrictionMessageEmpty}>-</div>
          ) : (
            <div
              className={classes.restrictionMessage}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: r.description }}
            />
          )}
        </div>
        {index < restrictions.length - 1 && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
      </div>
    ));

  return (
    <div>
      <a href="#" onClick={() => setDialogOpened(true)}>
        <FormattedMessage id="ParcelDetailOverview.restrictionsButton" />
      </a>
      <CfSimpleDialog
        data-test="parcel-restrictions"
        onDialogClose={() => setDialogOpened(false)}
        open={dialogOpened}
        heading={
          <FormattedMessage id="ParcelDetailOverview.restrictionsButton" />
        }
      >
        <CfDialogTabs
          activateTab={(val) => setTabValue(val as RESTRICTIONS_GROUPS)}
          tabsInfo={tabs}
          tabValue={tabValue}
        />
        {tabValue === RESTRICTIONS_GROUPS.NITRAT && renderRestrictions(nitrate)}
        {tabValue === RESTRICTIONS_GROUPS.AEKO && renderRestrictions(aeko)}
      </CfSimpleDialog>
    </div>
  );
};
