import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Scrollbars } from "react-custom-scrollbars-2";

import { StatsBody } from "../../components/ParcelZoneTable/StatsBody";
import { StatsFooter } from "../../components/ParcelZoneTable/StatsFooter";
import { StatsHeader } from "../../components/ParcelZoneTable/StatsHeader";

import {
  CropTo,
  FarmStatisticsTo,
} from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  parcelsStats: FarmStatisticsTo;
  onFilterCropInParcels: (zones: CropTo[]) => void;
};

const CropParcelStats = ({ onFilterCropInParcels, parcelsStats }: Props) => (
  <>
    <Table stickyHeader>
      <StatsHeader />
    </Table>
    <Scrollbars autoHeight={true} autoHeightMax={400}>
      <Table data-test="crop-list">
        <TableBody>
          {parcelsStats.crops
            .sort((a, b) => b.totalArea - a.totalArea)
            .map((crop, id) => (
              <StatsBody
                item={crop}
                key={id}
                onFilterCropInParcels={onFilterCropInParcels}
              />
            ))}
        </TableBody>
      </Table>
    </Scrollbars>
    <Table>
      <TableBody>
        {parcelsStats.parcels.totalArea !== undefined && (
          <StatsFooter stats={parcelsStats.parcels} />
        )}
      </TableBody>
    </Table>
  </>
);

export { CropParcelStats };
