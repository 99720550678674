import React, { Fragment, useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { useFormikContext } from "formik";

import AbsoluteParcelSubtractableAreas from "../../components/AbsoluteParcelSubtractableAreas/AbsoluteParcelSubtractableAreas";
import BoundaryParcelSubtractableAreas from "../../components/BoundaryParcelSubtractableAreas/BoundaryParcelSubtractableAreas";
import WaterParcelSubtractableAreas from "../../components/WaterParcelSubtractableAreas/WaterParcelSubtractableAreas";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";

type Props = {
  isEditing: boolean;
  maxValue: number;
  onParcelSubtractionDelete: (type: string, value: number) => void;
  parcel: InitialParcelToAdd;
  parcelIndex: number;
  parcelsInForm: InitialParcelToAdd[];
  updateParcelsActionArea: (parcelsInForm: InitialParcelToAdd[]) => void;
};

const ActionParcelSubtractableAreas = ({
  isEditing,
  maxValue,
  onParcelSubtractionDelete,
  parcel,
  parcelIndex,
  parcelsInForm,
  updateParcelsActionArea,
}: Props) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();

  const prevPropsRef = useRef({ parcel });

  const getMaxMaterialValue = (areas: SubtractionResponse[]) =>
    areas.reduce((maxValue, sa) => {
      if (sa.isMaterial && sa.value > maxValue) {
        return sa.value;
      } else {
        return maxValue;
      }
    }, 0);

  useEffect(() => {
    const prevSubtractableAreas = prevPropsRef.current.parcel.subtractableAreas;
    const subtractableAreas = parcel.subtractableAreas;

    if (
      prevSubtractableAreas.absolute !== subtractableAreas.absolute ||
      prevSubtractableAreas.boundaryChecked !==
        subtractableAreas.boundaryChecked ||
      prevSubtractableAreas.waterChecked !== subtractableAreas.waterChecked
    ) {
      updateParcelsActionArea(parcelsInForm);
    }

    if (
      prevSubtractableAreas.boundary !== subtractableAreas.boundary &&
      isEditing
    ) {
      const maxValue = getMaxMaterialValue(subtractableAreas.boundary);

      setFieldValue(
        `parcels.${parcelIndex}.subtractableAreas.boundaryChecked`,
        maxValue || 0,
      );
    }

    if (prevSubtractableAreas.water !== subtractableAreas.water && isEditing) {
      const maxValue = getMaxMaterialValue(subtractableAreas.water);

      setFieldValue(
        `parcels.${parcelIndex}.subtractableAreas.waterChecked`,
        maxValue || 0,
      );
    }

    prevPropsRef.current = { parcel };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcel.subtractableAreas]);

  return (
    <Fragment>
      <AbsoluteParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
      <Divider className={classes.divider} />
      <BoundaryParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
      <Divider className={classes.divider} />
      <WaterParcelSubtractableAreas
        isEditing={isEditing}
        maxValue={maxValue}
        onParcelSubtractionDelete={onParcelSubtractionDelete}
        parcelIndex={parcelIndex}
      />
    </Fragment>
  );
};

export default ActionParcelSubtractableAreas;

const useStyles = makeStyles(() => ({
  divider: {
    margin: 10,
  },
}));
