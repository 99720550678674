import React, { Fragment, ReactElement } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormattedMessage, FormattedDate } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getParcelSeedApplication,
  getParcelPredecessors,
  getParcelEagriRestrictions,
  getIsFetchingParcelSeedApplication,
} from "../../../../../../shared/api/agroevidence/parcels/parcels.selectors";
import { getParcelSowingPlan } from "../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors";
import { getPrecisionParcel } from "../../../../../../shared/api/sentinel/precision/precision.selectors";
import { getIsFetchingParcelDetails } from "../../selectors/parcelDetailOverview.selectors";

import {
  assignCenter,
  assignCrop,
} from "../../../../shared/actions/parcels.actions";
import {
  updateParcelNote,
  updateLpisYieldGrade,
} from "../../actions/parcelDetailOverview.actions";

import { getParcelApi } from "../../../../../../shared/api/agroevidence/parcels/parcels.api";
import { getParcelSowingPlanApi } from "../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api";
import CfLoader from "../../../../../../shared/components/common/CfLoader/CfLoader";
import { CropName } from "../../../../../../shared/components/specific/CropName/CropName";
import { useTypedIntl } from "../../../../../../shared/hooks/useTypedIntl";
import Localization from "../../../../../../shared/services/Localization.service";
import { PrecisionTableServiceIndicators } from "../../../../../precision/components/PrecisionTableServiceIndicators";
import ParcelCenterAssign from "../../../../shared/containers/ParcelCenterAssign/ParcelCenterAssign";
import {
  ParcelsService,
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from "../../../../shared/services/Parcels.service";
import EditableNote from "../../components/EditableNote/EditableNote";
import HistoricalParcelsPanel from "../../components/HistoricalParcelsPanel/HistoricalParcelsPanel";
import { ParcelDetailCropTable } from "../../components/ParcelDetailCropTable/ParcelDetailCropTable";
import { ParcelDetailRestrictions } from "../../components/ParcelDetailRestrictions/ParcelDetailRestrictions";
import { ParcelDetailSection } from "../../components/ParcelDetailSection/ParcelDetailSection";
import ParcelSowingPlanTable from "../../components/ParcelSowingPlanTable";
import { ParcelZones } from "../../components/ParcelZones/ParcelZones";
import YieldGradeSelector from "../../components/YieldGradeSelector/YieldGradeSelector";
import { useStyles } from "../../styles";

import {
  ParcelDetailTo,
  ParcelPredecessorTo,
} from "../../../../../../shared/api/agroevidence/agroevidence.types";
import { PrecisionParcel } from "../../../../../../shared/api/sentinel/precision/precision.types";

type Props = {
  countryCode: string;
  displayedMap: boolean;
  farmId: string;
  goToParcel: (parcel: ParcelPredecessorTo) => void;
  parcel: ParcelDetailTo;
  isParcelInZOD: boolean;
};

export const ParcelDetailOverview = ({
  countryCode,
  displayedMap,
  farmId,
  goToParcel,
  isParcelInZOD,
  parcel,
}: Props) => {
  const classes = useStyles();
  const intl = useTypedIntl();
  const dispatch = useDispatch();

  const parcelSeedApplication = useSelector(getParcelSeedApplication);
  const isFetchingSeedApplication = useSelector(
    getIsFetchingParcelSeedApplication,
  );

  const parcelPredecessors = useSelector(getParcelPredecessors);
  const isFetchingParcelDetails = useSelector(getIsFetchingParcelDetails);
  const parcelRestrictions = useSelector(getParcelEagriRestrictions);
  const precisionParcel = useSelector(getPrecisionParcel);
  const parcelSowingPlan = useSelector(getParcelSowingPlan);

  const confirmEditingNote = (newNote: string) =>
    dispatch(updateParcelNote(parcel.id, newNote));

  const onAssignCenter = (centerId: string, parcelId: string) => {
    (
      dispatch(assignCenter(centerId, parcelId)) as unknown as Promise<unknown>
    ).then(() => {
      dispatch(getParcelApi(parcelId));
    });
  };

  const onAssignCrop = (cropId: string, seasonId: string, parcelId: string) => {
    (
      dispatch(
        assignCrop(cropId, seasonId, parcelId),
      ) as unknown as Promise<unknown>
    ).then(() => {
      dispatch(
        getParcelSowingPlanApi(
          parcelId,
          START_YEAR_FIRST_SOWING_SEASONS,
          SHOWN_NUMBER_SOWING_SEASONS,
        ),
      );
    });
  };

  const renderHeading = (messageId: string) => (
    <Grid data-test="heading" item xs={12}>
      <Typography className={classes.heading} variant="h5">
        <FormattedMessage id={messageId} />
      </Typography>
    </Grid>
  );

  const renderErrorNotification = (notificationString: string) => (
    <div className={classes.errorNotificationContainer}>
      <span className={classes.errorNotification}>
        <FormattedMessage id={notificationString} />
      </span>
    </div>
  );

  const renderParcelDetailSection = (
    translationId: string,
    value: ReactElement | string,
  ) => {
    const gridItem = displayedMap ? 4 : 3;
    return (
      <Grid className={classes.gridItem} item md={gridItem} sm={4} xs={6}>
        <ParcelDetailSection translationId={translationId} valueItem={value} />
      </Grid>
    );
  };

  const renderCropName = () => (
    <CropName cropType={parcel.seedApplication?.type}>
      {parcel.seedApplication?.seed.crop.name}
    </CropName>
  );

  const renderOverview = () => (
    <Grid
      className={classes.overviewPanelItem}
      data-test="parcel-overview"
      item
      xs={12}
    >
      <Paper className={classes.paper}>
        <Grid container>
          {renderParcelDetailSection(
            "common.area",
            `${Localization.num2str(parcel.area, intl.locale)} ha`,
          )}
          {renderParcelDetailSection(
            "common.crop",
            parcel.seedApplication?.seed.crop.name ? (
              renderCropName()
            ) : (
              <FormattedMessage id="common.noCrop" />
            ),
          )}
          {renderParcelDetailSection(
            "common.culture",
            parcel.eagri.landUseName,
          )}
          {renderParcelDetailSection(
            "common.variety",
            parcel.seedApplication?.seed?.varietyName ?? "-",
          )}
          {renderParcelDetailSection(
            "common.center",
            <ParcelCenterAssign
              onCenterChange={onAssignCenter}
              parcel={parcel}
            />,
          )}
          {renderParcelDetailSection(
            "common.validity",
            <span>
              <FormattedDate value={parcel.validFrom} />
              {" \u2013 "}
              {parcel.validTo ? <FormattedDate value={parcel.validTo} /> : ""}
            </span>,
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const renderSowingPlan = () => (
    <Grid className={classes.panelItem} data-test="sowing-plan" item xs={12}>
      <Paper className={classes.paper}>
        <ParcelSowingPlanTable
          onCropChange={onAssignCrop}
          parcel={parcel}
          parcelSowingPlan={parcelSowingPlan}
        />
      </Paper>
    </Grid>
  );

  const renderCzechSpecifics = () => {
    const { altitude, applicationZone, slope, userYieldGrade, yieldGrade } =
      parcel.eagri;
    return (
      <Grid
        className={classes.panelItem}
        data-test="czech-specific-overview"
        item
        xs={12}
      >
        <div>
          <Paper className={classes.paper}>
            <Grid container>
              {renderParcelDetailSection(
                "term.NSA",
                <FormattedMessage
                  id={`common.${parcel.nitrateVulnerable ? "yes" : "no"}`}
                />,
              )}
              {renderParcelDetailSection(
                "common.yieldGrade",
                parcel.nitrateVulnerable
                  ? renderEditableYieldGrade(yieldGrade, userYieldGrade)
                  : "-",
              )}
              {renderParcelDetailSection(
                "common.applicationZone",
                applicationZone || "-",
              )}
              {renderParcelDetailSection(
                "common.altitude",
                `${Localization.num2str(altitude, intl.locale)} m`,
              )}
              {renderParcelDetailSection(
                "common.slope",
                `${Localization.num2str(slope, intl.locale)}\xB0`,
              )}
              {!parcelRestrictions?.nitrate &&
                renderErrorNotification(
                  "ParcelDetailOverview.restrictions.error",
                )}
              {parcelRestrictions?.nitrate &&
                renderParcelDetailSection(
                  "ParcelDetailOverview.restrictions",
                  parcelRestrictions.nitrate?.length > 0 ||
                    parcelRestrictions?.aeko?.length > 0 ? (
                    <ParcelDetailRestrictions
                      restrictions={parcelRestrictions}
                    />
                  ) : (
                    "-"
                  ),
                )}
            </Grid>
          </Paper>
        </div>
      </Grid>
    );
  };

  const renderCropsTable = () => (
    <Grid className={classes.panelItem} data-test="crops-table" item xs={12}>
      <ParcelDetailCropTable
        isParcelInZOD={isParcelInZOD}
        parcelSeedApplication={parcelSeedApplication}
      />
    </Grid>
  );

  const renderPrecisionFarming = () => (
    <Grid
      className={classes.panelItem}
      data-test="precision-farming"
      item
      xs={12}
    >
      <Paper className={classes.precisionPaper}>
        <PrecisionTableServiceIndicators
          farmId={farmId}
          forceReloadOnClick
          parcel={precisionParcel as PrecisionParcel}
        />
      </Paper>
    </Grid>
  );

  const renderHistoricalParcels = () => (
    <Grid
      className={classes.panelItem}
      data-test="historical-parcels"
      item
      xs={12}
    >
      <HistoricalParcelsPanel
        goToParcel={goToParcel}
        historicalParcels={parcelPredecessors}
      />
    </Grid>
  );

  const renderNote = () => (
    <Grid
      className={classes.panelItem}
      data-test="note"
      item
      lg={6}
      md={9}
      xs={12}
    >
      <EditableNote
        defaultValue={intl.formatMessage({ id: "ParcelDetailOverview.noNote" })}
        initialValue={parcel.notes ? parcel.notes : ""}
        inputTestId="parcel-note"
        onConfirmEditing={(newValue: string) => confirmEditingNote(newValue)}
      />
    </Grid>
  );

  const renderEditableYieldGrade = (
    yieldGrade?: string,
    userYieldGrade?: string,
  ) => {
    const isHistorical = ParcelsService.isParcelHistorical(parcel);
    return (
      <YieldGradeSelector
        isHistorical={isHistorical}
        userYieldGrade={userYieldGrade}
        yieldGrade={yieldGrade}
        updateParcelYieldGrade={(chosenYieldGrade: "I" | "II" | "III") =>
          dispatch(updateLpisYieldGrade(parcel.id, chosenYieldGrade))
        }
      />
    );
  };

  const czechFarm = countryCode === "CZ";
  const hasPrecisionService = precisionParcel !== undefined;

  return (
    <Fragment>
      {isFetchingParcelDetails ? (
        <CfLoader />
      ) : (
        <Grid container>
          {renderHeading("ParcelDetailOverview.basicOverview")}
          {renderOverview()}
          {czechFarm && renderCzechSpecifics()}
          {renderHeading("ParcelDetailOverview.sowingPlan")}
          {renderSowingPlan()}
          {renderHeading("ParcelDetailOverview.crops")}
          {isFetchingSeedApplication ? <CfLoader /> : renderCropsTable()}
          {hasPrecisionService && (
            <Fragment>
              {renderHeading("ParcelDetailOverview.precisionFarming")}
              {renderPrecisionFarming()}
            </Fragment>
          )}
          {renderHeading("ParcelDetailOverview.previousHistoricalParcels")}
          {renderHistoricalParcels()}
          {renderHeading("ParcelDetailOverview.zones")}
          <ParcelZones parcel={parcel} />
          {renderHeading("common.note")}
          {renderNote()}
        </Grid>
      )}
    </Fragment>
  );
};

export default ParcelDetailOverview;
