import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import SelectionItem from "../../../../../shared/components/common/SelectionItem/SelectionItem";

import { ActiveSubstanceTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  items: ActiveSubstanceTo[];
  onItemRemove: (val: number) => void;
  isEditing: boolean;
};

const ActiveSubstancesList = ({
  isEditing,
  items = [],
  onItemRemove,
}: Props) => {
  const classes = useStyles();

  const renderActiveSubstanceItem = (
    field: ActiveSubstanceTo,
    index: number,
  ) => {
    const renderIntegratedProductionItem = (
      integratedProductionId: string,
      checkParam?: boolean,
    ) => (
      <div className={classNames(classes.integratedProductionItem)}>
        {checkParam ? (
          <CheckIcon color="primary" />
        ) : (
          <ClearIcon color="error" />
        )}
        <FormattedMessage
          id={`Catalogues.plantProtection.detail.integratedProduction.${integratedProductionId}`}
        />
      </div>
    );

    const {
      id,
      isIntegratedProductionOrchard,
      isIntegratedProductionVegetable,
      isIntegratedProductionVineyard,
      name,
    } = field;

    return (
      <SelectionItem
        editing={isEditing}
        key={id}
        onRemoveItem={() => {
          onItemRemove(index);
        }}
      >
        <div className={classes.row}>
          <div className={classes.name}>{name}</div>
          <div>
            <div className={classes.label}>
              <FormattedMessage id="Catalogues.plantProtection.detail.integratedProduction" />
            </div>
            <div className={classNames(classes.integratedProduction)}>
              {renderIntegratedProductionItem(
                "vineyard",
                isIntegratedProductionVineyard,
              )}
              {renderIntegratedProductionItem(
                "orchard",
                isIntegratedProductionOrchard,
              )}
              {renderIntegratedProductionItem(
                "vegetable",
                isIntegratedProductionVegetable,
              )}
            </div>
          </div>
        </div>
      </SelectionItem>
    );
  };

  return (
    <div className={classes.activeSubstances} id="active-substances-list">
      {items.map((field, index) => renderActiveSubstanceItem(field, index))}
    </div>
  );
};

export default ActiveSubstancesList;

const useStyles = makeStyles((theme: Theme) => ({
  activeSubstances: {
    marginTop: 25,
    marginBottom: 35,
  },
  name: {
    fontSize: 16,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  label: {
    color: theme.palette.grey[400],
  },
  integratedProduction: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 20,
  },
  integratedProductionItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    fontSize: 14,
  },
}));
