import { ActionsState } from "../../../../../reducers/actions.reducer.types";
import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { StoresState } from "../../../../../reducers/stores.reducer.types";

export const getPlantProtection = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.plantProtection.items;
export const getPlantProtectionV2 = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.itemsV2;
export const getIsFetching = (
  state: ActionsState | StoresState | CataloguesState,
) => state.api.agroevidence.plantProtection.isFetching;
export const getPlantProtectionCount = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.totalCount;

export const getPlantProtectionDetail = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.item;
export const getPlantProtectionDetailV2 = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.itemV2;
export const getPlantProtectionDetailApplicationsV2 = (
  state: CataloguesState,
) => state.api.agroevidence.plantProtection.itemV2Applications;

export const getActiveSubstances = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.activeSubstances;
export const getIsFetchingActiveSubstances = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingActiveSubstances;

export const getOrganisms = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.organisms;
export const getIsFetchingOrganisms = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingOrganisms;

export const getBioFunctions = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.bioFunctions;
export const getIsFetchingBioFunctions = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.isFetchingBioFunctions;

export const getPlantProtectionOrganisms = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.itemOrganisms;

export const getPlantProtectionApplication = (state: CataloguesState) =>
  state.api.agroevidence.plantProtection.applications;
