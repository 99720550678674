import { formValueSelector, arrayPush, arrayRemove, change } from "redux-form";

import { getOtherActionTypesApi } from "../../../../shared/api/agroevidence/actions/actions.api";
import { getCropsApi } from "../../../../shared/api/agroevidence/catalogues/crops/crops.api";
import {
  getCropUsageApi,
  getHarvestProductsApi,
} from "../../../../shared/api/agroevidence/catalogues/eagri/eagri.api";
import {
  getFertilizersSuggestionsApi,
  resetFertilizersApi,
} from "../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api";
import {
  getPlantProtectionsSuggestionsApi,
  resetPlantProtectionsApi,
} from "../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import {
  getParcelsApi,
  resetParcelsApi,
  addParcels,
  getParcelSubtractionsGeometryApi,
  resetParcelSubtractionsGeometryApi,
  updateParcelsSubtractionsAreaApi,
} from "../../../../shared/api/agroevidence/parcels/parcels.api";
import {
  deleteSubtractionAreaApi,
  getParcelHistorySubtractionsApi,
  updateParcelsSubtractionsApi,
} from "../../../../shared/api/agroevidence/subtractableAreas/subtractableAreas.api";
import {
  getZonesApi,
  resetZonesApi,
} from "../../../../shared/api/agroevidence/zones/zones.api";
import { getUnits } from "../../../../shared/api/core/units/units.api";
import {
  exportVariableApplication as exportVariableApplicationApi,
  exportVariableApplicationLegacy as exportVariableApplicationLegacyApi,
  getAGIntegratedJobs,
} from "../../../../shared/api/sentinel/variableApplication/variableApplication.api";
import FileService from "../../../../shared/services/File.service";

export const getExpensesExportsTableId = (applicationId) =>
  `expenses-exports-${applicationId}`;

export const fetchCropUsage = (cropExternalId) => (dispatch) =>
  dispatch(getCropUsageApi(cropExternalId));

export const fetchHarvestProduct = (cropExternalId) => (dispatch) => {
  dispatch(getHarvestProductsApi(cropExternalId));
};

export const fetchUnits = () => (dispatch) => {
  dispatch(getUnits());
};

export const fetchOtherActionTypes = () => (dispatch) =>
  dispatch(getOtherActionTypesApi());

export const fetchParcelsAndZonesSuggestions =
  (searchInput, parcelsOnly = false, zonesOnly = false, sown) =>
  (dispatch) => {
    if (parcelsOnly) {
      return dispatch(
        getParcelsApi({ context: "suggestions", search: searchInput, sown }),
      );
    }

    if (zonesOnly) {
      return dispatch(getZonesApi(searchInput));
    }

    dispatch(
      getParcelsApi({ context: "suggestions", search: searchInput, sown }),
    );
    dispatch(getZonesApi(searchInput));
  };

export const clearParcelsAndZonesSuggestions =
  (parcelsOnly = false, zonesOnly = false) =>
  (dispatch) => {
    if (parcelsOnly) {
      return dispatch(resetParcelsApi("suggestions"));
    }

    if (zonesOnly) {
      return dispatch(resetZonesApi());
    }

    dispatch(resetParcelsApi("suggestions"));
    dispatch(resetZonesApi());
  };

export const addParcelOrZoneParcelsToAdd = (parcelOrZone) => (dispatch) => {
  const isZone = "parcelCount" in parcelOrZone;
  if (isZone && parcelOrZone.parcelCount > 0) {
    dispatch(
      getParcelsApi({
        context: "additions",
        zones: parcelOrZone.id,
        "per-page": 200,
      }),
    );
  } else if (!isZone) {
    dispatch(addParcels("additions", parcelOrZone));
  }
};

export const getAllParcelsInZone = (zone) => (dispatch) =>
  dispatch(
    getParcelsApi({ context: "additions", zones: zone.id, "per-page": 200 }),
  ).then((sa) => sa.payload);

export const addInitParcelsToAdd = (initParcelIds) => (dispatch) => {
  dispatch(
    getParcelsApi({ context: "additions", ids: initParcelIds.join(",") }),
  );
};

export const addInitZoneParcelsToAdd = (zoneIds) => (dispatch) => {
  dispatch(
    getParcelsApi({
      context: "additions",
      zones: zoneIds.join(","),
      "per-page": 200,
    }),
  );
};

export const clearParcelsToAdd = () => (dispatch) => {
  dispatch(resetParcelsApi("additions"));
};

export const updateParcelsList = (parcels, form) => (dispatch) => {
  dispatch(change(form, "parcels", parcels));
};

export const fetchCrops = (search) => (dispatch) => {
  const params = {
    search,
    "include-no-crop": true,
  };
  dispatch(getCropsApi(params));
};

export const fetchPlantProtectionByName =
  (search, usableUntil, targetCropId) => (dispatch) => {
    const params = {
      search,
    };

    if (usableUntil) {
      params["usable-until"] = usableUntil;
    }

    if (targetCropId) {
      params["crop-id"] = targetCropId;
    }

    dispatch(getPlantProtectionsSuggestionsApi(params));
  };

export const fetchFertilizersByName = (search) => (dispatch) =>
  dispatch(getFertilizersSuggestionsApi({ search }));

export const getChemistryAndFertilizersByName =
  (searchInput, validOn, chemOnly = false, fertOnly = false) =>
  (dispatch) => {
    if (chemOnly) {
      return dispatch(
        getPlantProtectionsSuggestionsApi({ search: searchInput }),
      );
    }

    if (fertOnly) {
      return dispatch(
        getFertilizersSuggestionsApi({
          search: searchInput,
          "valid-on": validOn,
        }),
      );
    }

    dispatch(getPlantProtectionsSuggestionsApi({ search: searchInput }));
    dispatch(
      getFertilizersSuggestionsApi({
        search: searchInput,
        "valid-on": validOn,
      }),
    );
  };

export const clearChemistryAndFertilizers =
  (chemOnly, fertOnly) => (dispatch) => {
    if (chemOnly) {
      return dispatch(resetPlantProtectionsApi());
    }

    if (fertOnly) {
      return dispatch(resetFertilizersApi());
    }

    dispatch(resetPlantProtectionsApi());
    dispatch(resetFertilizersApi());
  };

/*
 * PARCEL SUBTRACTIONS RELATED FUNCTIONS
 */

/*
 * Fetch already defined subtraction areas
 * e.g. absolute areas defined by map drawing
 */
export const fetchParcelHistorySubtractions =
  (parcelId, parcelIds) => (dispatch) =>
    dispatch(getParcelHistorySubtractionsApi(parcelId, parcelIds)).then(
      (sa) => sa.payload,
    );

/*
 * Recalculate subtractable areas for parcels already in form
 * because adding new parcel (or POR material in EPH form) can affect subtractable areas
 * in the previously added parcels (e.g. if the share border)
 */

// for redux forms
export const updateParcelsSubtractions = (params) => (dispatch) =>
  dispatch(updateParcelsSubtractionsApi(params)).then((res) => res.payload);

// for formik forms
export const updateParcelsSubtractionsArea = (params) => (dispatch) =>
  dispatch(updateParcelsSubtractionsAreaApi(params)).then((res) => res.payload);

/*
 * Recalculate geometry of parcel depend on selected subtractions (vrf, vrs actions)
 */
export const updateParcelSubtractionsGeometry = (params) => (dispatch) =>
  dispatch(getParcelSubtractionsGeometryApi(params)).then((res) => res.payload);

export const resetParcelSubtractionsGeometry = () => (dispatch) => {
  dispatch(resetParcelSubtractionsGeometryApi());
};
/*
 * RESTRICTED AREA RELATED FUNCTIONS
 *
 * Calculates parcel restricted area (= sum of subtractable areas for given parcel)
 * EPH, VRF, VRS
 */
export const getRestrictedArea = (subtractableAreas) => (dispatch) => {
  const checkedSa = subtractableAreas?.filter((sa) => sa.isUsed) || [];

  const restrictedArea = checkedSa
    .map((item) => item.area)
    .reduce((sum, area) => sum + area, 0);
  dispatch(change("subtractableAreas", "restrictedArea", restrictedArea));
};

export const addSubtractionArea = (subtractableAreas) => (dispatch) => {
  dispatch(
    arrayPush("subtractableAreas", "subtractableAreas", subtractableAreas),
  );
};

export const deleteSubtractionArea =
  (parcelId, type, value, indexForDelete) => (dispatch) => {
    dispatch(deleteSubtractionAreaApi(parcelId, type, value));
    // TODO delete when reduxForm EPH will remove
    dispatch(
      arrayRemove("subtractableAreas", "subtractableAreas", indexForDelete),
    );
  };

export const updateSubtractableAreas = (sas, index, formName) => (dispatch) => {
  dispatch(change(formName, `parcels[${index}].subtractableAreas`, sas));
};

export const updateParcelRestrictedArea =
  (restrictedArea, index, formName) => (dispatch) =>
    dispatch(
      change(formName, `parcels[${index}].restrictedArea`, restrictedArea),
    );

export const updateParcelActionTotalArea =
  (actionParcelTotalArea, index, formName) => (dispatch) =>
    dispatch(
      change(
        formName,
        `parcels[${index}].actionParcelTotalArea`,
        actionParcelTotalArea,
      ),
    );

export const updateParcelGeometry = (geometry, index, formName) => (dispatch) =>
  dispatch(change(formName, `parcels[${index}].geometry`, geometry));

export const updateVariableExpense =
  (variableExpense, index, formName) => (dispatch) => {
    dispatch(
      change(formName, `expenses[${index}].variableExpense`, variableExpense),
    );
  };

export const updateFertilizationType = (formName, newType) => (dispatch) => {
  dispatch(change(formName, "fertilizationType", newType));
};

export const updateVariableExpenseZoneDose =
  (payload, totalDose, expenseIndex, zoneIndex, formName) =>
  (dispatch, getState) => {
    const expenses = formValueSelector(formName)(getState(), "expenses");

    const newZone = {
      ...expenses[expenseIndex].variableExpense.applicationZones[zoneIndex],
      totalDose,
      ...(payload ? { k: payload.k2oIntake } : {}),
      ...(payload ? { accountableN: payload.nitrogenIntake } : {}),
      ...(payload ? { p: payload.p2o5Intake } : {}),
    };

    dispatch(
      change(
        formName,
        `expenses[${expenseIndex}].variableExpense.applicationZones[${zoneIndex}]`,
        newZone,
      ),
    );
  };

export const updateVariableExpenseSumValues =
  (payload, expenseIndex, formName, sumTotalDose, averageDose) =>
  (dispatch, getState) => {
    const expenses = formValueSelector(formName)(getState(), "expenses");
    const { variableExpense } = expenses[expenseIndex];

    const newVarExpense = {
      ...variableExpense,
      doseHa: averageDose,
      totalDose: sumTotalDose,
      ...(payload ? { k: payload.k2oIntake } : {}),
      ...(payload ? { accountableN: payload.nitrogenIntake } : {}),
      ...(payload ? { p: payload.p2o5Intake } : {}),
    };

    dispatch(
      change(
        formName,
        `expenses[${expenseIndex}].variableExpense`,
        newVarExpense,
      ),
    );
  };

export const exportVariableApplications =
  (applications, exportType, parcelName, parcelNumber) => (dispatch) => {
    if (exportType === "COMMON" || exportType === "TRIMBLE") {
      applications.forEach(({ id }) => {
        dispatch(exportVariableApplicationLegacyApi(id, exportType)).then(
          (res) => {
            FileService.processFileResponse(res);
          },
        );
      });
    } else {
      applications.forEach(({ id, material }, index) => {
        dispatch(
          exportVariableApplicationApi(
            id,
            exportType,
            material,
            parcelName,
            parcelNumber,
          ),
        ).then(() => {
          dispatch(getAGIntegratedJobs(id));
          if (index === 0) {
            setTimeout(
              () =>
                document
                  .getElementById(getExpensesExportsTableId(id))
                  .scrollIntoView(true),
              100,
            );
          }
        });
      });
    }
  };
