import React from "react";

import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { PlantProtectionRegistrationApplicationTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

export const ApplicationRowDetails = ({
  data,
}: {
  data: PlantProtectionRegistrationApplicationTo;
}) => {
  const classes = useStyles();

  const renderBufferByDriftReduction = (
    driftReduction: number,
    type: string,
  ) => {
    const buffer = data.buffers.find(
      (buffer) =>
        buffer.type === type && buffer.driftReduction === driftReduction,
    );

    if (!buffer) return "0 m";
    return buffer.isAllowed ? (
      `${buffer.buffer} m`
    ) : (
      <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reductionNotAllowed" />
    );
  };

  const renderIntervalString = (minInterval?: number, maxInterval?: number) => {
    if (minInterval || maxInterval) {
      const intervalString = [minInterval, maxInterval]
        .filter(Boolean)
        .join(" - ");

      return (
        <span>
          {intervalString}{" "}
          <FormattedMessage id="Catalogues.plantProtection.application.editDialog.dayUnit" />
        </span>
      );
    }

    return <span>-</span>;
  };

  return (
    <Grid
      className={classes.container}
      container
      data-test="application-detail"
    >
      <Grid container direction="column" item rowSpacing={1} xs={3}>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.minBbch" />
          </Typography>
          <Typography variant="body2">{data.minBbch ?? "-"}</Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.maxBbch" />
          </Typography>
          <Typography variant="body2">{data.maxBbch ?? "-"}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" item rowSpacing={1} xs={3}>
        <Grid container direction="row" item justifyContent="space-around">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.interval" />
          </Typography>
          <Typography variant="body2">
            {renderIntervalString(data.minInterval, data.maxInterval)}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-around">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.allowedApplications" />
          </Typography>
          <Typography variant="body2">
            {data.allowedApplications ?? "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" item rowSpacing={1} xs={3}>
        <Grid container direction="row" item justifyContent="center">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.distanceEdge" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.withoutReduction" />
          </Typography>
          <Typography variant="body2">
            {renderBufferByDriftReduction(0, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction50" />
          </Typography>
          <Typography variant="body2">
            {renderBufferByDriftReduction(50, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction75" />
          </Typography>
          <Typography variant="body2">
            {renderBufferByDriftReduction(75, "Boundary")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction90" />
          </Typography>
          <Typography variant="body2">
            {renderBufferByDriftReduction(90, "Boundary")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" item rowSpacing={1} xs={3}>
        <Grid container direction="row" item justifyContent="center">
          <Typography variant="subtitle2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.distanceWater" />
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.withoutReduction" />
          </Typography>
          <Typography variant="body2">
            {renderBufferByDriftReduction(0, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction50" />
          </Typography>
          <Typography variant="body2">
            {" "}
            {renderBufferByDriftReduction(50, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction75" />
          </Typography>
          <Typography variant="body2">
            {" "}
            {renderBufferByDriftReduction(75, "Water")}
          </Typography>
        </Grid>
        <Grid container direction="row" item justifyContent="space-evenly">
          <Typography variant="body2">
            <FormattedMessage id="Catalogues.plantProtection.application.editDialog.reduction90" />
          </Typography>
          <Typography variant="body2">
            {" "}
            {renderBufferByDriftReduction(90, "Water")}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.noteRow} container direction="row" item xs={12}>
        <Typography className={classes.noteLabel} variant="subtitle2">
          <FormattedMessage id="common.note" />:
        </Typography>
        <Typography variant="body2"> {data.notes ?? "-"}</Typography>
      </Grid>
    </Grid>
  );
};

export default ApplicationRowDetails;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    padding: 20,
  },
  noteRow: {
    paddingTop: 20,
  },
  noteLabel: {
    paddingRight: 20,
  },
}));
