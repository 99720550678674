import React, { Fragment } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import {
  isActionButtonEnabled,
  isSensorsTabActive,
  isActionButtonHidden,
} from "../../selectors/manager.selectors";

import { exportNodes as exportNodesApi } from "../../../sensors/actions/sensors.actions";
import { setDialogOpen } from "../../actions/manager.actions";

import { LANGUAGE_ID } from "../../../../../shared/lang/lang.constants";

import { ExportButton } from "../../../../../shared/components/common/ExportButton/ExportButton";
import PageHeader from "../../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../../shared/components/common/PageHeading/PageHeading";
import { Farms } from "../../../farms/Farms";
import { IrrigationAdmin } from "../../../irrigation/containers/IrrigationAdmin/IrrigationAdmin";
import NotificationsAdmin from "../../../notifications/containers/NotificationsAdmin/NotificationsAdmin";
import SensorsAdmin from "../../../sensors/containers/SensorsAdmin/SensorsAdmin";
import { SentinelAdmin } from "../../../sentinel/containers/SentinelAdmin/SentinelAdmin";
import TelematicsAdmin from "../../../telematics/TelematicsAdmin";
import AdminFabButton from "../../components/AdminFabButton/AdminFabButton";
import AdminManagerTabs from "../../components/AdminManagerTabs/AdminManagerTabs";

import { AdminFarm } from "../../../sensors/admin.sensors.types";

type Props = {
  history: History;
  match: unknown;
  location: unknown;
  langId: LANGUAGE_ID;
  ngImpersActivate: (
    farm: AdminFarm,
    userId: string,
    state: string,
    sensorId: { sensorId: string },
  ) => void;
};

export const AdminManager = ({
  history,
  langId,
  location,
  match,
  ngImpersActivate,
}: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const actionButtonEnabled = useSelector(isActionButtonEnabled);
  const actionButtonHidden = useSelector(isActionButtonHidden);
  const sensorsTabActive = useSelector(isSensorsTabActive);

  return (
    <div>
      <div className={classes.headerWithTabs}>
        <div className={classes.headerWrapper}>
          <PageHeader
            heading={<PageHeading dataTest="admin-heading" value="Admin" />}
            actionButtons={
              <Fragment>
                {sensorsTabActive && (
                  <div className={classes.exportButton}>
                    <ExportButton
                      aria-label="export"
                      id="export-sensor"
                      onClick={() => dispatch(exportNodesApi())}
                    />
                  </div>
                )}
                {!actionButtonHidden && (
                  <AdminFabButton
                    enabled={actionButtonEnabled}
                    handleDialogOpen={() => dispatch(setDialogOpen(true))}
                  />
                )}
              </Fragment>
            }
          />
        </div>
        <AdminManagerTabs history={history} location={location} match={match} />
      </div>
      <div className={classes.contentWrapper}>
        <Switch>
          <Route
            exact
            path="/farm/:farmId/admin/farms"
            render={() => <Farms />}
          />
          <Route
            exact
            path="/farm/:farmId/admin/sensors"
            render={() => (
              <SensorsAdmin
                langId={langId}
                ngImpersActivate={ngImpersActivate}
              />
            )}
          />
          <Route
            exact
            path="/farm/:farmId/admin/notifications"
            render={() => <NotificationsAdmin langId={langId} />}
          />
          <Route
            exact
            path="/farm/:farmId/admin/sentinel"
            render={() => <SentinelAdmin />}
          />
          <Route
            exact
            path="/farm/:farmId/admin/irrigation"
            render={() => <IrrigationAdmin langId={langId} />}
          />
          <Route
            exact
            path="/farm/:farmId/admin/telematics"
            render={() => <TelematicsAdmin langId={langId} />}
          />
        </Switch>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  contentWrapper: {
    padding: theme.spacing(2),
  },
}));
