import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const usePlantProtectionDetailStyles = makeStyles((theme: Theme) => ({
  body: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headWrapper: {
    padding: 24,
    marginBottom: 20,
  },
  headerActionButtons: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  headingFontSize: {
    fontSize: 24,
    textAlign: "center",
  },
  headingContentConfirming: {
    color: theme.palette.grey[400],
  },
  headingValidationError: {
    color: theme.palette.error.main,
  },
  copyButton: {
    color: "#fff",
    height: "50%",
    padding: "4px 8px",
    borderRadius: "50px",
    fontSize: 14,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
  textField: {
    width: 500,
  },
  wrapper: {
    padding: "0px 24px 24px 24px",
  },
  spinnerWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sectionHeading: {
    marginBottom: 5,
    fontSize: 20,
  },
  paper: {
    padding: 20,
    fontSize: 13,
  },
  fieldFormik: {
    width: 300,
    marginBottom: 20,
    marginRight: 20,
  },
  bioFunctionSelector: {
    width: 250,
    marginBottom: 20,
    marginRight: 20,
  },
  datePickerField: {
    width: "250px",
  },
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
  },
}));
