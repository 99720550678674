import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FieldArrayRenderProps, useFormikContext, useField } from "formik";
import { FormattedMessage } from "react-intl";

import ActiveSubstancesSelector from "../../PlantProtectionAdvancedFilter/ActiveSubstancesSelector/ActiveSubstancesSelector";

import ActiveSubstancesList from "./ActiveSubstancesList";

import { ActiveSubstanceTo } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { PlantProtectionDetailFormValues } from "../PlantProtectionDetail.types";

type Props = {
  arrayHelpers: FieldArrayRenderProps;
  name: string;
  isEditing: boolean;
};

const ActiveSubstancesControl = ({ arrayHelpers, isEditing, name }: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<PlantProtectionDetailFormValues>();
  const [field] = useField(name);

  const handleItemRemove = (index: number) => {
    arrayHelpers.remove(index);
  };

  const handleChangeActiveSubstances = (activeSubstance: ActiveSubstanceTo) => {
    const activeSubstancesIndFields = field.value || [];
    if (activeSubstance && !isActiveSubstanceInList(activeSubstance)) {
      setFieldValue("activeSubstances", [
        ...activeSubstancesIndFields,
        activeSubstance,
      ]);
    }
  };

  const isActiveSubstanceInList = (activeSubstanceToCheck: ActiveSubstanceTo) =>
    field.value.some(
      (activeSubstance: ActiveSubstanceTo) =>
        activeSubstance.id === activeSubstanceToCheck.id,
    );

  return (
    <div className={classes.container}>
      <ActiveSubstancesSelector
        defaultValues={null}
        disabled={!isEditing}
        onChange={handleChangeActiveSubstances}
        label={
          <FormattedMessage id="Catalogues.plantProtection.detail.activeSubstancesControl" />
        }
      />
      <ActiveSubstancesList
        isEditing={isEditing}
        items={field.value}
        onItemRemove={handleItemRemove}
      />
    </div>
  );
};

export default ActiveSubstancesControl;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
    },
    marginLeft: 0,
    marginRight: "auto",
  },
}));
