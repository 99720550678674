import React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { usePlantProtectionDetailStyles } from "../PlantProtectionDetail.styles";

import { PlantProtectionRegistrationDataTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

interface Props {
  registrations?: PlantProtectionRegistrationDataTo[];
  isEditing: boolean;
  handleCopyPlantProtection: (
    registrations: PlantProtectionRegistrationDataTo[],
  ) => void;
}

export const AuthorizationHolders = ({
  handleCopyPlantProtection,
  isEditing,
  registrations = [],
}: Props) => {
  const classes = usePlantProtectionDetailStyles();

  const handleLinkToEagri = (eagriRegistrationId: string) =>
    window.open(
      `https://eagri.cz/public/app/eagriapp/POR/Detail.aspx?id=${eagriRegistrationId}`,
      "_blank",
    );

  return (
    <Paper className={classes.paper}>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
      >
        {registrations.map((registration, index) => (
          <Grid item key={index}>
            <span className={classes.authorizationHolder}>
              <a
                href="#"
                onClick={
                  registration.externalId
                    ? () => handleLinkToEagri(registration.externalId as string)
                    : undefined
                }
              >
                {registration.registrationHolder}
              </a>
            </span>
            <Tooltip
              followCursor
              title={
                <span>
                  <FormattedMessage id="Catalogues.plantProtection.detail.copy.tooltip" />
                </span>
              }
            >
              <IconButton
                disabled={isEditing}
                onClick={() => handleCopyPlantProtection([registration])}
                size="large"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
