import React, { FC, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import { FormattedDate, FormattedMessage } from "react-intl";

import CfDatePicker from "../../../../shared/components/common/CfDatePicker/CfDatePicker";
import { getShortDateString } from "../../../../shared/misc/timeHelpers";

const useStyles = makeStyles(() => ({
  dialogContainer: {
    maxWidth: "400px",
  },
  itemContainer: {
    padding: "5px 0px",
  },
  button: {
    marginLeft: 8,
  },
  radioGroupWrapper: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 30,
  },
  dateSelectorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  label: {
    fontSize: 12,
    marginBottom: 5,
  },
  statisticsDateRange: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 5,
  },
  grid: {
    marginRight: 30,
  },
  lastMonthText: {
    fontWeight: 400,
  },
}));

enum PorUseReportType {
  LAST_MONTH = "LAST_MONTH",
  EXACT_DATE = "EXACT_DATE",
}

interface PorUsageReportDialogProps {
  onAccept: (dateTo: string) => void;
  onClose: () => void;
  showDialog?: boolean;
}

export const PorUsageReportDialog: FC<PorUsageReportDialogProps> = ({
  onAccept,
  onClose,
  showDialog = false,
}) => {
  const classes = useStyles();

  const [reportType, setReportType] = useState<string>(
    PorUseReportType.LAST_MONTH,
  );
  const [dateFrom, setDateFrom] = useState<Moment>(
    moment().subtract(1, "months").endOf("month"),
  );

  const lastDate = getShortDateString(
    moment().subtract(1, "months").endOf("month"),
  );

  const handleSendReport = () => {
    if (reportType === PorUseReportType.LAST_MONTH) {
      onAccept(getShortDateString(moment(lastDate)));
    }
    if (reportType === PorUseReportType.EXACT_DATE) {
      onAccept(getShortDateString(moment(dateFrom)));
    }
  };

  const handleRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setReportType((event.target as HTMLInputElement).value);
  };

  const onDateFromChange = (value: Moment) => {
    const newValue = moment(value).startOf("day");
    setDateFrom(newValue);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="report-por-dialog"
      onClose={onClose}
      open={showDialog}
    >
      <DialogTitle>
        <FormattedMessage id="Reports.porUsageReport.dialogTitle" />
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.itemContainer}>
          <Grid className={classes.radioGroupWrapper} item xs={5}>
            <DialogContentText className={classes.label}>
              <FormattedMessage id="Reports.porUsageReport.dialogTypeLabel" />
            </DialogContentText>
            <RadioGroup
              aria-labelledby="controlled-radio-buttons-group"
              name="radio-buttons-group"
              onChange={handleRadioGroupChange}
              value={reportType}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={PorUseReportType.LAST_MONTH}
                label={
                  <FormattedMessage id="Reports.porUsageReport.lastMonthReport" />
                }
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={PorUseReportType.EXACT_DATE}
                label={
                  <FormattedMessage id="Reports.porUsageReport.exactDateReport" />
                }
              />
            </RadioGroup>
          </Grid>
          {reportType === PorUseReportType.LAST_MONTH && (
            <div>
              <DialogContentText className={classes.label}>
                <FormattedMessage id="Reports.porUsageReport.lastMonthLabel" />
              </DialogContentText>
              <span className={classes.lastMonthText}>
                <FormattedDate
                  month="long"
                  year="numeric"
                  value={moment()
                    .subtract(1, "months")
                    .endOf("month")
                    .toString()}
                />
              </span>
            </div>
          )}
          {reportType === PorUseReportType.EXACT_DATE && (
            <Grid className={classes.grid} item xs={5}>
              <CfDatePicker
                label={<FormattedMessage id="common.date" />}
                maxDate={undefined}
                maxDateMessage=""
                minDate={undefined}
                minDateMessage=""
                name="dateFrom"
                input={{
                  value: dateFrom,
                  onChange: onDateFromChange,
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          spacing={1}
        >
          <Button
            className={classes.button}
            id="reset"
            onClick={onClose}
            type="reset"
            variant="text"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            className={classes.button}
            color="primary"
            id="send"
            onClick={handleSendReport}
            type="submit"
            variant="text"
          >
            <FormattedMessage id="common.send" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
