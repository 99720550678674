/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState, FC, useContext } from "react";

import { Grid } from "@mui/material";
import classnames from "classnames";
import { Field, Form, Formik, FormikProps } from "formik";
import { round } from "lodash";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RSAAResultAction } from "redux-api-middleware";

import {
  getIsFetchingParcel,
  getParcelError,
  getParcel,
} from "../../../shared/api/agroevidence/parcels/parcels.selectors";
import {
  getMachineByGpsUnit,
  getOperations,
  getProductionOperations,
} from "../../../shared/api/telematics/drives/drives.selectors";

import { calculateArea } from "../../actions/telematicsAggregations.actions";

import InfoBlueFaded from "../../../assets/img/icons/info_blue-faded.svg";
import { getParcelApi } from "../../../shared/api/agroevidence/parcels/parcels.api";
import { getAggregatedOverlapsApi } from "../../../shared/api/telematics/aggregations/aggregations.api";
import CfFormControl from "../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikTimePicker from "../../../shared/components/form/CfFormikTimePicker/CfFormikTimePicker";
import TimeIntervalsBar from "../../../shared/components/misc/TimeIntervalsBar/TimeIntervalsBar";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import {
  getLocalizedDateString,
  getShortDateString,
} from "../../../shared/misc/timeHelpers";
import { COLOR_PRIMARY, COLOR_TIMELINE } from "../../../theme";
import { AsyncFn, Thunk } from "../../../types";
import {
  alignTimesWithDate,
  getNotOverlapedTime,
  sumTimeIntervals,
} from "../../helpers/index";
import EmptyParcelBox from "../EmptyParcelBox/EmptyParcelBox";
import LpisBlock from "../LpisBlock/LpisBlock";
import TelematicsDetailEquipmentSelector from "../TelematicsDetailSelectors/TelematicsDetailEquipmentSelector";
import TelematicsDetailMachineSelector from "../TelematicsDetailSelectors/TelematicsDetailMachineSelector";
import TelematicsForeignWarning, {
  TelematicsForeignWarningType,
} from "../TelematicsForeignWarning/TelematicsForeignWarning";
import TelematicsParcel, {
  TelematicsParcelType,
} from "../TelematicsParcel/TelematicsParcel";
import TelematicsParcelSelector from "../TelematicsParcelSelector/TelematicsParcelSelector";

import {
  ApprovalWarning,
  OperationField,
  DateField,
  ProductionOperationField,
  CultivatedField,
  DistanceField,
  BonusField,
  DriverField,
  WorkingWidthField,
  Buttons,
} from "./formComponents";
import { useTelematicsAggregationDetailContentStyles } from "./styles";
import { validateContentForm } from "./validators";

import {
  FORM_TYPES,
  Props,
  ConnectedProps,
  DetailContentFormValues,
} from "./DetailContentForm.types";
import { TelematicsState } from "../../../reducers/telematics.reducer.types";
import { ParcelTo } from "../../../shared/api/agroevidence/agroevidence.types";
import {
  Type,
  DriveUpdateTo,
  EquipmentTo,
  MachineTo,
  DriveCreateTo,
  Affiliation,
  ProductionOperationTo,
  DriveDetailOverlapTo,
  DriverTo,
  DriveOverlapRequestTo,
  TelematicsOperation,
} from "../../../shared/api/telematics/telematics.types";

const initialEmptyValues: Partial<DetailContentFormValues> = {
  date: undefined,
  operation: undefined,
  productionOperation: undefined,
  cultivated: 0,
  distance: 0,
  driverCode: "",
  bonus: 0,
  gpsUnit: "",
  equipmentCode: "",
  workingWidth: 0,
  parcelId: undefined,
  type: Type.MANUAL,
  timeFrom: moment().startOf("day"),
  timeTo: moment().startOf("day"),
  formType: FORM_TYPES.CREATE,
  season: 0,
  isExternal: false,
};

const DetailContentForm: FC<ConnectedProps> = ({
  approvalValidationErrors,
  calculateArea,
  driveDetail,
  getAggregatedOverlapsApi,
  getParcelApi,
  handleReset,
  handleSave,
  isEditing,
  isFetchingParcelItem,
  isParcelError,
  machine,
  operations,
  parcel,
  parcelId,
  productionOperations,
  setParcelId,
}) => {
  // hooks
  const showSnackbar = useContext(SnackbarContext);
  const classes = useTelematicsAggregationDetailContentStyles();
  const [selectedMachine, setSelectedMachine] = useState<
    MachineTo | null | undefined
  >();
  const [isParcelTouched, setIsParcelTouched] = useState(false);
  const [isLpisBlockTouched, setIsLpisBlockTouched] = useState(false);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [overlaps, setOverlaps] = useState<DriveDetailOverlapTo[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<
    DriverTo | undefined | null
  >(undefined);
  const [sumTimeFormatted, setSumTimeFormatted] = useState<string>("");

  // decide if data about parcel should be taken from telematics or agroevidence
  // (to avoid problems with parcel historization)
  let parcelData: Partial<TelematicsParcelType> | undefined;

  if (parcelId && parcel) {
    parcelData = { ...parcel };
  }

  if (
    driveDetail?.parcel?.localName &&
    !isParcelTouched &&
    !isLpisBlockTouched
  ) {
    parcelData = {
      ...driveDetail.parcel,
      seedApplication: driveDetail?.cropName
        ? {
            type: parcel?.seedApplication?.type,
            seed: {
              crop: {
                name: driveDetail?.cropName,
              },
            },
          }
        : undefined,
    };
  }

  const initialValues: Partial<DetailContentFormValues> = useMemo(() => {
    if (!driveDetail) return initialEmptyValues;

    return {
      id: driveDetail.id,
      date: moment(driveDetail.dateFrom),
      operation: driveDetail?.operation,
      productionOperation: driveDetail?.productionOperation,
      cultivated: round(driveDetail?.cultivated || 0, 4),
      distance: round(driveDetail?.distance || 0, 3),
      driverCode: driveDetail.driver?.code ?? "",
      bonus: driveDetail.driver?.bonus ?? 0,
      gpsUnit: driveDetail?.gpsUnit ?? "",
      equipmentCode: driveDetail?.equipmentCode ?? "",
      workingWidth:
        driveDetail?.workingWidth ?? selectedMachine?.workingWidth ?? 0,
      parcelId: driveDetail?.parcelId,
      type: driveDetail.type,
      timeFrom: moment(driveDetail?.drivePart?.[0]?.dateFrom) ?? undefined,
      timeTo: moment(driveDetail?.drivePart?.[0]?.dateTo) ?? undefined,
      duration: driveDetail.duration,
      formType: FORM_TYPES.EDIT,
      season: driveDetail?.season ?? 0,
      isExternal: driveDetail?.parcel?.affiliation === Affiliation.EXTERNAL,
    };
  }, [driveDetail, selectedMachine]);

  // lifecycle effects
  useEffect(() => {
    setSelectedMachine(machine);
  }, [machine]);

  useEffect(() => {
    if (parcelId) {
      getParcelApi(parcelId);
    }
  }, [getParcelApi, parcelId]);

  // handlers

  const handleResetFormAndParcel = () => {
    handleReset();
    setIsParcelTouched(false);
    setIsLpisBlockTouched(false);
  };

  const handleSubmit = (values: DetailContentFormValues) => {
    let data: Record<string, unknown> = {
      parcelId: parcelId || undefined,
      driver: values?.driverCode || undefined,
      gpsUnit: values.gpsUnit,
      equipment: values?.equipmentCode || undefined,
      operation: values.operation,
      productionOperation: values?.productionOperation?.code || undefined,
      workingWidth: values?.workingWidth || undefined,
      cultivated: values.cultivated === 0 ? 0 : values?.cultivated || undefined,
      distance: values?.distance || undefined,
      bonus: values.bonus,
      season: values.season > 0 ? values.season : undefined,
    };

    if (values.formType === FORM_TYPES.EDIT && values.isExternal) {
      data.parcelAffiliation = Affiliation.EXTERNAL;
    }

    if (values.type === Type.MANUAL) {
      const [timeFromAligned, timeToAligned] = alignTimesWithDate(
        values.date,
        values.timeFrom,
        values.timeTo,
      );
      data = {
        ...data,
        timeFrom: timeFromAligned.toISOString(),
        timeTo: timeToAligned.toISOString(),
      };
    }
    return handleSave(data as unknown as DriveUpdateTo | DriveCreateTo);
  };

  const isNew = initialValues.type === Type.MANUAL && !driveDetail;

  const notOverlapedInterval = (
    timeFrom?: moment.Moment | string,
    timeTo?: moment.Moment | string,
  ) => {
    const from = moment(timeFrom).toISOString();
    const to = moment(timeTo).toISOString();
    return `${from}/${to}`;
  };

  return (
    <>
      <Formik<Partial<DetailContentFormValues>>
        enableReinitialize
        initialValues={initialValues}
        onReset={handleResetFormAndParcel}
        onSubmit={handleSubmit}
        validateOnBlur={hasSubmitted}
        validateOnChange={hasSubmitted}
        validateOnMount={!isNew}
        validate={(v) =>
          validateContentForm(v as DetailContentFormValues, sumTimeFormatted)
        }
      >
        {(formikProps: FormikProps<DetailContentFormValues>) => {
          const {
            errors,
            isSubmitting,
            setFieldValue,
            touched,
            validateForm,
            values,
          } = formikProps;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            const touchedFieldsCount = Object.keys(touched).length;
            const fieldsCount = Object.keys(values).length;
            const fieldErrorsCount = Object.keys(errors).length;

            // formik sets all fields as touched on submit
            const submitted = touchedFieldsCount === fieldsCount;
            const submittedWithErrors = submitted && fieldErrorsCount > 0;

            if (submittedWithErrors) {
              // revalidate form until user fixes all errors
              validateForm();
            }
          }, [values, touched, validateForm, errors]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (isNew) {
              if (values.date) {
                setFieldValue("timeFrom", values.date.startOf("day"));
                setFieldValue("timeTo", values.date.startOf("day"));
              }
              if (values.date && values.driverCode) {
                (getAggregatedOverlapsApi as AsyncFn<DriveOverlapRequestTo>)({
                  date: getShortDateString(values.date),
                  driver: values.driverCode,
                }).then((res: RSAAResultAction<DriveDetailOverlapTo[]>) => {
                  if (!res.error) {
                    setFieldValue(
                      "timeIntervals",
                      res?.payload.map(
                        ({ dateFrom, dateTo }) => `${dateFrom}/${dateTo}`,
                      ),
                    );
                    setOverlaps(res?.payload);
                  }
                });
              }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [values.date, values.driverCode]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (hasSubmitted) {
              validateForm();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [validateForm, sumTimeFormatted]);

          if (parcelId !== values.parcelId) {
            setFieldValue("parcelId", parcelId);
          }
          const setNewValueAfterRecalculation = (area: number) => {
            showSnackbar({
              message: (
                <FormattedMessage id="TelematicsList.areaRecalculation.success" />
              ),
              autoHideDuration: 3000,
            });
            setFieldValue("cultivated", round(area, 4));
          };

          const handleDeleteParcel = () => {
            setParcelId(undefined);
            setIsParcelTouched(true);
            setFieldValue("season", 0);
          };

          const handleAreaRecalculationAfterValidation = (err: string) => {
            if (err) return;
            if (values.type === Type.MANUAL) return;
            if (values.gpsUnit && values?.id) {
              calculateArea(
                values.id,
                {
                  gpsUnit: values.gpsUnit,
                  workingWidth: values.workingWidth,
                },
                setNewValueAfterRecalculation,
              );
            }
          };

          const handleMachineChange = (machine: MachineTo | null) => {
            setFieldValue("gpsUnit", machine?.gpsUnit ?? "");
            setSelectedMachine(machine);
            if (!values.equipmentCode) {
              setFieldValue("workingWidth", machine?.workingWidth ?? 0);
            }
            if (values.type !== Type.MANUAL && values.id) {
              setFieldValue("workingWidth", machine?.workingWidth ?? 0);

              const equipmentData = {
                gpsUnit: values.gpsUnit,
                equipment: values.equipmentCode || undefined,
                workingWidth: machine?.workingWidth ?? 0,
              };

              calculateArea(
                values.id,
                equipmentData,
                setNewValueAfterRecalculation,
              );
            }
          };

          const handleProductionOperationChange = (
            value: ProductionOperationTo,
          ) => {
            setFieldValue("productionOperation", value);

            const operation = productionOperations.find(
              (o) => o.id === value?.id,
            );

            setFieldValue("bonus", value?.bonus ?? operation?.bonus ?? 0);
          };

          const handleEquipmentChange = (equipment: EquipmentTo | null) => {
            let newWorkingWidth = 0;
            if (equipment) {
              newWorkingWidth = equipment?.workingWidth || 0;
            } else if (selectedMachine) {
              newWorkingWidth = selectedMachine?.workingWidth || 0;
            }
            setFieldValue("equipmentCode", equipment?.code ?? "");
            setFieldValue("workingWidth", newWorkingWidth);

            const operation = productionOperations.find(
              (o) => o.code === equipment?.operation?.id,
            );
            setFieldValue("productionOperation", operation);

            setFieldValue(
              "bonus",
              operation?.bonus ?? machine?.operation?.bonus ?? 0,
            );

            const eqOperation = productionOperations.find(
              (o) => o.code === equipment?.operation?.id,
            );
            const machOperation = productionOperations.find(
              (o) => o.code === machine?.operation?.id,
            );

            if (eqOperation) {
              setFieldValue("productionOperation", eqOperation);
            } else if (machOperation) {
              setFieldValue("productionOperation", machOperation);
            } else {
              setFieldValue("productionOperation", undefined);
            }

            if (values.type === Type.MANUAL || !values.id) return;

            const equipmentData = {
              gpsUnit: values.gpsUnit,
              equipment: equipment ? equipment?.code : undefined,
              workingWidth: newWorkingWidth,
            };

            calculateArea(
              values.id,
              equipmentData,
              setNewValueAfterRecalculation,
            );
          };

          const handleChangeParcel = (value: ParcelTo | null) => {
            setParcelId(value?.id);
          };

          const hasParcel = !!(parcelId && parcelData);
          const hasLpisBlockInfo = !hasParcel && values.isExternal;
          const hasNoParcelOrBlock = !hasParcel && !hasLpisBlockInfo;

          // classifiers need always some date (even if user haven't selected it yet)
          // also use as valid-to date for parcels selector
          const dateForClassifiers =
            values.date?.toISOString() ?? moment().startOf("day").toISOString();

          const showIntervalsBar =
            values.type !== Type.MANUAL ||
            (!isEditing && values.type === Type.MANUAL);

          const hasExternalDriver =
            driveDetail?.driver.affiliation === Affiliation.EXTERNAL;
          const hasExternalEquipment =
            driveDetail?.equipmentAffiliation === Affiliation.EXTERNAL;
          const hasExternalMachine =
            driveDetail?.machine?.affiliation === Affiliation.EXTERNAL;

          const notOverlapedTime = getNotOverlapedTime(overlaps, {
            dateFrom: values.timeFrom.toISOString(),
            dateTo: values.timeTo.toISOString(),
          });

          const { totalHours, totalMinutes } =
            sumTimeIntervals(notOverlapedTime);
          setSumTimeFormatted(
            `${totalHours
              .toString()
              .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`,
          );

          const getOverlapedIntervals = () => {
            if (isNew) {
              return values.timeIntervals as string[];
            }
            return [];
          };

          const getNotOverlapedIntervals = (): string[] => {
            if (isNew) {
              return notOverlapedTime.map(({ dateFrom, dateTo }) =>
                notOverlapedInterval(dateFrom, dateTo),
              );
            }
            return [];
          };

          return (
            <Form className={classes.container}>
              <Grid container justifyContent="space-between">
                <Grid
                  className={classes.parcelFieldWrapper}
                  container
                  item
                  xs={12}
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    xs={approvalValidationErrors.parcel ? 1 : 0}
                    paddingBottom={
                      hasNoParcelOrBlock || parcelId ? "10px" : undefined
                    }
                  >
                    <ApprovalWarning
                      show={!!approvalValidationErrors.parcel}
                      tooltipCode={approvalValidationErrors.parcel}
                    />
                  </Grid>
                  <Grid item xs={approvalValidationErrors.parcel ? 11 : 12}>
                    {hasNoParcelOrBlock && !isEditing && <EmptyParcelBox />}
                    {hasLpisBlockInfo && (
                      <LpisBlock
                        area={driveDetail?.parcel?.area}
                        blockNr={driveDetail?.parcel?.blockNumber}
                        isEditing={isEditing}
                        square={driveDetail?.parcel?.square}
                        subjectId={driveDetail?.parcel?.subjectId}
                        subjectName={driveDetail?.parcel?.subjectName}
                        handleRemove={() => {
                          setIsLpisBlockTouched(true);
                          setFieldValue("isExternal", false);
                        }}
                      />
                    )}
                    {hasParcel && !hasLpisBlockInfo && (
                      <TelematicsParcel
                        handleRemoveParcel={handleDeleteParcel}
                        isEditing={isEditing}
                        parcel={parcelData as Partial<TelematicsParcelType>}
                        parcelId={parcelId}
                        isFetchingParcel={
                          isFetchingParcelItem && !isParcelError
                        }
                      />
                    )}
                    {hasNoParcelOrBlock && isEditing && (
                      <TelematicsParcelSelector
                        error={!!errors.parcelId}
                        isEditing={isEditing}
                        onChange={handleChangeParcel}
                        validToDate={dateForClassifiers}
                        label={
                          <FormattedMessage id="TelematicsList.selectParcel" />
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                {errors.parcelId && (
                  <Grid
                    className={classes.parcelValidationMessage}
                    item
                    xs={12}
                  >
                    {errors.parcelId}
                  </Grid>
                )}
                <Grid item xs={6}>
                  <OperationField
                    disabled={!isEditing}
                    operations={operations.filter(
                      (o) => o.code !== TelematicsOperation.HANDWORK,
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateField
                    disabled={!(isEditing && values.type === Type.MANUAL)}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.productionOperation}
                      tooltipCode={approvalValidationErrors.productionOperation}
                    />
                    <ProductionOperationField
                      date={dateForClassifiers}
                      disabled={!isEditing}
                      handleProductionOperationChange={
                        handleProductionOperationChange
                      }
                      {...formikProps}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.cultivated}
                      tooltipCode={approvalValidationErrors.cultivated}
                    />
                    <CultivatedField isEditing={isEditing} {...formikProps} />
                  </div>
                </Grid>
                <Grid item xs={approvalValidationErrors.cultivated ? 5 : 4}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.distance}
                      tooltipCode={approvalValidationErrors.distance}
                    />
                    <DistanceField isEditing={isEditing} {...formikProps} />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.fieldWrapper}>
                    <ApprovalWarning
                      show={!!approvalValidationErrors.driverCode}
                      tooltipCode={approvalValidationErrors.driverCode}
                    />
                    <DriverField
                      date={dateForClassifiers}
                      disabled={!isEditing}
                      placeholderId="Telematics.handwork.worker"
                      setSelectedDriver={isNew ? setSelectedDriver : undefined}
                      {...formikProps}
                    />
                  </div>
                  {hasExternalDriver && (
                    <div className={classes.externalDriver}>
                      <TelematicsForeignWarning
                        text={driveDetail.driver.companyName}
                        type={TelematicsForeignWarningType.Driver}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <BonusField
                    disabled={!isEditing}
                    label={<FormattedMessage id="TelematicsList.bonus" />}
                    {...formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    className={classes.fieldWrapper}
                    style={{ alignItems: "flex-start" }}
                  >
                    <ApprovalWarning
                      show={!!approvalValidationErrors.externalMachine}
                      style={{ marginTop: "28px" }}
                      tooltipCode={approvalValidationErrors.externalMachine}
                    />
                    <CfFormControl>
                      <TelematicsDetailMachineSelector
                        dateFrom={dateForClassifiers}
                        dateTo={dateForClassifiers}
                        disabled={!(isEditing && values.type === Type.MANUAL)}
                        error={!!errors.gpsUnit}
                        helperText={errors.gpsUnit ?? ""}
                        label={<FormattedMessage id="TelematicsList.machine" />}
                        onChange={handleMachineChange}
                        selectedGpsUnit={values.gpsUnit}
                      />
                      {hasExternalMachine ? (
                        <div className={classes.externalEquipment}>
                          <TelematicsForeignWarning
                            text={driveDetail.machine?.companyName}
                            type={TelematicsForeignWarningType.Machine}
                          />
                        </div>
                      ) : null}
                    </CfFormControl>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <CfFormControl>
                    <TelematicsDetailEquipmentSelector
                      dateFrom={dateForClassifiers}
                      dateTo={dateForClassifiers}
                      disabled={!isEditing}
                      error={false}
                      onChange={handleEquipmentChange}
                      selectedCode={values.equipmentCode}
                      label={
                        <FormattedMessage id="TelematicsList.additionalEquipment" />
                      }
                    />
                    {hasExternalEquipment && (
                      <div className={classes.externalEquipment}>
                        <TelematicsForeignWarning
                          text={driveDetail.equipmentCompanyName}
                          type={
                            TelematicsForeignWarningType.AdditionalEquipment
                          }
                        />
                      </div>
                    )}
                  </CfFormControl>
                </Grid>
                <Grid item xs={4}>
                  <WorkingWidthField
                    isEditing={isEditing}
                    handleAfterValidationEffects={
                      handleAreaRecalculationAfterValidation
                    }
                    {...formikProps}
                  />
                </Grid>
                {!showIntervalsBar && (
                  <>
                    <Grid item xs={4}>
                      <Field
                        component={CfFormikTimePicker}
                        name="timeFrom"
                        label={
                          <FormattedMessage id="TelematicsAggregations.detail.timeFrom" />
                        }
                      />
                    </Grid>
                    <Grid className={classes.timeToField} item xs={4}>
                      <Field
                        component={CfFormikTimePicker}
                        name="timeTo"
                        label={
                          <FormattedMessage id="TelematicsAggregations.detail.timeTo" />
                        }
                      />
                    </Grid>
                  </>
                )}
                {isNew && (
                  <Grid item marginTop={2} xs={12}>
                    <div className={classes.intervalsHeader}>
                      <span className={classes.intervalsHeaderLabelNew}>
                        {`${
                          values.date ? getLocalizedDateString(values.date) : ""
                        } ${selectedDriver?.name ?? ""}`}
                      </span>
                      <span
                        data-test="duration"
                        className={classnames(
                          sumTimeFormatted !== "00:00"
                            ? classes.activeIntervalsTime
                            : classes.intervalsTime,
                        )}
                      >
                        {sumTimeFormatted}{" "}
                        <FormattedMessage id="TelematicsAggregations.detail.intervals.hours" />
                      </span>
                    </div>
                    <TimeIntervalsBar
                      intervals={getNotOverlapedIntervals()}
                      isEditing={isNew}
                      key="refreshKey"
                      overlaps={getOverlapedIntervals()}
                      withTimeAxis
                      datetimeEnd={moment(values.date)
                        .endOf("day")
                        .toISOString()}
                      datetimeStart={moment(values.date)
                        .startOf("day")
                        .toISOString()}
                      intervalCustomColor={
                        isNew ? COLOR_PRIMARY.cleverfarm.main : undefined
                      }
                      overlapsCustomColor={
                        isNew ? COLOR_TIMELINE.isEditing : undefined
                      }
                      overlapsTooltipCustomColor={
                        isNew ? COLOR_PRIMARY.cleverfarm.main : undefined
                      }
                    />
                    <div className={classes.timelineNotification}>
                      <img alt="started" src={InfoBlueFaded} />
                      <span className={classes.timelineNotificationText}>
                        <FormattedMessage id="Telematics.handwork.timeline.notification" />
                      </span>
                    </div>
                  </Grid>
                )}
              </Grid>
              {isEditing && (
                <Buttons
                  isSubmitting={isSubmitting}
                  onCancelClick={() => handleReset()}
                  onSubmitClick={() => setHasSubmitted(true)}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: TelematicsState, props: Props) => ({
  parcel: getParcel(state),
  isFetchingParcelItem: getIsFetchingParcel(state),
  isParcelError: getParcelError(state).isError,
  operations: getOperations(state),
  machine: getMachineByGpsUnit(state, props),
  productionOperations: getProductionOperations(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      getParcelApi,
      calculateArea,
      getAggregatedOverlapsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailContentForm);
