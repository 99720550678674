import React from "react";

import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import CfTextFieldWithConfirm from "../../../../../shared/components/common/CfTextFieldWithConfirm/CfTextFieldWithConfirm";

const StatsNewZonePure = (props) => {
  const { onCreateZoneConfirm, onCreateZoneDismiss } = props;
  return (
    <CfTextFieldWithConfirm
      label={<FormattedMessage id="ParcelZoneStatistics.new-zone" />}
      labelTestId="new-zone"
      onAccept={(name) => onCreateZoneConfirm({ name })}
      onCancel={onCreateZoneDismiss}
    />
  );
};

StatsNewZonePure.propTypes = {
  onCreateZoneConfirm: PropTypes.func.isRequired,
  onCreateZoneDismiss: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  statCellBorderTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  statCellBorderBottom: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    color: theme.palette.grey[500],
    backgroundColor: "white",
  },
  firstCell: {
    width: "40%",
  },
  cell: {
    width: "30%",
  },
});

export const StatsNewZone = withStyles(styles)(StatsNewZonePure);
