import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  getOrganisms,
  getIsFetchingOrganisms,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchOrganisms } from "../../../../actions/catalogues.actions";

import { resetOrganismsApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import { Thunk } from "../../../../../types";

import { CataloguesState } from "../../../../../reducers/catalogues.reducer.types";
import { OrganismTo } from "../../../../../shared/api/agroevidence/agroevidence.types";

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  defaultValues?: OrganismTo | OrganismTo[];
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  isUsed?: boolean;
  validOnly?: boolean;
  onChange: (items: OrganismTo | OrganismTo[]) => void;
};
type Props = ReduxProps & OwnProps;

const OrganismsSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  fetchOrganisms,
  isFetching,
  isMultiple = false,
  isUsed = false,
  label,
  onChange,
  organisms,
  resetOrganismsApi,
  validOnly = false,
}: Props) => {
  useEffect(() => {
    fetchOrganisms("", isUsed, validOnly);

    return () => {
      resetOrganismsApi();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="organisms-selector"
      isFetching={isFetching}
      isMultiple={isMultiple}
      label={label}
      onChange={onChange}
      suggestions={organisms}
      testId="organisms-selector"
      loadOptions={(search: string) =>
        fetchOrganisms(search, isUsed, validOnly)
      }
    />
  );
};

const mapStateToProps = (state: CataloguesState) => ({
  organisms: getOrganisms(state),
  isFetching: getIsFetchingOrganisms(state),
});

const mapDispatchToProps = (dispatch: Thunk<CataloguesState>) =>
  bindActionCreators(
    {
      fetchOrganisms,
      resetOrganismsApi,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OrganismsSelector);
