import React, { Fragment, useContext } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { Theme, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { Field, FieldProps, FormikProps } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getActionRelatedDrives } from "../../../../../shared/api/telematics/aggregations/aggregations.selectors";

import { deleteSubtractionArea } from "../../actions/actions.actions";

import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import { SelectionItemAccordion } from "../../../../../shared/components/common/SelectionItemAccordion/SelectionItemAccordion";
import SelectionItemColumn from "../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn";
import SelectionItemHeading from "../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading";
import { CropName } from "../../../../../shared/components/specific/CropName/CropName";
import ValidationStatusIcon from "../../../../../shared/components/specific/ValidationStatusIcon/ValidationStatusIcon";
import * as validators from "../../../../../shared/misc/validators";
import { isParcelSown } from "../../../ActionOthers/helpers/others.helpers";
import ActionToTelematicsLinks from "../../components/ActionToTelematicsLinks/ActionToTelematicsLinks";
import { ActionDetailContext } from "../ActionDetail/ActionDetail";
import ActionParcelSubtractableAreas from "../ActionParcelSubractableAreas/ActionParcelSubtractableAreas";

import {
  ActionEphFormValues,
  PlantProtectionRestrictionWarningsType,
} from "../../../ActionEph/actionEph.types";
import { InitialParcelToAdd } from "../../../ActionOthers/actionOther.types";

type Props = {
  accountableNitrogen?: number;
  allMustBeSown: boolean;
  complianceResult?: PlantProtectionRestrictionWarningsType[];
  expectedAccountableNitrogen?: number;
  form: FormikProps<ActionEphFormValues>;
  formType: string;
  handleRemoveItem: () => void;
  index: number;
  isDeleting: boolean;
  isDraft: boolean;
  isEditing: boolean;
  isEphAction: boolean;
  isExisting: boolean;
  parcel: InitialParcelToAdd;
  isLegislationCheckWarning: boolean;
  parcelsInForm: InitialParcelToAdd[];
  updateParcelsActionArea: (parcelsInForm: InitialParcelToAdd[]) => void;
};

export const ParcelsListItem = ({
  accountableNitrogen,
  allMustBeSown,
  complianceResult,
  expectedAccountableNitrogen,
  form,
  formType,
  handleRemoveItem,
  index,
  isDeleting,
  isDraft,
  isEditing,
  isEphAction,
  isExisting,
  isLegislationCheckWarning,
  parcel,
  parcelsInForm,
  updateParcelsActionArea,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actionRelatedDrives = useSelector(getActionRelatedDrives);

  const { farmId } = useParams<{ farmId: string }>();

  const { checkedItems, handleCheckboxChange, isSplitting } =
    useContext(ActionDetailContext);

  const onParcelSubtractionDelete = (type: string, value: number) => {
    dispatch(deleteSubtractionArea(parcel.id, type, value));
  };

  const renderNitrogen = () => {
    if (accountableNitrogen === undefined) {
      return <span>...</span>;
    }

    const addedNitrogen =
      expectedAccountableNitrogen !== undefined
        ? accountableNitrogen + expectedAccountableNitrogen
        : accountableNitrogen;

    return (
      <span>
        <CfFormattedNumber value={accountableNitrogen} />
        {"\u2192"} <CfFormattedNumber value={addedNitrogen} />
      </span>
    );
  };

  const isSown = isParcelSown(parcel);

  const isActionRelatedDrivesForParcel =
    actionRelatedDrives?.parcels && actionRelatedDrives?.parcels[parcel.id];

  const isActionParcelTotalAreaError = parcel?.actionParcelTotalArea <= 0;

  const isBoundaryCheckedError = form.getFieldMeta(
    `parcels.${index}.subtractableAreas.boundaryChecked`,
  ).error;

  const isWaterCheckedError = form.getFieldMeta(
    `parcels.${index}.subtractableAreas.waterChecked`,
  ).error;

  const isSubtractableAreasWarning =
    !!isBoundaryCheckedError || !!isWaterCheckedError;

  const hasWarning =
    complianceResult?.some(
      (result) =>
        result.isDatePreviousApplicationWarning ||
        result.isNumberOfApplicationsWarning,
    ) ||
    isSubtractableAreasWarning ||
    isLegislationCheckWarning;

  return (
    <Stack direction="row" spacing={2} style={{ minWidth: "100%" }}>
      {isSplitting && (
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          spacing={2}
        >
          <Checkbox
            checked={checkedItems[parcel.id] || false}
            className={classes.splitCheckbox}
            color="primary"
            onChange={(e) => {
              handleCheckboxChange(parcel.id, e.target.checked);
            }}
          />
        </Stack>
      )}
      <div className={classes.accordionWrapper}>
        <SelectionItemAccordion
          data-test="item-root"
          formType={formType}
          handleRemoveItem={handleRemoveItem}
          hasWarning={hasWarning}
          isDeleting={isDeleting}
          isEditing={isEditing}
          isExisting={isExisting}
          isSown={isSown}
          key={`${parcel.id}-${index}`}
          details={
            <ActionParcelSubtractableAreas
              isEditing={isEditing}
              maxValue={parcel.area}
              onParcelSubtractionDelete={onParcelSubtractionDelete}
              parcel={parcel}
              parcelIndex={index}
              parcelsInForm={parcelsInForm}
              updateParcelsActionArea={updateParcelsActionArea}
            />
          }
        >
          <Grid container spacing={0}>
            <Grid item sm={isEphAction ? 3 : 4} xs={12}>
              {
                // TODO: "a" is temporary
                // change to "
                // <Link to=" after remove angular and updating react-router-dom to version 6 (or higher)
              }
              <a href={`/farm/${farmId}/parcels/${parcel.id}/overview`}>
                <SelectionItemHeading
                  reversed={true}
                  subheading={parcel.blockNumber}
                >
                  <div>{parcel.localName}</div>
                </SelectionItemHeading>
              </a>
            </Grid>
            <Grid item sm={isEphAction ? 2 : 3} xs={6}>
              <SelectionItemColumn
                customClasses={{ content: classes.area }}
                label={<FormattedMessage id="common.area-ha" />}
              >
                <Fragment>
                  {allMustBeSown && !isSown ? (
                    <CfFormattedNumber decimalDigits={2} value={0} />
                  ) : (
                    <Field
                      name={`parcels.${index}.actionParcelTotalArea`}
                      validate={validators.requiredAndPositiveNumber}
                    >
                      {({ field }: FieldProps<number>) => (
                        <CfFormattedNumber
                          decimalDigits={2}
                          value={field.value}
                        />
                      )}
                    </Field>
                  )}
                  {" / "}
                  <CfFormattedNumber decimalDigits={2} value={parcel.area} />
                  {isActionParcelTotalAreaError && (
                    <InputAdornment
                      classes={{ positionEnd: classes.warning }}
                      position="end"
                    >
                      <ValidationStatusIcon
                        id={`parcels.${index}.actionParcelTotalArea`}
                        type="warning"
                      >
                        <FormattedMessage id="SubtractableArea.error" />
                      </ValidationStatusIcon>
                    </InputAdornment>
                  )}
                </Fragment>
              </SelectionItemColumn>
            </Grid>
            <Grid item sm={isEphAction ? 3 : 4} xs={isDraft ? 4 : 6}>
              <SelectionItemColumn
                label={
                  parcel.seedApplication?.type === "CATCH_CROP" ? (
                    <FormattedMessage id="ActionParcelsList.catchCrop" />
                  ) : (
                    <FormattedMessage id="common.crop" />
                  )
                }
              >
                <div>
                  <CropName cropType={parcel.seedApplication?.type}>
                    {parcel.seedApplication?.seed.name || (
                      <FormattedMessage id="common.noCrop" />
                    )}
                  </CropName>
                </div>
              </SelectionItemColumn>
            </Grid>
            {isEphAction && (
              <Grid item sm={2} xs={3}>
                <SelectionItemColumn
                  label={<FormattedMessage id="ActionParcelsList.NSA" />}
                >
                  <div>
                    {parcel.nitrateVulnerable ? (
                      <FormattedMessage id="common.yes" />
                    ) : (
                      <FormattedMessage id="common.no" />
                    )}
                  </div>
                </SelectionItemColumn>
              </Grid>
            )}
            {isEphAction && (
              <Grid
                item
                sm={isActionRelatedDrivesForParcel ? 1 : 2}
                xs={isActionRelatedDrivesForParcel ? 3 : 6}
              >
                <SelectionItemColumn
                  label={
                    <span>
                      N <FormattedMessage id="unit.kg" />
                      /ha
                    </span>
                  }
                >
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    placement="bottom"
                    title={
                      <FormattedMessage id="ActionParcelsList.accountableNitrogen" />
                    }
                  >
                    <div>{renderNitrogen()}</div>
                  </Tooltip>
                </SelectionItemColumn>
              </Grid>
            )}
            <Grid
              className={classes.telematicsIconContainer}
              item
              sm={1}
              xs={2}
            >
              <ActionToTelematicsLinks data={isActionRelatedDrivesForParcel} />
            </Grid>
          </Grid>
          {isLegislationCheckWarning && (
            <div
              className={classes.warningCard}
              data-test="parcel-warning-missing-seed-application"
            >
              <WarningIcon className={classes.warningCardIcon} />
              <div>
                <div
                  className={classNames(
                    classes.warningCardContent,
                    classes.bold,
                  )}
                >
                  <FormattedMessage id="Eph.parcelsControl.legislationCheckWarning.main" />
                </div>
                <div className={classes.warningCardContent}>
                  <FormattedMessage id="Eph.parcelsControl.legislationCheckWarning.secondary" />
                </div>
              </div>
            </div>
          )}
          {!isLegislationCheckWarning &&
            complianceResult?.map((item, index) => (
              <Fragment key={index}>
                {item.isNumberOfApplicationsWarning && (
                  <div
                    className={classes.warningCard}
                    data-test="parcel-warning-number-of-application"
                  >
                    <WarningIcon className={classes.warningCardIcon} />
                    <p className={classes.warningCardContent}>
                      <FormattedMessage
                        id="Eph.parcelsControl.numberOfApplicationsWarning"
                        values={{
                          porName: item.name,
                          allowedApplications: item.allowedApplications,
                          b: (chunks: string) => <b>{chunks}</b>,
                        }}
                      />
                    </p>
                  </div>
                )}
                {item.isDatePreviousApplicationWarning && (
                  <div
                    className={classes.warningCard}
                    data-test="parcel-warning-date-of-prev-action"
                  >
                    <WarningIcon className={classes.warningCardIcon} />
                    <p className={classes.warningCardContent}>
                      <FormattedMessage
                        id="Eph.parcelsControl.datePreviousApplicationWarning"
                        values={{
                          porName: item.name,
                          minInterval: item.minInterval,
                          datePreviousApplication: item.previousDate,
                          b: (chunks: string) => <b>{chunks}</b>,
                        }}
                      />
                    </p>
                  </div>
                )}
              </Fragment>
            ))}
          {!isLegislationCheckWarning && isSubtractableAreasWarning && (
            <div
              className={classes.warningCard}
              data-test="parcel-warning-subtraction"
            >
              <WarningIcon className={classes.warningCardIcon} />
              <p className={classes.warningCardContent}>
                <FormattedMessage id="Eph.parcelsControl.porRestrictionsWarning" />
              </p>
            </div>
          )}
        </SelectionItemAccordion>
      </div>
    </Stack>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    position: "relative",
    top: 10,
  },
  area: {
    display: "flex",
  },
  telematicsIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  accordionWrapper: {
    width: "100%",
  },
  splitCheckbox: {
    height: "40px",
  },
  tooltip: {
    fontSize: 12,
  },
  warningCard: {
    display: "inline-flex",
    justifyContent: "left",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    paddingTop: "8px",
  },
  warningCardIcon: {
    color: theme.palette.secondary.dark,
    marginRight: 8,
  },
  warningCardContent: {
    margin: 0,
    fontSize: 13,
  },
  bold: {
    fontWeight: "bold",
  },
}));
