import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { TableRow, Collapse, IconButton, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedDate, FormattedMessage } from "react-intl";

import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import { useToggle } from "../../../../../shared/hooks/useToggle";
import { APPLICATIONS_COLUMN_NAMES_V2 } from "../../plantProtection.columns";

import { ApplicationDeleteDialog } from "./ApplicationDeleteDialog";
import { ApplicationEditDialog } from "./ApplicationEditDialog";
import { ApplicationRowDetails } from "./ApplicationRowDetails";

import { PlantProtectionDetailRegistrationApplicationV2To } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  application: PlantProtectionDetailRegistrationApplicationV2To;
  onDeleteAccept: () => void;
  canUpdate: boolean;
  isEditing: boolean;
};

export const ApplicationRow = ({
  application,
  canUpdate,
  isEditing,
  onDeleteAccept,
}: Props) => {
  const classes = useStyles();

  const { on: rowIsOpen, toggle: handleRowClick } = useToggle();

  const {
    on: deleteIsOpen,
    setOff: handleDeleteClose,
    setOn: handleDeleteOpen,
  } = useToggle();

  const {
    on: showEditDialog,
    setOff: handleEditeClose,
    setOn: handleEditOpen,
  } = useToggle();

  const handleDeleteAccept = () => {
    onDeleteAccept();
    handleDeleteClose();
  };

  return (
    <>
      <TableRow
        className={classes.tableRow}
        hover
        key={application.id}
        onClick={handleRowClick}
      >
        <CfTableCell name="actions">
          <IconButton
            aria-label="Toggle view"
            className={classes.collapseIcon}
            size="large"
          >
            {rowIsOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CfTableCell>
        <CfTableCell
          classes={{ root: classes.name }}
          name={APPLICATIONS_COLUMN_NAMES_V2.CROP}
        >
          {application.crop?.name ?? "-"}
        </CfTableCell>
        <CfTableCell name={APPLICATIONS_COLUMN_NAMES_V2.ORGANISM}>
          {application.organism?.name ?? "-"}
        </CfTableCell>
        {!canUpdate && (
          <CfTableCell name={APPLICATIONS_COLUMN_NAMES_V2.REG_EXTERNAL_ID}>
            {application.registrationExternalId ?? "-"}
          </CfTableCell>
        )}
        {!canUpdate && (
          <CfTableCell name={APPLICATIONS_COLUMN_NAMES_V2.REG_HOLDER}>
            {application.registrationHolder ?? "-"}
          </CfTableCell>
        )}
        {!canUpdate && (
          <CfTableCell name={APPLICATIONS_COLUMN_NAMES_V2.REG_NUMBER}>
            {application.registrationNumber ?? "-"}
          </CfTableCell>
        )}
        {!canUpdate && (
          <CfTableCell name={APPLICATIONS_COLUMN_NAMES_V2.VALID_UNTIL}>
            {application.validUntil ? (
              <FormattedDate value={application.validUntil} />
            ) : (
              "-"
            )}
          </CfTableCell>
        )}
        {canUpdate && (
          <CfTableRowTools
            key="area-actions"
            toolsClass={classes.tableRowActions}
            withoutGradient
          >
            <CfTableRowToolButton
              disabled={isEditing}
              icon={<EditIcon />}
              onClick={handleEditOpen}
              tooltipMessage={
                <FormattedMessage id="Catalogues.table.shared.disabledEditTooltip" />
              }
            />
            <CfTableRowToolButton
              disabled={isEditing}
              icon={<DeleteIcon />}
              onClick={handleDeleteOpen}
              tooltipMessage={<FormattedMessage id="common.delete" />}
            />
          </CfTableRowTools>
        )}
      </TableRow>
      <TableRow className={classes.collapsedRow}>
        <TableCell className={classes.collapsedCell} colSpan={12}>
          <Collapse in={rowIsOpen}>
            <ApplicationRowDetails canUpdate={canUpdate} data={application} />
          </Collapse>
        </TableCell>
      </TableRow>

      {deleteIsOpen && (
        <ApplicationDeleteDialog
          handleAccept={handleDeleteAccept}
          handleClose={handleDeleteClose}
          open={deleteIsOpen}
        />
      )}

      {showEditDialog && (
        <ApplicationEditDialog
          data={application}
          handleClose={handleEditeClose}
          isNew={false}
          opened={showEditDialog}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  name: {
    fontSize: 13,
    fontWeight: 500,
    paddingLeft: 16,
  },
  pointer: {
    cursor: "pointer",
  },
  tableRow: {
    cursor: "pointer",
    transform: "scale(1)",
    "&:hover $tableRowActions": {
      display: "inline-block",
      padding: "9px 6px 0px 0px",
      background: "transparent",
      backgroundColor: "transparent",
    },
  },
  tableRowActions: {
    display: "none",
  },
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    padding: 0,
    borderBottom: "none",
  },
  collapseIcon: {
    marginLeft: 4,
  },
}));
