import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getMainMapTelematicsDashboardMachinesIsOpen } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.selectors";
import {
  getMainMapTelematicsDateFrom,
  getMainMapTelematicsDateTo,
  getMainMapTelematicsMachineFilter,
  getMainMapTelematicsDriverFilter,
} from "../../selectors/mainMapTelematics.selectors";

import { setDashboardMachineSelected } from "../../../../shared/api/telematics/mainMapTelematics/dashboard/machinesDashboard.actions";
import {
  setDate,
  setDriverFilter,
  setMachineFilter,
  setMachineSelectorFilter,
  resetFilters,
} from "../../actions/mainMapTelematics/mainMapTelematics.actions";

import { DUPLICATED_TAB_KEY } from "../../constants/localStorage.constants";

import { resetMachinesGroups } from "../../../../shared/api/telematics/mainMapTelematics/mainMapTelematics.api";
import { getFarmActivatedDashboard } from "../../../../shared/api/telematics/sectionStatus/sectionStatus.selector";
import DateRangeSelector from "../../../../shared/components/common/DateRangeSelector/DateRangeSelector";
import CfSingleFilter from "../../../../shared/containers/CfSingleFilter/CfSingleFilter";
import useWidth from "../../../../shared/hooks/useWidth";
import LocalStorage from "../../../../shared/services/LocalStorage.service";
import MachinesDashboard from "../MachinesDashboard/MachinesDashboard";
import MainMapTelematicsDriverSelector from "../MainMapTelematicsDriverSelector/MainMapTelematicsDriverSelector";
import MainMapTelematicsMachineSelector from "../MainMapTelematicsMachineSelector/MainMapTelematicsMachineSelector";

import {
  MachineTo,
  DriverTo,
} from "../../../../shared/api/telematics/telematics.types";

export type Props = {
  initDateFrom: string;
  initDateTo: string;
  initDriverFilter: DriverTo[];
  initMachineFilter: MachineTo[];
  langId: string;
};

const TelematicsToolbar = ({
  initDateFrom,
  initDateTo,
  initDriverFilter,
  initMachineFilter,
  langId,
}: Props) => {
  const dispatch = useDispatch();
  const machineDashboardIsOpen = useSelector(
    getMainMapTelematicsDashboardMachinesIsOpen,
  );
  const dateFrom = useSelector(getMainMapTelematicsDateFrom);
  const dateTo = useSelector(getMainMapTelematicsDateTo);
  const machineFilter = useSelector(getMainMapTelematicsMachineFilter);
  const driverFilter = useSelector(getMainMapTelematicsDriverFilter);
  const activatedDashboard = useSelector(getFarmActivatedDashboard);

  const classes = useStyles();
  const width = useWidth();
  const isMobile = width === "xs" || width === "sm";

  const [machineFilterState, setMachineFilterState] =
    useState<MachineTo[]>(machineFilter);
  const [driverFilterState, setDriverFilterState] =
    useState<DriverTo[]>(driverFilter);
  const isInitialLoad = useRef<boolean>();

  useEffect(() => {
    isInitialLoad.current = true;
    if (initDateFrom && initDateTo) dispatch(setDate(initDateFrom, initDateTo));
    if (initDriverFilter) dispatch(setDriverFilter(initDriverFilter));
    if (initMachineFilter) {
      dispatch(setMachineFilter(initMachineFilter));
      setMachineFilterState(initMachineFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      return;
    }
    dispatch(setMachineFilter(machineFilter));
    setMachineFilterState(machineFilter);
  }, [dispatch, machineFilter, setMachineFilterState]);

  useEffect(() => {
    const loadLocalStorageData = () => {
      const ls = LocalStorage.loadFromLocalStorage(DUPLICATED_TAB_KEY);
      if (ls?.duplicatedTab?.filters) {
        const { dateFrom, dateTo, driver, machine } = ls.duplicatedTab.filters;
        if (dateFrom && dateTo) {
          dispatch(setDate(dateFrom, dateTo));
        }
        if (driver) {
          dispatch(setDriverFilter(Array.isArray(driver) ? driver : [driver]));
        }
        if (machine) {
          dispatch(
            setMachineFilter(Array.isArray(machine) ? machine : [machine]),
          );
        }
        LocalStorage.removeFromLocalStorage(DUPLICATED_TAB_KEY);
      }
    };
    loadLocalStorageData();
  }, [dispatch]);

  const resetMainMapMachinesDriversFilter = () => {
    dispatch(setDriverFilter([]));
    dispatch(setMachineFilter([]));
    setMachineFilterState([]);
    dispatch(setDashboardMachineSelected(undefined));
    dispatch(setMachineSelectorFilter(""));
    dispatch(resetMachinesGroups());
    dispatch(resetFilters());
  };

  const onChangeMachineFilterState = (
    machines: MachineTo[],
    checked: boolean,
  ) => {
    const prevMachineFilterState = [...machineFilterState];
    if (checked) {
      const newMachineFilterState = prevMachineFilterState.concat(
        machines.filter((item) => prevMachineFilterState.indexOf(item) < 0),
      );
      setMachineFilterState(newMachineFilterState);
      dispatch(setMachineFilter(newMachineFilterState));
    } else {
      const newMachineFilterState = prevMachineFilterState.filter(
        (item) =>
          machines.map((machine) => machine.gpsUnit).indexOf(item.gpsUnit) < 0,
      );
      setMachineFilterState(newMachineFilterState);
      dispatch(setMachineFilter(newMachineFilterState));
    }
  };

  const isFilterSelected = !!(machineFilter.length || driverFilter.length);
  const showFilters = !(machineDashboardIsOpen && isMobile);

  return (
    <div className={classes.toolbarElements}>
      {activatedDashboard && (
        <MachinesDashboard onOpen={resetMainMapMachinesDriversFilter} />
      )}
      {showFilters && (
        <>
          <DateRangeSelector
            dateFrom={dateFrom ?? ""}
            dateTo={dateTo ?? ""}
            disabled={machineDashboardIsOpen}
            langId={langId}
            lightStyle
            setDate={(dateFrom, dateTo) => dispatch(setDate(dateFrom, dateTo))}
          />
          <CfSingleFilter
            disabled={machineDashboardIsOpen}
            filter="-machines"
            filterItems={machineFilter}
            filterItemsState={machineFilterState}
            formattedMessageId="MainMapTelematics.filter.machineWithCount"
            mainPage
            setFilterItems={(i) => dispatch(setMachineFilter(i))}
            setFilterItemsState={setMachineFilterState}
            withButton
          >
            <MainMapTelematicsMachineSelector
              langId={langId}
              onChangeMachineFilter={onChangeMachineFilterState}
              selectedValues={machineFilterState}
            />
          </CfSingleFilter>
          <CfSingleFilter
            disabled={machineDashboardIsOpen}
            filter="-drivers"
            filterItems={driverFilter}
            filterItemsState={driverFilterState}
            formattedMessageId="MainMapTelematics.filter.driverWithCount"
            mainPage
            setFilterItems={(i) => dispatch(setDriverFilter(i))}
            setFilterItemsState={setDriverFilterState}
          >
            <div className={classes.container}>
              <Grid container>
                <Grid className={classes.select} item xs={12}>
                  <MainMapTelematicsDriverSelector
                    defaultValues={driverFilter}
                    onChange={setDriverFilterState}
                    label={
                      <FormattedMessage id="MainMapTelematics.filter.driver" />
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </CfSingleFilter>
          {isFilterSelected && (
            <Button
              className={classes.button}
              onClick={resetMainMapMachinesDriversFilter}
              size="small"
              variant="contained"
            >
              <FormattedMessage id="CfFilter.cancelFilter" />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbarElements: {
    display: "flex",
    gap: "0.5rem",
    marginBottom: 5,
    alignItems: "center",
    flexWrap: "wrap",
  },
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
  button: {
    backgroundColor: "#424242",
    color: theme.palette.common.white,
    padding: "2px 10px",
    borderRadius: 28,
    "&:hover": {
      backgroundColor: "#212121",
    },
    "&:active": {
      backgroundColor: "#212121",
    },
  },
}));

export default TelematicsToolbar;
