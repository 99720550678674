import React from "react";

import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { CfFormikErrors } from "../../../../../types";
import CheckboxField from "../../../shared/CheckboxField";
import DatePickerField from "../../../shared/DatePickerField";
import FormikTextField from "../../../shared/FormikTextField";
import BioFunctionSelector from "../../PlantProtectionDetail/components/BioFunctionSelector";
import { usePlantProtectionDetailStyles } from "../PlantProtectionDetail.styles";

import { PlantProtectionDetailFormValues } from "../PlantProtectionDetail.types";

interface Props {
  handleChangeBioFunction: (bioFunction: string) => void;
  handleChangeCheckbox: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
  isEditing: boolean;
  isMobile: boolean;
  values: PlantProtectionDetailFormValues;
  errors: CfFormikErrors<PlantProtectionDetailFormValues>;
}

export const GeneralSectionEditMode = ({
  errors,
  handleChangeBioFunction,
  handleChangeCheckbox,
  isEditing,
  isMobile,
  values,
}: Props) => {
  const classes = usePlantProtectionDetailStyles();

  return (
    <>
      <Grid
        container
        direction={isMobile ? "column-reverse" : "row"}
        item
        justifyContent="space-between"
      >
        <Grid container direction={isMobile ? "column" : "row"} item xs={8}>
          <Grid className={classes.bioFunctionSelector} item>
            <BioFunctionSelector
              defaultValues={values.bioFunction}
              disabled={!isEditing}
              error={!!errors.bioFunction}
              onChange={handleChangeBioFunction}
              label={
                <FormattedMessage id="Catalogues.plantProtection.detail.bioFunction" />
              }
            />
          </Grid>
        </Grid>
        <Grid
          alignItems="flex-start"
          container
          item
          justifyContent={isMobile ? "flex-start" : "flex-end"}
          xs={4}
        >
          <CheckboxField
            defaultValues={values.isEco}
            isEditing={isEditing}
            label="Catalogues.plantProtection.detail.isEco"
            name="isEco"
            onChange={handleChangeCheckbox}
          />
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <DatePickerField
          className={classes.datePickerField}
          isEditing={isEditing}
          label="Catalogues.table.plantProtection.column.validTo"
          name="usableUntil"
        />
      </Grid>
      <Grid item xs={8}>
        <FormikTextField
          fullWidth={true}
          isEditing={isEditing}
          label="common.note"
          name="notes"
        />
      </Grid>
    </>
  );
};
