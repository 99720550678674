import React from "react";

import MuiTableCell from "@mui/material/TableCell";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const TableCellPure = (props) => {
  const { children, classes } = props;
  return (
    <MuiTableCell classes={{ root: classes.root }} padding="normal">
      <span className={classes.statCellPadding}>{children}</span>
    </MuiTableCell>
  );
};

TableCellPure.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const stylesTableCell = () => ({
  root: {
    borderBottom: 0,
  },
  statCellPadding: {
    padding: "0 9px",
    display: "block",
  },
});

const TableCell = withStyles(stylesTableCell)(TableCellPure);

export default TableCell;
